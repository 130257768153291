/* eslint-disable @typescript-eslint/no-explicit-any */
import * as enums from "@/lib/enums";
import { Column } from "@/lib/types";

export type Board = {
	//columns: Map<enums.TodoStatus, Column>;
	config?: {
		cards: BoardCard[];
		labels: CardLabel[];
		lists: BoardColumn[];
	};
	id: string;
	name: string;
	closed: boolean;
	dateLastActivity: string;
	dateLastView: string;
	datePluginDisable?: any;
	enterpriseOwned: boolean;
	idOrganization: string;
	nodeId: string;
	prefs?: Prefs;
	premiumFeatures: string[];
	shortLink: string;
	shortUrl: string;
	url: string;
	creationMethod: string;
	idEnterprise: any;
	subscribed: boolean;
	organization?: Organization;
	memberships: Membership[];
};

export class CardLabel {
	id?: string;
	idBoard?: string;
	name: string = "";
	color: string = "";
	uses?: 0;
	limits?: object;
}

export interface BoardCard {
	id: string;
	badges: Badges;
	cardRole: any;
	closed: boolean;
	dateLastActivity: string;
	desc: string;
	descData: DescData;
	due: any;
	dueComplete: boolean;
	dueReminder: any;
	idAttachmentCover: any;
	idList: string;
	idBoard: string;
	idMembers: any[];
	idShort: number;
	idLabels: any[];
	limits?: Limits;
	name: string;
	pos: number;
	shortUrl: string;
	shortLink: string;
	subscribed: boolean;
	url: string;
	locationName?: any;
	address?: any;
	coordinates?: any;
	cover: Cover;
	isTemplate: boolean;
	start: any;
	labels: any[];
	stickers: any[];
	attachments: any[];
	checklists: any[];
	pluginData: any[];
	customFieldItems: any[];
}

export const newEmptyBoardCard: BoardCard = {
	id: "6515b92c44831c6f70a828ac",
	badges: {
		attachmentsByType: {
			trello: {
				board: 0,
				card: 0,
			},
		},
		location: false,
		votes: 0,
		viewingMemberVoted: false,
		subscribed: false,
		fogbugz: "",
		checkItems: 0,
		checkItemsChecked: 0,
		checkItemsEarliestDue: null,
		comments: 0,
		attachments: 0,
		description: false,
		due: null,
		dueComplete: false,
		start: null,
	},
	closed: false,
	dueComplete: false,
	desc: "",
	descData: {
		emoji: {},
	},
	due: null,
	dueReminder: null,
	idMembers: [],
	idShort: 85,
	idAttachmentCover: null,
	labels: [],
	idLabels: [],
	name: "",
	start: null,
	subscribed: false,
	cover: {
		idAttachment: null,
		color: null,
		idUploadedBackground: null,
		size: "normal",
		brightness: "dark",
		idPlugin: null,
	},
	isTemplate: false,
	cardRole: null,
	attachments: [],
	stickers: [],
	dateLastActivity: "",
	idList: "",
	idBoard: "",
	pos: 0,
	shortUrl: "",
	shortLink: "",
	url: "",
	checklists: [],
	pluginData: [],
	customFieldItems: [],
};

export interface Badges {
	attachmentsByType: AttachmentsByType;
	location: boolean;
	votes: number;
	viewingMemberVoted: boolean;
	subscribed: boolean;
	fogbugz: string;
	checkItems: number;
	checkItemsChecked: number;
	checkItemsEarliestDue: any;
	comments: number;
	attachments: number;
	description: boolean;
	due: any;
	dueComplete: boolean;
	start: any;
}

export interface AttachmentsByType {
	trello: Trello;
}

export interface Trello {
	board: number;
	card: number;
}

export interface DescData {
	emoji: Emoji;
}

export interface Emoji {}

export interface Limits {
	attachments: Attachments;
	checklists: Checklists;
	stickers: Stickers;
}

export interface Attachments {
	perCard: PerCard;
}

export interface PerCard {
	status: string;
	disableAt: number;
	warnAt: number;
}

export interface Checklists {
	perCard: PerCard2;
}

export interface PerCard2 {
	status: string;
	disableAt: number;
	warnAt: number;
}

export interface Stickers {
	perCard: PerCard3;
}

export interface PerCard3 {
	status: string;
	disableAt: number;
	warnAt: number;
}

export interface Cover {
	idAttachment: any;
	color: any;
	idUploadedBackground: any;
	size: string;
	brightness: string;
	idPlugin: any;
}
export class BoardColumn {
	id?: string;
	name: string = "";
	closed: boolean = false;
	idBoard: string = "";
	pos: number = -1;
	status?: any;
	subscribed?: boolean;
	limits?: Limits;
	creationMethod?: any;
	softLimit?: any;
}

export interface Limits {
	cards: Cards;
}

export interface Cards {
	openPerList: OpenPerList;
	totalPerList: TotalPerList;
}

export interface OpenPerList {
	status: string;
	disableAt: number;
	warnAt: number;
}

export interface TotalPerList {
	status: string;
	disableAt: number;
	warnAt: number;
}

export const newBoard: Board = {
	//columns: new Map<enums.TodoStatus, Column>(),
	id: "",
	name: "",
	closed: false,
	dateLastActivity: "",
	dateLastView: "",
	enterpriseOwned: true,
	idOrganization: "",
	nodeId: "",
	premiumFeatures: [],
	shortLink: "",
	shortUrl: "",
	url: "",
	creationMethod: "",
	idEnterprise: "",
	subscribed: false,
	memberships: [],
};

export interface Prefs {
	permissionLevel: string;
	hideVotes: boolean;
	voting: string;
	comments: string;
	invitations: string;
	selfJoin: boolean;
	cardCovers: boolean;
	isTemplate: boolean;
	cardAging: string;
	calendarFeedEnabled: boolean;
	hiddenPluginBoardButtons: any[];
	switcherViews: SwitcherView[];
	background: string;
	backgroundColor: string;
	backgroundImage: any;
	backgroundImageScaled: any;
	backgroundTile: boolean;
	backgroundBrightness: string;
	backgroundBottomColor: string;
	backgroundTopColor: string;
	canBePublic: boolean;
	canBeEnterprise: boolean;
	canBeOrg: boolean;
	canBePrivate: boolean;
	canInvite: boolean;
}

export interface SwitcherView {
	viewType: string;
	enabled: boolean;
}

export interface Organization {
	id: string;
	name: string;
	displayName: string;
	products: any[];
	prefs: Prefs2;
	premiumFeatures: string[];
	logoHash: any;
	idEnterprise: any;
	limits: Limits;
	credits: any[];
}

export interface Prefs2 {
	permissionLevel: string;
	orgInviteRestrict: any[];
	boardInviteRestrict: string;
	externalMembersDisabled: boolean;
	associatedDomain: any;
	googleAppsVersion: number;
	boardVisibilityRestrict: BoardVisibilityRestrict;
	boardDeleteRestrict: BoardDeleteRestrict;
	attachmentRestrictions: any;
	seatAutomation: any;
	seatAutomationActiveDays: any;
	seatAutomationInactiveDays: any;
	newLicenseInviteRestrict: any;
	newLicenseInviteRestrictUrl: any;
	atlassianIntelligenceEnabled: boolean;
}

export interface BoardVisibilityRestrict {
	private: string;
	org: string;
	enterprise: string;
	public: string;
}

export interface BoardDeleteRestrict {
	private: string;
	org: string;
	enterprise: string;
	public: string;
}

export interface Limits {
	orgs: Orgs;
}

export interface Orgs {
	totalMembersPerOrg: TotalMembersPerOrg;
	freeBoardsPerOrg: FreeBoardsPerOrg;
}

export interface TotalMembersPerOrg {
	status: string;
	disableAt: number;
	warnAt: number;
}

export interface FreeBoardsPerOrg {
	status: string;
	disableAt: number;
	warnAt: number;
	count: number;
}

export interface Membership {
	idMember: string;
	memberType: string;
	unconfirmed: boolean;
	deactivated: boolean;
	id: string;
}
