import React from "react";
import {
	Card,
	CardHeader,
	CardBody,
	Button,
	Typography,
} from "@material-tailwind/react";
import { ResultatNetProps } from "./ResultatNet";
import { ReportData } from "@/lib/invoice-types";

const RapportTVA = ({
	report,
	soldeReporte,
	reportANouveau,
	totalOnComplementaryItems,
	taxes,
}: {
	report: ReportData;
	soldeReporte: number;
	reportANouveau: number;
	taxes: number;
	nbCouverts?: number;
	totalOnComplementaryItems: number;
}) => {
	let totalRecettesMontantIn = 0;
	let totalRecettesMontantOut = 0;
	const recettes = report.recettes;
	recettes.map((recette) => {
		totalRecettesMontantIn += recette.montantIn - recette.pourboires;
		totalRecettesMontantOut += recette.montantOut;
	});
	const tvaArray = {};
	report.ventes.map((vente) => {
		const prop = `tva${vente.tva.toString().replace(".", "")}`;
		if (!tvaArray[prop]) {
			tvaArray[prop] = {
				tva: vente.tva,
				montantTva: 0,
				totalTTC: 0,
				discounts: 0,
				montantDiscounts: 0,
			};
		}
		tvaArray[prop].montantTva += vente.montantTva;
		tvaArray[prop].totalTTC += vente.totalTTC;
		tvaArray[prop].discounts += vente.discounts ?? 0;
		tvaArray[prop].montantDiscounts += vente.montantDiscounts ?? 0;
	});
	const allTVA = Object.keys(tvaArray);

	const resultatBrut =
		soldeReporte +
		reportANouveau +
		totalRecettesMontantIn -
		totalRecettesMontantOut;
	return (
		<>
			<Card className="rounded-md p-0 text-xs">
				<CardBody className=" flex flex-col p-0">
					{allTVA.map((line, line_index) => {
						const tvaLine = tvaArray[line];
						return (
							<React.Fragment key={line_index}>
								<Typography
									variant="h6"
									color="blue-gray"
									className="p-2"
								>
									TVA {tvaLine.tva}%
								</Typography>
								<table className="min-w-full leading-normal">
									<tbody>
										<tr>
											<td className="p-2 border-b border-t border-gray-200">
												Montant hors-taxe
											</td>
											<td className="p-2 border-b border-t border-gray-200"></td>
											<td className="p-2 border-b border-t border-gray-200"></td>
											<td className="p-2 border-b border-t border-gray-200 text-right">
												{(tvaLine.totalTTC - tvaLine.montantTva).toFixed(2)}€
											</td>
										</tr>
										<tr>
											<td className="p-2 border-b border-gray-200">Taxe</td>
											<td className="p-2 border-b border-gray-200"></td>
											<td className="p-2 border-b border-gray-200"></td>
											<td className="p-2 border-b border-gray-200 text-right">
												{tvaLine.montantTva.toFixed(2)}%
											</td>
										</tr>

										<tr>
											<td className="p-2 border-b border-gray-300">Taxe</td>
											<td className="p-2 border-b border-gray-300 ">
												Montant taxe comprise
											</td>
											<td className="p-2 border-b border-gray-300"></td>
											<td className="p-2 border-b border-gray-300 text-right">
												{tvaLine.totalTTC.toFixed(2)}€
											</td>
										</tr>
									</tbody>
								</table>
							</React.Fragment>
						);
					})}
				</CardBody>
			</Card>

			<Card className="rounded-md p-0 text-xs mt-5">
				<CardBody className="p-0">
					<Typography
						variant="h6"
						color="blue-gray"
						className="p-2"
					>
						Taxes sur les articles offerts
					</Typography>

					<table className="min-w-full leading-normal">
						<tbody>
							{allTVA.map((line, line_index) => {
								const tvaLine = tvaArray[line];
								return (
									<React.Fragment key={line_index}>
										<tr>
											<td className="p-2 border-b border-t border-gray-200">
												TVA {tvaLine.tva}% sur{" "}
												{tvaLine.montantDiscounts.toFixed(2)}€ d'articles
												complémentaires
											</td>
											<td className="p-2 border-b border-t border-gray-200"></td>
											<td className="p-2 border-b border-t border-gray-200"></td>
											<td className="p-2 border-b border-t border-gray-200 text-right">
												{totalOnComplementaryItems > 0
													? ((totalOnComplementaryItems * taxes) / 100).toFixed(
															2
													  )
													: (0).toFixed(2)}
												€
											</td>
										</tr>
									</React.Fragment>
								);
							})}
							<tr className="font-bold">
								<td className="p-2">Total</td>
								<td className="p-2"></td>
								<td className="p-2"></td>
								<td className="p-2 text-right">
									{totalOnComplementaryItems > 0
										? ((totalOnComplementaryItems * taxes) / 100).toFixed(2)
										: (0).toFixed(2)}
									€
								</td>
							</tr>
						</tbody>
					</table>
				</CardBody>
			</Card>
		</>
	);
};

export default RapportTVA;
