import colors from "tailwindcss/colors";

function CalCaseWhite({
	selected,
	selectedColor,
	defaultColor,
}: {
	selected?: boolean;
	selectedColor?: keyof typeof colors;
	defaultColor?: keyof typeof colors;
}) {
	const selectedBorder = `border-${selectedColor}-700`;
	const defaultBorder = `border-${defaultColor}-700`;
	return (
		<div
			className={`flex w-1 h-1 border-[1px]  ${
				selected
					? `${selectedColor ? selectedBorder : "border-blue-800/50 "}`
					: `${defaultColor ? defaultBorder : "border-blue-500/50 "}`
			} bg-white`}
		></div>
	);
}

export default CalCaseWhite;
