// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {
	doc,
	onSnapshot,
	addDoc,
	collection,
	query,
	updateDoc,
	deleteDoc,
	getDocs,
	where,
	getFirestore,
} from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: process.env.VITE_FIREBASE_APIKEY,
	authDomain: process.env.VITE_FIREBASE_AUTH_DOMAIN,
	projectId: process.env.VITE_FIREBASE_PROJECT_ID,
	storageBucket: process.env.VITE_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.VITE_FIREBASE_MESSAGE_SENDER_ID,
	appId: process.env.VITE_FIREBASE_APP_ID,
	measurementId: process.env.VITE_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export {
	doc,
	getDocs,
	where,
	onSnapshot,
	addDoc,
	collection,
	query,
	updateDoc,
	deleteDoc,
};
// const analytics = getAnalytics(app);
// export const db = getFirestore(app);
// export const firebaseAuth = getAuth(app);
//const credential = EmailAuthProvider.credential(email, password);

/*
createUserWithEmailAndPassword(auth, email, password)
	.then((userCredential) => {
		// Signed up
		const user = userCredential.user;
		// ...
	})
	.catch((error) => {
		const errorCode = error.code;
		const errorMessage = error.message;
		// ..
	});

signInWithEmailAndPassword(auth, email, password)
		.then((userCredential) => {
			// Signed in
			const user = userCredential.user;
			// ...
		})
		.catch((error) => {
			const errorCode = error.code;
			const errorMessage = error.message;
		});*/

// Get a list of cities from your database
async function getCities(db) {
	const citiesCol = collection(db, "cities");
	const citySnapshot = await getDocs(citiesCol);
	const cityList = citySnapshot.docs.map((doc) => doc.data());
	return cityList;
}
