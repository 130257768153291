import React, { useEffect, useState } from "react";

import * as func from "@/lib/functions";
import * as enums from "@/lib/enums";
import * as types from "@/lib/types";
import moment, { Moment } from "moment";

import RdvCase from "./elements/RdvCase";
import CalendarOptionFilter from "../selectors/CalendarOptionFilter";
import { PlayIcon, PlusIcon } from "@heroicons/react/24/solid";
import { Fab } from "@mui/material";
import CalendarEditMeeting from "./CalendarEditMeeting";
import { Card } from "@material-tailwind/react";
import { useAppSettingStore } from "_services/useAppSettingStore";
import SettingButton from "../buttons/SettingButton";
import TodoDetail from "../todos/TodoDetail";

CalendarWeek.propTypes = {};

function CalendarWeek({
	currentDate,
	dateArray,
	nbDaysAvailable,
	weekCalendarDays,
}: {
	currentDate: Moment;
	dateArray: any[];
	nbDaysAvailable: number;
	weekCalendarDays: {
		id: number;
		active: boolean;
		title: string;
	}[];
}) {
	const [calendarOption, appConfig] = useAppSettingStore((state) => [
		state.calendarOption,
		state.appConfig,
	]);
	let heureDebut = 0;
	if (
		calendarOption.dayHourRange[0] &&
		calendarOption.dayHourRange[0].split(":")[0]
	) {
		heureDebut = parseInt(calendarOption.dayHourRange[0].split(":")[0]);
	}
	let heureFin = 23;
	if (
		calendarOption.dayHourRange[1] &&
		calendarOption.dayHourRange[1].split(":")[0]
	) {
		heureFin = parseInt(calendarOption.dayHourRange[1].split(":")[0]) + 1;
	}

	const datarange = heureFin - heureDebut;
	const arrayOfHours = new Array(datarange).fill(0);

	const todayDate = moment();
	return (
		<>
			<div className="flex flex-col h-full w-full overflow-hidden">
				<div className="flex flex-col relative w-full overflow-y-auto no-scollbar">
					{/* <ModalNoButtonComponent
					size="lg"
					show={showEditRdvModal}
					placement="top"
					handleClose={() => {
						setShowEditRdvModal(false);
					}}
					handleShow={() => {
						setShowEditRdvModal(true);
					}}
				>
					<CalendarEditMeeting meeting={selectedMeeting} />
				</ModalNoButtonComponent> */}
					{/* <div className="flex m-0 items-center w-full">
					<div className="grow ">
						<span className="bold normal-case">{`Rendez-vous de la semaine ${selectedCalendarDate.week()}`}</span>{" "}
						<span className="text-lowercase normal">
							(
							{`du  ${selectedCalendarDate
								.startOf("week")
								.format("ddd D MMM YYYY")} au  ${selectedCalendarDate
								.endOf("week")
								.format("ddd D MMM YYYY")}`}
							)
						</span>
					</div>
					<div className="justify-end shrink">
						<CalendarOptionFilter />
					</div>
				</div> */}
					<div className={`grid h-full w-full grid-cols-${nbDaysAvailable}`}>
						<div className={`col-span-${nbDaysAvailable} py-3`}>
							test toute la semaine
						</div>
						{dateArray.map((dz, idx) => {
							const dyZo = moment(dz, "YYYYMMDD");
							const isToday =
								todayDate.format("YYYYMMDD") === dyZo.format("YYYYMMDD");
							let currHour = -1;
							if (weekCalendarDays[idx].active) {
								return (
									<div
										key={idx}
										className={
											"grid grid-cols-1 w-full divide-y-2 divide-x-[1px] divide-blue-200/50" +
											(isToday ? "  bg-blue-200/50 " : "") +
											(idx >= 5 ? " weekend " : "")
										}
									>
										<div className="h-0"></div>
										{arrayOfHours.map((hour, hour_index) => {
											let isNewHour = false;
											if (currHour !== hour_index + heureDebut) {
												currHour = hour_index + heureDebut;
												isNewHour = true;
											}
											let caseDate = moment(
												`${dyZo.format("DD-MM-YYYY")}T${currHour}:00`,
												"DD-MM-YYYYTH:mm"
											);
											return (
												<React.Fragment key={hour_index}>
													<SettingButton
														title={`Notes du ${caseDate.format("D MMMM YYYY")}`}
														className={
															"flex w-full h-full rounded-none bg-transparent p-0 m-0 shadow-none text-inherit font-normal"
														}
														element={<TodoDetail date={caseDate} />}
													>
														<div className="relative flex h-20 w-full ">
															<div
																className={`${
																	idx === 0 ? "absolute grid" : "hidden"
																} grid-cols-1 h-6 w-10 py-[2px] px-[3px] -mt-3  md:ml-2 text-end text-xs border-2 rounded-md border-blue-200/50 bg-white z-[1]`}
															>
																{`${currHour}:00`}
															</div>
															<div className=""></div>
															<div className=""></div>
														</div>
													</SettingButton>
												</React.Fragment>
											);
										})}
									</div>
								);
							}
							return "";
						})}
					</div>
				</div>
			</div>
		</>
	);
}

export default CalendarWeek;
