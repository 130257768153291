import * as enums from "@/lib/enums";
import { Button } from "@material-tailwind/react";
import { useReportStore } from "_services/useReportStore";
import React from "react";
import { useNavigate } from "react-router-dom";

function ReportDisplayViewFilters() {
	const [displayView, setDisplayView] = useReportStore((state) => [
		state.displayView,
		state.setDisplayView,
	]); // Fonctions pour changer les filtres
	const navigate = useNavigate();
	const changerVue = (nouvelleVue: string) => {
		navigate(`#${nouvelleVue}`);
		//setDisplayView(nouvelleVue);
	};

	const buttons = [
		{
			label: "Classique",
			type: enums.REPORT_FILTER_VIEW_CLASSIC,
		},
		{
			label: "Comptable",
			type: enums.REPORT_FILTER_VIEW_ACCOUNTING,
		},
		{
			label: "Statistique",
			type: enums.REPORT_FILTER_VIEW_STATISTICAL,
		},
		{
			label: "Profils de compte",
			type: enums.REPORT_FILTER_VIEW_ACCOUNTPROFILES,
		},
		{
			label: "Condensé",
			type: enums.REPORT_FILTER_VIEW_CONDENCED,
		},
	];

	return (
		<div className="flex flex-col">
			<div className="">Afficher la vue:</div>
			<div className="flex flex-row gap-1">
				{buttons.map((button) => {
					return (
						<Button
							key={button.label}
							onClick={() => changerVue(button.type)}
							className={
								`text-xs capitalize font-normal px-4 py-2 rounded-md text-gray-800 ${displayView === button.type ? "bg-blue-600/50 font-bold" : "bg-gray-300/50 font-normal"}`
							}
						>
							{button.label}
						</Button>
					);
				})}
			</div>
		</div>
	);
}

export default ReportDisplayViewFilters;
