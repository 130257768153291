import { Button } from "@material-tailwind/react";
import { useModalStore } from "_services/useModalStore";
import React, { ReactNode } from "react";
import SettingContentLayout from "../app-templates/SettingContentLayout";

function SettingButton({
	children,
	element,
	title,
	className,
}: {
	children: ReactNode;
	element: ReactNode;
	title: string;
	className: string;
}) {
	const [openModalDetail] = useModalStore((state) => [state.openModalDetail]);
	return (
		<Button
			className={
				className ??
				"w-[29px] h-[30px] p-[2px] items-center justify-center rounded-sm bg-blue-800/50"
			}
			onClick={() => {
				openModalDetail(
					<SettingContentLayout title={title}>{element}</SettingContentLayout>
				);
			}}
		>
			{children}
		</Button>
	);
}

export default SettingButton;
