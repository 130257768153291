import React from 'react'

function WaiterIcon({ className }: { className?: string }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			className={className}
			viewBox="0 0 25.599 25.599"
		>
			<path
				id="Tracé_11362"
				data-name="Tracé 11362"
				d="M0,0H25.6V25.6H0Z"
				fill="none"
			/>
			<g
				id="Groupe_5728"
				data-name="Groupe 5728"
				transform="translate(6 1)"
			>
				<path
					id="Tracé_11365"
					data-name="Tracé 11365"
					d="M376.833-4244.187l.305-1.614,2.355-.7,2.224.7.349,2.006.654-2.006-1.221-1.744-2.442-.305a14.123,14.123,0,0,0-2.224.829c-.087.174-.567,1.221-.567,1.221Z"
					transform="translate(-373 4248)"
					fill="rgba(37,67,116,0.86)"
				/>
				<path
					id="Tracé_11364"
					data-name="Tracé 11364"
					d="M-9041.594-362.754l1,.426h1.177l5.843-2.921h.916l.523.828s.927.425,1.09.785-.436.654-.436.654h-2.094v3.925h.916l.523-.872.218-1.526,1.309-.872a5.4,5.4,0,0,0,1.438-.959,1.833,1.833,0,0,0,.479-1.134l-1.918-6.453-2.05-1h-.916v1h-.479l-.828-.306-.872-.218-1.352.872-.741-.349V-372.1a8.275,8.275,0,0,0-2.05.916,1.759,1.759,0,0,0-.7,1.09l-1,2.965-.437,2.7v1.134Z"
					transform="translate(9042.2 381.882)"
					fill="rgba(37,67,116,0.61)"
				/>
				<path
					id="Tracé_11363"
					data-name="Tracé 11363"
					d="M.353.458A2.8,2.8,0,0,1,1.574,0H5.237l.48.851V6.825H-.214V1.461A1.9,1.9,0,0,1,.353.458Z"
					transform="translate(3 16)"
					fill="#fff"
				/>
				<path
					id="Tracé_11350"
					data-name="Tracé 11350"
					d="M-9041.511-345.885a1.458,1.458,0,0,1-.906-.3,1.8,1.8,0,0,1-.594-1.4,22.794,22.794,0,0,1,1.169-5.64,4.383,4.383,0,0,1,.917-1.524,3.59,3.59,0,0,1,1.476-.8.179.179,0,0,1,.217.131.178.178,0,0,1-.131.217,3.267,3.267,0,0,0-1.312.709,4.068,4.068,0,0,0-.826,1.381,23.03,23.03,0,0,0-1.151,5.509,1.467,1.467,0,0,0,.455,1.131,1.163,1.163,0,0,0,.843.212l.031,0a.18.18,0,0,1,.177.148.18.18,0,0,1-.145.208A1.3,1.3,0,0,1-9041.511-345.885Z"
					transform="translate(9043.016 365.49)"
					fill="#254374"
				/>
				<path
					id="Tracé_11351"
					data-name="Tracé 11351"
					d="M-9034.764-343.134l-.031,0a.18.18,0,0,1-.146-.206,16.2,16.2,0,0,0,.23-2.686c-.031-.39-.141-.521-.2-.565a.157.157,0,0,0-.1-.031.177.177,0,0,1-.217-.129.178.178,0,0,1,.129-.219.492.492,0,0,1,.393.086,1.071,1.071,0,0,1,.352.828,16.135,16.135,0,0,1-.232,2.774A.182.182,0,0,1-9034.764-343.134Z"
					transform="translate(9038.024 359.859)"
					fill="#254374"
				/>
				<path
					id="Tracé_11352"
					data-name="Tracé 11352"
					d="M-9029.271-351.379a.18.18,0,0,1-.181-.179v-4.93l-.84.442a.158.158,0,0,1-.027.012,1.2,1.2,0,0,1-.843-.015,1.05,1.05,0,0,1-.4-.713c0-.008,0-.015,0-.023v-.871c0-.008,0-.015,0-.023a1.049,1.049,0,0,1,.4-.713,1.2,1.2,0,0,1,.843-.015.161.161,0,0,1,.027.012l1.027.541,1.257-.546.017-.006a1.2,1.2,0,0,1,.843.016,1.009,1.009,0,0,1,.378.718.108.108,0,0,1,0,.018v.871a.152.152,0,0,1,0,.036c0,.023-.12.558-.554.637a.625.625,0,0,1-.112.01,2.111,2.111,0,0,1-.878-.3.179.179,0,0,1-.063-.246.179.179,0,0,1,.245-.064,1.757,1.757,0,0,0,.69.247.32.32,0,0,0,.055,0c.168-.031.245-.278.263-.342v-.839c-.012-.1-.07-.358-.2-.436a.864.864,0,0,0-.542.014l-1.328.577a.18.18,0,0,1-.155-.006l-1.089-.573a.842.842,0,0,0-.538-.011.688.688,0,0,0-.217.438v.846a.7.7,0,0,0,.217.438.855.855,0,0,0,.537-.011l1.09-.573a.181.181,0,0,1,.177.005.181.181,0,0,1,.086.154v5.227A.179.179,0,0,1-9029.271-351.379Z"
					transform="translate(9035.669 367.37)"
					fill="#254374"
				/>
				<path
					id="Tracé_11353"
					data-name="Tracé 11353"
					d="M-9016.5-345.7a.179.179,0,0,1-.152-.086.18.18,0,0,1,.06-.247l1.575-.964a2.089,2.089,0,0,0,.584-.862,4.78,4.78,0,0,0-.158-1.837l-.937-3.277a9.254,9.254,0,0,0-.562-1.282,3.726,3.726,0,0,0-1.442-1.185,2.617,2.617,0,0,0-1.307-.219l-.024,0a.179.179,0,0,1-.177-.156.179.179,0,0,1,.153-.2,2.938,2.938,0,0,1,1.509.25,4.05,4.05,0,0,1,1.592,1.318,9.508,9.508,0,0,1,.6,1.358.046.046,0,0,1,0,.011l.939,3.285a.009.009,0,0,1,0,.005,5.08,5.08,0,0,1,.153,2.039,2.425,2.425,0,0,1-.709,1.037.1.1,0,0,1-.019.013l-1.587.971A.174.174,0,0,1-9016.5-345.7Z"
					transform="translate(9027.645 365.797)"
					fill="#254374"
				/>
				<path
					id="Tracé_11354"
					data-name="Tracé 11354"
					d="M-9016.365-344.261a.179.179,0,0,1-.179-.158,6.728,6.728,0,0,1,0-1.624,1.241,1.241,0,0,1,.56-.885l.163.319h0a.906.906,0,0,0-.373.637,6.381,6.381,0,0,0,0,1.512.18.18,0,0,1-.157.2Z"
					transform="translate(9026.074 359.962)"
					fill="#254374"
				/>
				<path
					id="Tracé_11355"
					data-name="Tracé 11355"
					d="M-9017.335-326.961a.179.179,0,0,1-.173-.132.179.179,0,0,1,.124-.22,1.071,1.071,0,0,0,.653-.948,25.139,25.139,0,0,0,.12-2.661.179.179,0,0,1,.179-.18h0a.179.179,0,0,1,.18.178,25.154,25.154,0,0,1-.123,2.714,1.411,1.411,0,0,1-.912,1.242A.182.182,0,0,1-9017.335-326.961Z"
					transform="translate(9026.661 349.98)"
					fill="#254374"
				/>
				<path
					id="Tracé_11356"
					data-name="Tracé 11356"
					d="M-9029.779-332.116h-5.924a.179.179,0,0,1-.18-.179v-5.285c0-.012,0-.024,0-.036a2.342,2.342,0,0,1,.638-1.143,3.326,3.326,0,0,1,1.3-.623.178.178,0,0,1,.04,0h2.66a2.8,2.8,0,0,1,.3-.015,1.616,1.616,0,0,1,.868.205,1.38,1.38,0,0,1,.479.965.043.043,0,0,1,0,.011v5.924A.18.18,0,0,1-9029.779-332.116Zm-5.744-.359h5.565v-5.738a1.021,1.021,0,0,0-.321-.687,1.253,1.253,0,0,0-.667-.143,2.382,2.382,0,0,0-.263.014h-2.673a2.972,2.972,0,0,0-1.12.535,1.977,1.977,0,0,0-.521.932Z"
					transform="translate(9038.442 355.135)"
					fill="#254374"
				/>
				<path
					id="Tracé_11357"
					data-name="Tracé 11357"
					d="M-9018.66-337.47a1.1,1.1,0,0,1,.479.1.744.744,0,0,1,.406.453.8.8,0,0,0,.17.282,2.743,2.743,0,0,0,.537.431c.1.054.557.318.557.679s-.453.671-.546.731a.176.176,0,0,1-.1.029h-2.033a.179.179,0,0,1-.18-.179v-2.265a.18.18,0,0,1,.126-.171A2.226,2.226,0,0,1-9018.66-337.47Zm1.451,2.342c.169-.122.341-.3.341-.4s-.22-.284-.37-.364l0,0a3,3,0,0,1-.622-.5,1.133,1.133,0,0,1-.241-.392.4.4,0,0,0-.229-.267.759.759,0,0,0-.325-.06,1.775,1.775,0,0,0-.348.039v1.945Z"
					transform="translate(9027.85 353.897)"
					fill="#254374"
				/>
				<path
					id="Tracé_11358"
					data-name="Tracé 11358"
					d="M-9033.317-378.335a.179.179,0,0,1-.174-.134l-.532-2.046a1.418,1.418,0,0,1,0-.9,2.475,2.475,0,0,1,.894-1.285,4.008,4.008,0,0,1,2.341-.565h.162a3.724,3.724,0,0,1,2.232.581,2.72,2.72,0,0,1,.89,1.407,3,3,0,0,1-.123,1.2.089.089,0,0,1,0,.015l-.5,1.354a.18.18,0,0,1-.23.106.18.18,0,0,1-.107-.23l.5-1.347a2.826,2.826,0,0,0,.126-.984c-.319-.966-.728-1.706-2.786-1.744h-.156c-2.146,0-2.62.829-2.9,1.611a1.075,1.075,0,0,0,0,.658l.006.019.534,2.054a.179.179,0,0,1-.128.219A.18.18,0,0,1-9033.317-378.335Z"
					transform="translate(9037.295 383.26)"
					fill="#254374"
				/>
				<path
					id="Tracé_11359"
					data-name="Tracé 11359"
					d="M-9029.568-379.369a4.96,4.96,0,0,1,1.551.249,1.366,1.366,0,0,1,.913.871,1.535,1.535,0,0,1,.081.585v2.954a.187.187,0,0,1-.014.069,3.4,3.4,0,0,1-1.439,1.633,2.6,2.6,0,0,1-1.06.3,3.593,3.593,0,0,1-1.185-.291,3.063,3.063,0,0,1-1.587-1.642.212.212,0,0,1-.011-.066v-2.962c0-.323,0-1.081,1.17-1.449A5.274,5.274,0,0,1-9029.568-379.369Zm2.186,4.621v-2.924c0-.006,0-.013,0-.019a1.05,1.05,0,0,0-.748-1.088,4.6,4.6,0,0,0-1.438-.231,4.906,4.906,0,0,0-1.472.231c-.92.289-.92.8-.92,1.107v2.926a2.7,2.7,0,0,0,1.359,1.406,3.348,3.348,0,0,0,1.064.27,2.213,2.213,0,0,0,.913-.265A3.008,3.008,0,0,0-9027.383-374.748Z"
					transform="translate(9036.155 380.765)"
					fill="#254374"
				/>
				<path
					id="Tracé_11360"
					data-name="Tracé 11360"
					d="M-9028.417-360.928a.179.179,0,0,1-.179-.179v-1.548a.179.179,0,0,1,.179-.179.179.179,0,0,1,.18.179v1.548A.179.179,0,0,1-9028.417-360.928Z"
					transform="translate(9033.77 370.24)"
					fill="#254374"
				/>
				<path
					id="Tracé_11361"
					data-name="Tracé 11361"
					d="M-9028.417-360.928a.179.179,0,0,1-.179-.179v-1.548a.179.179,0,0,1,.179-.179.179.179,0,0,1,.18.179v1.548A.179.179,0,0,1-9028.417-360.928Z"
					transform="translate(9036.193 370.24)"
					fill="#254374"
				/>
				<ellipse
					id="Ellipse_480"
					data-name="Ellipse 480"
					cx="0.359"
					cy="0.359"
					rx="0.359"
					ry="0.359"
					transform="translate(7.538 11.931)"
					fill="#254374"
				/>
				<path
					id="Ellipse_480_-_Contour"
					data-name="Ellipse 480 - Contour"
					d="M.359,0A.359.359,0,1,1,0,.359.359.359,0,0,1,.359,0Z"
					transform="translate(7.538 11.931)"
					fill="#254374"
				/>
				<ellipse
					id="Ellipse_481"
					data-name="Ellipse 481"
					cx="0.359"
					cy="0.359"
					rx="0.359"
					ry="0.359"
					transform="translate(7.538 14.084)"
					fill="#254374"
				/>
				<path
					id="Ellipse_481_-_Contour"
					data-name="Ellipse 481 - Contour"
					d="M.359,0A.359.359,0,1,1,0,.359.359.359,0,0,1,.359,0Z"
					transform="translate(7.538 14.084)"
					fill="#254374"
				/>
				<path
					id="Rectangle_335"
					data-name="Rectangle 335"
					d="M.538.359A.18.18,0,0,0,.359.538v.717a.179.179,0,1,0,.359,0V.538A.18.18,0,0,0,.538.359M.538,0a.538.538,0,0,1,.538.538v.717A.538.538,0,1,1,0,1.256V.538A.538.538,0,0,1,.538,0Z"
					transform="translate(3.049 4.04)"
					fill="#254374"
				/>
				<path
					id="Rectangle_336"
					data-name="Rectangle 336"
					d="M.538.359A.18.18,0,0,0,.359.538v.717a.179.179,0,1,0,.359,0V.538A.18.18,0,0,0,.538.359M.538,0a.538.538,0,0,1,.538.538v.717A.538.538,0,1,1,0,1.256V.538A.538.538,0,0,1,.538,0Z"
					transform="translate(8.789 4.04)"
					fill="#254374"
				/>
			</g>
		</svg>
	);
}

export default WaiterIcon