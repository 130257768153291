import { db } from "@/firebase/BaseConfig";
import * as menuTypes from "@/lib/menu-types";
import * as types from "@/lib/types";
import {
	addDoc,
	collection,
	doc,
	getDocs,
	query,
	updateDoc,
	where,
} from "firebase/firestore";
import { create } from "zustand";

interface AppStore {
	isOpen: boolean;
	openMenu: () => void;
	closeMenu: () => void;
	isDrawerOpen: boolean;
	drawerContent?: React.ReactElement;
	drawerPlacement?: types.Side;
	drawerWidth?: string;
	openDrawer: (drawerWidth?: string) => void;
	closeDrawer: () => void;
	setDrawerContent: (
		drawerContent: React.ReactElement,
		drawerPlacement?: types.Side
	) => void;
	roomOptions: types.RoomOption;
	setRoomOptions: (roomOptions?: types.RoomOption) => void;
	selectedTile?: number;
	setSelectedTile: (selectedTile: number) => void;
	selectedSection?: types.Section;
	setSelectedSection: (selectedSection: types.Section) => void;
	appLanguage: menuTypes.Language;
	setAppLanguage: (appLanguageDetail: menuTypes.LanguageDetail) => void;
	menuLanguage: menuTypes.Language;
	setMenuLanguage: (menuLanguageDetail: menuTypes.LanguageDetail) => void;
	testMenuLanguage: menuTypes.Language;
	setTestMenuLanguage: (
		testMenuLanguageDetail: menuTypes.LanguageDetail
	) => void;

	calendarOption: types.CalendarOption;
	getCalendarOption: (uid: string) => void;
	setCalendarOption: (
		calendarOption: types.CalendarOption,
		uid: string
	) => void;
	appConfig: types.AppConfig;
	getAppConfig: (uid: string) => void;
	setAppConfig: (appConfig: types.AppConfig, uid: string) => void;
}

export const useAppSettingStore = create<AppStore>()((set, get) => ({
	appConfig: new types.AppConfig(),
	getAppConfig: async (uid: string) => {
		const q = query(collection(db, "appConfig"), where("uid", "==", uid));
		const querySnapshot = await getDocs(q);
		let tmpcappConfig = new types.CalendarOption();
		if (querySnapshot) {
			querySnapshot.forEach((doc) => {
				// doc.data() is never undefined for query doc snapshots
				tmpcappConfig = doc.data() as types.CalendarOption;
			});
		}

		set({ appConfig: tmpcappConfig });
	},
	setAppConfig: async (appConfig: types.AppConfig, uid: string) => {
		const q = query(collection(db, "appConfig"), where("uid", "==", uid));
		const querySnapshot = await getDocs(q);
		if (querySnapshot) {
			querySnapshot.forEach(async (fireDoc) => {
				const docRef = doc(db, "appConfig", fireDoc.id);
				await updateDoc(docRef, {
					...appConfig,
					uid,
				});
			});
		} else {
			await addDoc(collection(db, "appConfig"), {
				...appConfig,
				uid,
			});
		}

		set({ appConfig });
	},
	calendarOption: new types.CalendarOption(),
	getCalendarOption: async (uid: string) => {
		const q = query(collection(db, "calendarOptions"), where("uid", "==", uid));
		const querySnapshot = await getDocs(q);
		let tmpcalendarOption = new types.CalendarOption();
		if (querySnapshot) {
			querySnapshot.forEach((doc) => {
				// doc.data() is never undefined for query doc snapshots
				tmpcalendarOption = doc.data() as types.CalendarOption;
			});
		}

		set({ calendarOption: tmpcalendarOption });
	},
	setCalendarOption: async (
		calendarOption: types.CalendarOption,
		uid: string
	) => {
		const q = query(collection(db, "calendarOptions"), where("uid", "==", uid));
		const querySnapshot = await getDocs(q);
		if (querySnapshot) {
			querySnapshot.forEach(async (fireDoc) => {
				const docRef = doc(db, "calendarOptions", fireDoc.id);
				await updateDoc(docRef, {
					...calendarOption,
					uid,
				});
			});
		} else {
			await addDoc(collection(db, "calendarOptions"), {
				...calendarOption,
				uid,
			});
		}

		set({ calendarOption });
	},
	isOpen: false,
	isDrawerOpen: false,
	roomOptions: new types.RoomOption(),

	openMenu: () => set({ isOpen: true }),
	closeMenu: () => set({ isOpen: false }),
	openDrawer: (drawerWidth?: string) =>
		set({ isDrawerOpen: true, drawerWidth }),
	closeDrawer: () =>
		set({
			isDrawerOpen: false,
			drawerContent: undefined,
			drawerWidth: undefined,
		}),
	setDrawerContent: (
		drawerContent: React.ReactElement,
		drawerPlacement?: types.Side
	) => set({ drawerContent, drawerPlacement }),
	setRoomOptions: (roomOptions?: types.RoomOption) => set({ roomOptions }),
	setSelectedTile: (selectedTile: number) => set({ selectedTile }),
	setSelectedSection: (selectedSection: types.Section) =>
		set({ selectedSection }),
	appLanguage: "fr",
	setAppLanguage: (appLanguageDetail: menuTypes.LanguageDetail) =>
		set({ appLanguage: appLanguageDetail.id }),
	menuLanguage: "fr",
	setMenuLanguage: (menuLanguageDetail: menuTypes.LanguageDetail) =>
		set({ menuLanguage: menuLanguageDetail.id }),
	testMenuLanguage: "fr",
	setTestMenuLanguage: (testMenuLanguageDetail: menuTypes.LanguageDetail) =>
		set({ testMenuLanguage: testMenuLanguageDetail.id }),
}));
