import * as invoiceTypes from "@/lib/invoice-types";
import PageContent from "@/pages/PageContent";
import { Card } from "@material-tailwind/react";
import React from "react";
import { Link } from "react-router-dom";

function Admin() {
	const linkMap: invoiceTypes.ReportCardsData = {
		title: "Admin",
		description: "",
		items: [],
	};
	return (
		<PageContent>
            admin
			{linkMap.items.map((mapItem, index) => {
				return (
					<React.Fragment key={index}>
						<Card className="rounded-md">
							<div className="flex flex-row">
								<div className="">{mapItem.icon}</div>
								<div className="">
									<Link to={mapItem.link}>{mapItem.label}</Link>
								</div>
							</div>
						</Card>
					</React.Fragment>
				);
			})}
		</PageContent>
	);
}

export default Admin;
