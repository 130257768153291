import React from "react";

function TabRoundBottom({
	isRight,
	className,
}: {
	isRight?: boolean;
	className?: string;
}) {
	return (
		<>
			{isRight ? (
				<div className="absolute w-4 h-4 bottom-0 -right-4  overflow-hidden">
					<div
						className={`absolute flex w-10 h-10 -bottom-2 -left-2 bg-transparent rounded-full border-b-8 border-l-8  ${
							className ?? "border-gray-400"
						}`}
					></div>
				</div>
			) : (
				<div className="absolute w-4 h-4 bottom-0 -left-4  overflow-hidden">
					<div
						className={`absolute flex w-10 h-10 -bottom-2 -right-2 bg-transparent rounded-full border-b-8 border-r-8 ${
							className ?? "border-gray-400"
						}`}
					></div>
				</div>
			)}
		</>
	);
}

export default TabRoundBottom;
