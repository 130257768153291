import { Card, CardHeader, CardBody } from "@material-tailwind/react";
import React from "react";
import SubSectionTitle from "../sections/SubSectionTitle";
import { Link } from "react-router-dom";

export interface ReportCardProps {
	title: string;
	description: string;
	items: { label: string; link: string }[];
}
const ReportCard: React.FC<ReportCardProps> = ({
	title,
	description,
	items,
}) => {
	return (
		<Card className=" rounded-md p-2">
			<div className="flex flex-col">
				<div className="leading-3">
					<SubSectionTitle className="px-2 py-0 my-0">{title}</SubSectionTitle>{" "}
				</div>
				<div className="leading-3 px-2 text-xs">{description}</div>
			</div>
			<ul className="p-2 mt-3">
				{items.map((item, index) => {
					return (
						<React.Fragment key={index}>
							<li>
								<Link to={`/admin/${item.link}`}>{item.label}</Link>
							</li>
						</React.Fragment>
					);
				})}
			</ul>
		</Card>
	);
};

export default ReportCard;
