import React from "react";

function SectionTitle({
	children,
	className,
}: {
	children: React.ReactNode;
	className?: string;
}) {
	return (
		<div className={`flex text-3xl m-3 my-2 ${className ?? "w-full"}`}>
			{children}
		</div>
	);
}

export default SectionTitle;
