import AppContent from "./pages/AppContent";
// import AppFooter from "./AppFooter";

import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import PageContent from "./pages/PageContent";
import "./App.css";
import HomePage from "./pages/HomePage";
import AdminContent from "./pages/AdminContent";

import { Toaster } from "react-hot-toast";
import { AuthProvider } from "./components/auth/AuthContext";
import { useEffect } from "react";
import DashBoard from "./pages/nav/admin/DashBoard";
import Employees from "./pages/nav/admin/Employees";
import Kitchen from "./pages/nav/admin/Kitchen";
import Rapports from "./pages/nav/admin/Rapports";
import Restaurant from "./pages/nav/admin/Restaurant";
import Commandes from "./pages/nav/admin/Commandes";
import Admin from "./pages/nav/admin/Admin";
function App() {
	return (
		<div className="grid grid-rows-[1fr_auto] grid-cols-1 w-full h-full">
			<AuthProvider>
				<Router>
					<Routes>
						<Route
							path="/admin"
							//exact={true}
							element={<Admin />}
						></Route>
						<Route path="/admin/:categ?/:categValue?">
							<Route
								path="dashboard/:categValue?"
								element={<DashBoard />}
							/>
							<Route
								path="employees/:categValue?"
								element={<Employees />}
							/>
							<Route
								path="kitchen/:categValue?"
								element={<Kitchen />}
							/>
							<Route
								path="rapports/:categValue?"
								element={<Rapports />}
							/>
							<Route
								path="restaurant/:categValue?"
								element={<Restaurant />}
							/>
							<Route
								path="commandes/:categValue?"
								element={<Commandes />}
							/>
						</Route>
						<Route
							path="waiter/:restaurantId?/:categ?/:categValue?/:subCateg?/:subCategValue?"
							element={
								<AuthProvider>
									<PageContent>
										<AdminContent />
									</PageContent>
								</AuthProvider>
							}
						/>
					</Routes>
					<Routes>
						<Route
							path="/"
							element={<Admin />}
							//exact
							//element={<HomePage />}
						>
							{/* <Route
							path=":categ?/:categValue?"
							element={<AppContent />}
						/> */}
						</Route>
					</Routes>
				</Router>
			</AuthProvider>

			<div className="toaster !!! ">
				<Toaster />
			</div>
		</div>
	);
}

export default App;
