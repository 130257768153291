import React from "react";
import PageContent from "../../PageContent";

function Commandes() {
	return (
		<PageContent>
			<div>
				<div className="">Commandes</div>
				<div className=""></div>
				<div className=""></div>
				<div className=""></div>
				<div className=""></div>
				<div className=""></div>
				<div className=""></div>
				<div className=""></div>
				<div className=""></div>
				<div className=""></div>
			</div>
		</PageContent>
	);
}

export default Commandes;
