import React from "react";
import {
	Card,
	CardHeader,
	CardBody,
	Button,
	Typography,
} from "@material-tailwind/react";
import { ResultatBrutProps } from "./ResultatBrut";
import { ResultatNetItem } from "@/lib/invoice-types";

const ResultatNet = ({
	recettes,
	soldeReporte,
	reportANouveau,
	taxes,
	nbCouverts,
}: {
	recettes: ResultatNetItem[];
	soldeReporte: number;
	reportANouveau: number;
	taxes: number;
	nbCouverts?: number;
}) => {
	let totalRecettesMontantIn = 0;
	let totalRecettesMontantOut = 0;

	recettes.map((recette) => {
		totalRecettesMontantIn += recette.montantIn - (recette.pourboires ?? 0);
		totalRecettesMontantOut += recette.montantOut;
	});

	const resultatBrut =
		soldeReporte +
		reportANouveau +
		totalRecettesMontantIn -
		totalRecettesMontantOut;
	return (
		<Card className="rounded-md p-0 text-xs">
			<CardBody className="p-0">
				<Typography
					variant="h6"
					color="blue-gray"
					className="p-2"
				>
					Montants reçus
				</Typography>
				<table className="min-w-full leading-normal">
					<tbody>
						{/* Section Drinks */}
						{recettes.map((recette, recette_index) => {
							const inMoinsPourboires =
								recette.montantIn - (recette.pourboires ?? 0);
							const totalSection = inMoinsPourboires - recette.montantOut;
							console.log(
								`totalSection:${inMoinsPourboires} - ${recette.montantOut} =${totalSection}`
							);
							return (
								<React.Fragment key={recette_index}>
									{recette.pourboires && recette.pourboires > 0 ? (
										<>
											<tr className="bg-gray-100 font-bold">
												<td className="p-2 border-b border-gray-200">
													{recette.type}
												</td>
												<td className="p-2 border-b border-gray-200 text-right">
													{recette.montantIn.toFixed(2)}€
												</td>
												<td className="p-2 border-b border-gray-200"></td>
												<td className="p-2 border-b border-gray-200"></td>
											</tr>
											<tr>
												<td className="p-2 border-b border-gray-200">
													Moins les pourboires
												</td>
												<td className="p-2 border-b border-gray-200 text-right">
													{recette.pourboires.toFixed(2)}€
												</td>
												<td className="p-2 border-b border-gray-200"></td>
												<td className="p-2 border-b border-gray-200"></td>
											</tr>
										</>
									) : (
										<></>
									)}

									<tr
										className={`${
											recette.pourboires > 0 ? "" : "bg-gray-100 font-bold"
										} `}
									>
										<td className="p-2 border-b border-gray-200">
											{recette.type}
											{recette.pourboires > 0 && " (Moins les pourboires)"}
										</td>
										<td className="p-2 border-b border-gray-200 text-right">
											{totalSection.toFixed(2)}€
										</td>
										<td className="p-2 border-b border-gray-200 text-right">
											{((totalSection / totalRecettesMontantIn) * 100).toFixed(
												2
											)}
											%
										</td>
										<td className="p-2 border-b border-gray-200 text-right">
											{totalSection.toFixed(2)}€
										</td>
									</tr>
									<tr>
										<td className="p-2 border-b border-gray-200">Sorties</td>
										<td className="p-2 border-b border-gray-200 text-right">
											-{recette.montantOut.toFixed(2)}€
										</td>
										<td className="p-2 border-b border-gray-200 text-right">
											{(recette.montantOut > 0
												? (recette.montantOut / totalSection) * 100
												: 0
											).toFixed(2)}
											%
										</td>
										<td className="p-2 border-b border-gray-200 text-right">
											-{recette.montantOut.toFixed(2)}€
										</td>
									</tr>
									<tr>
										<td className="p-2 border-b border-gray-200">Entrées</td>
										<td className="p-2 border-b border-gray-200 text-right">
											{inMoinsPourboires.toFixed(2)}€
										</td>
										<td className="p-2 border-b border-gray-200 text-right">
											{((inMoinsPourboires / totalSection) * 100).toFixed(2)}%
										</td>
										<td className="p-2 border-b border-gray-200 text-right">
											{inMoinsPourboires.toFixed(2)}€
										</td>
									</tr>
								</React.Fragment>
							);
						})}
						{/* Total */}

						<tr className="bg-gray-100 font-bold">
							<td className="p-2 border-b border-gray-200">
								Total des paiements
							</td>
							<td className="p-2 border-b border-gray-200 text-right">
								{(totalRecettesMontantIn - totalRecettesMontantOut).toFixed(2)}€
							</td>
							<td className="p-2 border-b border-gray-200"></td>
							<td className="p-2 border-b border-gray-200"></td>
						</tr>

						<tr className="bg-gray-100 font-bold">
							<td className="p-2 border-b border-gray-200">
								Solde intermédiaire
							</td>
							<td className="p-2 border-b border-gray-200 text-right">
								{(soldeReporte + reportANouveau).toFixed(2)}€
							</td>
							<td className="p-2 border-b border-gray-200"></td>
							<td className="p-2 border-b border-gray-200"></td>
						</tr>
						<tr>
							<td className="p-2 border-b border-gray-200">
								Report du solde précédent
							</td>
							<td className="p-2 border-b border-gray-200 text-right">
								{soldeReporte.toFixed(2)}€
							</td>
							<td className="p-2 border-b border-gray-200"></td>
							<td className="p-2 border-b border-gray-200"></td>
						</tr>
						<tr>
							<td className="p-2 border-b border-gray-300">Report à nouveau</td>
							<td className="p-2 border-b border-gray-300 text-right">
								{reportANouveau.toFixed(2)}€
							</td>
							<td className="p-2 border-b border-gray-300"></td>
							<td className="p-2 border-b border-gray-300"></td>
						</tr>

						<tr className="bg-gray-100 font-bold text-lg">
							<td className="p-2 border-b border-gray-200">
								CA Net (Recettes)
							</td>
							<td className="p-2 border-b border-gray-200"></td>
							<td className="p-2 border-b border-gray-200"></td>
							<td className="p-2 border-b border-gray-200 text-right">
								{resultatBrut.toFixed(2)}€
							</td>
						</tr>
						<tr>
							<td className="p-2 border-b border-gray-300">Moins les taxes</td>
							<td className="p-2 border-b border-gray-300 text-right">
								{((taxes * resultatBrut) / 100).toFixed(2)}€
							</td>
							<td className="p-2 border-b border-gray-300"></td>
							<td className="p-2 border-b border-gray-300"></td>
						</tr>
						{/* Resultat */}

						<tr className="bg-gray-100 font-bold">
							<td className="p-2 border-b border-gray-200">
								Résultat Net, hors taxes
							</td>
							<td className="p-2 border-b border-gray-200 text-right">
								{(resultatBrut - (taxes * resultatBrut) / 100).toFixed(2)}€
							</td>
							<td className="p-2 border-b border-gray-200"></td>
							<td className="p-2 border-b border-gray-200"></td>
						</tr>

						<tr>
							<td className="p-2">Moyenne par couvert sur les recettes</td>
							<td className="p-2 text-right">
								{nbCouverts && nbCouverts > 0
									? `${nbCouverts} couverts`
									: "N/A"}
							</td>
							<td className="p-2"></td>
							<td className="p-2 text-right">
								{nbCouverts && nbCouverts > 0
									? `${(
											nbCouverts /
											(resultatBrut - (taxes * resultatBrut) / 100)
									  ).toFixed(2)}€`
									: "N/A"}
							</td>
						</tr>
					</tbody>
				</table>
			</CardBody>
		</Card>
	);
};

export default ResultatNet;
