import * as functions from "@/lib/functions";
import moment from "moment";
import React, { useState } from "react";
import TabRoundBottom from "../sections/TabRoundBottom";
import MonthIcon from "@/svg/MonthIcon";
import WeekIcon from "@/svg/WeekIcon";
import DayIcon from "@/svg/DayIcon";
import * as enums from "@/lib/enums";
import colors from "tailwindcss/colors";
import RoundButton from "../buttons/RoundButton";
import {
	ArrowPathIcon,
	ChevronDoubleLeftIcon,
	ChevronDoubleRightIcon,
	ChevronLeftIcon,
	ChevronRightIcon,
	Cog8ToothIcon,
} from "@heroicons/react/24/solid";
import CalendarMonth from "./CalendarMonth";
import TodoList from "../todos/TodoList";
import { Fade } from "@mui/material";
import { useModalStore } from "_services/useModalStore";
import CalendarWeek from "./CalendarWeek";
import { useAppSettingStore } from "_services/useAppSettingStore";
import { useNavigate } from "react-router-dom";
import { Button } from "@material-tailwind/react";
import SettingButton from "../buttons/SettingButton";

moment.locale("fr", enums.momentFR);

function CalendarHome() {
	const navigate = useNavigate();

	const [isOpenDetail, detailNode] = useModalStore((state) => [
		state.isOpenDetail,
		state.detailNode,
	]);
	const [calendarOption, appConfig] = useAppSettingStore((state) => [
		state.calendarOption,
		state.appConfig,
	]);

	const [currentDate, setCurrentDate] = useState(moment());
	const [calMode, setCalMode] = useState(
		window.location.hash
			? parseInt(window.location.hash.substring(1))
			: enums.CALENDAR_MODE_MONTH
	);

	const weekDays = ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"];
	const {
		consultStepMin,
		showMonday,
		showTuesday,
		showWednesday,
		showThursday,
		showFriday,
		showSaturday,
		showSunday,
	} = calendarOption;

	let dateArray = [currentDate.clone().format("YYYYMMDD")];

	let weekCalendarDays = [
		{
			id: 0,
			active: true,
			title: currentDate.clone().format("dddd"),
		},
	];
	let combinaison = "1";

	if (calMode !== enums.CALENDAR_MODE_DAY) {
		const arrayOfDates: any[] = [];
		const arrayTargetDate = currentDate.clone().isoWeekday(4);
		for (let index = -3; index <= 3; index++) {
			arrayOfDates.push(
				moment(arrayTargetDate.format("YYYYMMDD"), "YYYYMMDD")
					.add(index, "days")
					.format("YYYYMMDD")
			);
		}
		dateArray = arrayOfDates;

		weekCalendarDays = [
			{
				id: 0,
				active: showMonday === undefined || showMonday,
				title: "Lundi",
			},
			{
				id: 1,
				active: showTuesday === undefined || showTuesday,
				title: "Mardi",
			},
			{
				id: 2,
				active: showWednesday === undefined || showWednesday,
				title: "Mercredi",
			},
			{
				id: 3,
				active: showThursday === undefined || showThursday,
				title: "Jeudi",
			},
			{
				id: 4,
				active: showFriday === undefined || showFriday,
				title: "Vendredi",
			},
			{
				id: 5,
				active: showSaturday === undefined || showSaturday,
				title: "Samedi",
			},
			{
				id: 6,
				active: showSunday === undefined || showSunday,
				title: "Dimanche",
			},
		];

		// combinaison =
		// 	(weekCalendarDays[0].active ? "1" : "0") +
		// 	"_" +
		// 	(weekCalendarDays[1].active ? "1" : "0") +
		// 	"_" +
		// 	(weekCalendarDays[2].active ? "1" : "0") +
		// 	"_" +
		// 	(weekCalendarDays[3].active ? "1" : "0") +
		// 	"_" +
		// 	(weekCalendarDays[4].active ? "1" : "0") +
		// 	"_" +
		// 	(weekCalendarDays[5].active ? "1" : "0") +
		// 	"_" +
		// 	(weekCalendarDays[6].active ? "1" : "0");
	}

	let nbDaysAvailable = 0;
	weekCalendarDays.forEach((element) => {
		if (element.active) {
			nbDaysAvailable++;
		}
	});

	const currentCalendar = functions.getMonthDays(
		parseInt(currentDate.format("MM")),
		parseInt(currentDate.format("YYYY"))
	);

	const wDays = ["lun", "mar", "mer", "jeu", "ven", "sam", "dim"];
	// Supposons une fonction pour générer les jours du mois (à implémenter selon la logique nécessaire)
	let strMonth = "";

	const todayDate = moment();

	const debutWeek = moment(dateArray[0], "YYYYMMDD");
	const finWeek = moment(dateArray[dateArray.length - 1], "YYYYMMDD");

	return (
		<div className="relative flex flex-col md:grid md:grid-cols-[auto_1fr] w-full h-full  md:overflow-hidden">
			<div className="flex w-full md:w-[250px] md:h-full md:overflow-hidden">
				<TodoList />
			</div>
			<div className=" grid grid-rows-[auto_auto_1fr] w-full h-full p-2  md:overflow-hidden">
				<div className="flex flex-col-reverse md:flex-row justify-between items-center">
					<div className="flex flex-row">
						<div className="">
							<RoundButton
								onClick={() => {
									const gotoMode =
										calMode === enums.CALENDAR_MODE_DAY
											? "week"
											: calMode === enums.CALENDAR_MODE_WEEK
											? "month"
											: calMode === enums.CALENDAR_MODE_MONTH
											? "year"
											: "year";
									setCurrentDate(currentDate.clone().add(-1, gotoMode));
								}}
								className=" h-6 w-6 md:h-8 md:w-8 m-1 bg-blue-200/50"
							>
								<ChevronDoubleLeftIcon className="h-4 w-4 md:h-6 md:w-6 text-blue-700/50" />
							</RoundButton>
						</div>
						<div className="">
							<RoundButton
								onClick={() => {
									const gotoMode =
										calMode === enums.CALENDAR_MODE_DAY
											? "day"
											: calMode === enums.CALENDAR_MODE_WEEK
											? "week"
											: calMode === enums.CALENDAR_MODE_MONTH
											? "month"
											: "year";
									setCurrentDate(currentDate.clone().add(-1, gotoMode));
								}}
								className="h-6 w-6 md:h-8 md:w-8 m-1 bg-blue-200/50"
							>
								<ChevronLeftIcon className="h-4 w-4 md:h-6 md:w-6 text-blue-700/50" />
							</RoundButton>
						</div>
						<div className="relative flex min-w-[150px] bg-blue-300/50 rounded-t-md mx-1 p-2 items-center justify-center">
							<TabRoundBottom className="border-blue-300/50" />
							<TabRoundBottom
								className="border-blue-300/50"
								isRight={true}
							/>

							<div className="">
								<div
									className={`${
										calMode !== enums.CALENDAR_MODE_DAY ? "hidden" : "flex"
									}`}
								>
									{debutWeek.format("D MMM YYYY")}
								</div>
								<div
									className={`${
										calMode !== enums.CALENDAR_MODE_WEEK ? "hidden" : "flex"
									} flex-row gap-1`}
								>
									<div className="">{debutWeek.format("D")}</div>
									<div
										className={`${
											debutWeek.format("MMM") === finWeek.format("MMM") &&
											debutWeek.format("YYYY") === finWeek.format("YYYY")
												? "hidden"
												: "flex"
										}`}
									>
										{debutWeek.format("MMM")}
									</div>
									<div
										className={`${
											debutWeek.format("YYYY") === finWeek.format("YYYY")
												? "hidden"
												: "flex"
										}`}
									>
										{debutWeek.format("YYYY")}
									</div>
									<div className="">-</div>
									<div className="">{finWeek.format("D")}</div>
									<div className="">{finWeek.format("MMM")}</div>
									<div className="">{finWeek.format("YYYY")}</div>
								</div>
								<div
									className={`${
										calMode !== enums.CALENDAR_MODE_MONTH ? "hidden" : "flex"
									}`}
								>
									{currentDate.format("MMMM YYYY")}
								</div>
							</div>
						</div>
						<div className="">
							<RoundButton
								onClick={() => {
									setCurrentDate(moment().clone());
								}}
								className="h-6 w-6 md:h-8 md:w-8 m-1 bg-blue-200/50"
							>
								<ArrowPathIcon className="h-4 w-4 md:h-6 md:w-6 text-blue-700/50" />
							</RoundButton>
						</div>
						<div className="">
							<RoundButton
								onClick={() => {
									const gotoMode =
										calMode === enums.CALENDAR_MODE_DAY
											? "day"
											: calMode === enums.CALENDAR_MODE_WEEK
											? "week"
											: calMode === enums.CALENDAR_MODE_MONTH
											? "month"
											: "year";
									setCurrentDate(currentDate.clone().add(1, gotoMode));
								}}
								className="h-6 w-6 md:h-8 md:w-8 m-1 bg-blue-200/50"
							>
								<ChevronRightIcon className="h-4 w-4 md:h-6 md:w-6 text-blue-700/50" />
							</RoundButton>
						</div>
						<div className="">
							<RoundButton
								onClick={() => {
									const gotoMode =
										calMode === enums.CALENDAR_MODE_DAY
											? "week"
											: calMode === enums.CALENDAR_MODE_WEEK
											? "month"
											: calMode === enums.CALENDAR_MODE_MONTH
											? "year"
											: "year";
									setCurrentDate(currentDate.clone().add(1, gotoMode));
								}}
								className="h-6 w-6 md:h-8 md:w-8 m-1 bg-blue-200/50"
							>
								<ChevronDoubleRightIcon className="h-4 w-4 md:h-6 md:w-6 text-blue-700/50" />
							</RoundButton>
						</div>
					</div>

					<div className="flex flex-row justify-end w-full gap-2 mb-2 md:mb-0">
						<div
							className=""
							onClick={() => {
								setCalMode(enums.CALENDAR_MODE_DAY);
								navigate(`#${enums.CALENDAR_MODE_DAY}`);
							}}
						>
							<DayIcon selected={calMode === enums.CALENDAR_MODE_DAY} />
						</div>
						<div
							className=""
							onClick={() => {
								setCalMode(enums.CALENDAR_MODE_WEEK);
								navigate(`#${enums.CALENDAR_MODE_WEEK}`);
							}}
						>
							<WeekIcon selected={calMode === enums.CALENDAR_MODE_WEEK} />
						</div>
						<div
							className=""
							onClick={() => {
								setCalMode(enums.CALENDAR_MODE_MONTH);
								navigate(`#${enums.CALENDAR_MODE_MONTH}`);
							}}
						>
							<MonthIcon selected={calMode === enums.CALENDAR_MODE_MONTH} />
						</div>
						<div className="grid grid-cols-1 h-full border-l-[1px]  border-blue-800/50" />
						<div
							className=""
							onClick={() => {
								setCalMode(enums.CALENDAR_MODE_MONTH);
								navigate(`#${enums.CALENDAR_MODE_MONTH}`);
							}}
						>
							<SettingButton
								title="Paramètres"
								element={<>test</>}
							>
								<Cog8ToothIcon className="w-6 h-6" />
							</SettingButton>
						</div>
					</div>
				</div>

				<div
					className={`grid w-full grid-cols-${nbDaysAvailable} bg-blue-300/50 rounded-t-md`}
				>
					{dateArray.map((dz, idx) => {
						const dyZo = moment(dz, "YYYYMMDD");
						const isToday =
							todayDate.format("YYYYMMDD") === dyZo.format("YYYYMMDD");
						if (weekCalendarDays[idx].active) {
							return (
								<div
									key={idx}
									className={
										"flex flex-row w-full" +
										(isToday ? " today " : "") +
										(idx >= 5 ? " weekend " : "")
									}
								>
									<div className="line1 capitalize fs-10  text-center p-1 md:p-2 text-xs">
										{dyZo.format("ddd")}
									</div>
									<div
										className={`${
											calMode === enums.CALENDAR_MODE_WEEK ? "" : "hidden"
										} line2 fs-7  text-center py-1 md:p-2 text-xs`}
									>
										{dyZo.format("DD")}
									</div>
								</div>
							);
						}
						return "";
					})}
				</div>
				<div className="grid grid-cols-1 h-full w-full bg-blue-50/50 border-[2px] border-blue-300/50 overflow-hidden">
					{calMode === enums.CALENDAR_MODE_DAY ? (
						<CalendarWeek
							currentDate={currentDate}
							nbDaysAvailable={nbDaysAvailable}
							dateArray={dateArray}
							weekCalendarDays={weekCalendarDays}
						/>
					) : calMode === enums.CALENDAR_MODE_WEEK ? (
						<CalendarWeek
							currentDate={currentDate}
							nbDaysAvailable={nbDaysAvailable}
							dateArray={dateArray}
							weekCalendarDays={weekCalendarDays}
						/>
					) : calMode === enums.CALENDAR_MODE_MONTH ? (
						<>
							<CalendarMonth currentDate={currentDate} />
						</>
					) : (
						<></>
					)}
				</div>
				<Fade
					className="absolute top-0 h-full z-[2] bg-white"
					in={isOpenDetail}
				>
					<div className="flex w-full h-full"> {detailNode}</div>
				</Fade>
			</div>
		</div>
	);
}

export default CalendarHome;
