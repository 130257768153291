import  { ReactNode } from "react";
import RoundButton from "../buttons/RoundButton";
import { useModalStore } from "_services/useModalStore";
import { XMarkIcon } from "@heroicons/react/24/solid";

function SettingContentLayout({
	title,
	children,
}: {
	title: string;
	children: ReactNode;
}) {
	const [closeModalDetail] = useModalStore((state) => [state.closeModalDetail]);
	return (
		<div className="flex flex-col w-full h-full shadow-md">
			<div className="flex w-full gap-2 items-center bg-blue-100">
				<div className="p-2">
					<RoundButton
						onClick={() => {
							closeModalDetail();
						}}
						className="h-8 w-8 bg-blue-300/50"
					>
						<XMarkIcon className="h-6 w-6 text-blue-700/50" />
					</RoundButton>
				</div>
				<div className="flex p-2">{title}</div>
			</div>
			<div className="flex w-full h-full overflow-hidden">{children}</div>
		</div>
	);
}

export default SettingContentLayout;
