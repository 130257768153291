import React, { useEffect, useState } from "react";
import SectionTitle from "../sections/SectionTitle";
import SubSectionTitle from "../sections/SubSectionTitle";
import moment, { Moment } from "moment";
import * as enums from "@/lib/enums";
import { CheckIcon, PlusIcon } from "@heroicons/react/24/solid";
import { useTodoListStore } from "_services/useTodoListStore";
import { useAuth } from "../auth/AuthContext";
import { TodoItem } from "@/lib/todo-types";
import { Card, Switch, Input } from "@material-tailwind/react";
import { Collapse, Fade } from "@mui/material";
import RoundButton from "../buttons/RoundButton";
moment.locale("fr", enums.momentFR);

function TodoList() {
	const { currentUser } = useAuth();
	const [todos, getTodos, toggleTodo, addTodo] = useTodoListStore((state) => [
		state.todos,
		state.getTodos,
		state.toggleTodo,
		state.addTodo,
	]);
	const [uncollapsedItem, setUncollapsedItem] = useState<string | undefined>();
	const [currentText, setCurrentText] = useState("");
	const days = [
		{ title: "Aujourd'hui", date: moment() },
		{ title: "Demain", date: moment().add(1, "day") },
		{
			title: moment().add(2, "day").format("dddd"),
			date: moment().add(2, "day"),
		},
	];
	const currTodos: TodoItem[] = todos ?? [];

	const handleSwitchCompleted = (todoId: string) => {
		toggleTodo(todoId);
	};
	useEffect(() => {
		getTodos(currentUser.uid);
	}, []);

	const handleSendMessage = async (currDate: Moment) => {
		if (currentText && currentText !== "") {
			await addTodo(currentUser.uid, currentText, currDate);
			setCurrentText("");
			setUncollapsedItem(undefined);
		}
	};
	return (
		<div className="grid grid-cols-1 grid-rows-[auto_1fr] w-full md:h-full md:overflow-hidden">
			<SubSectionTitle>Toto list</SubSectionTitle>
			<div className="relative flex w-full flex-col p-2 items-center gap-2 md:overflow-y-auto no-scollbar">
				{days.map((day, day_index) => {
					const sectionTodos = currTodos.filter((todo) => {
						return (
							moment(todo.date, "YYYYMMDDHHmm").format("YYYYMMDD") ===
							day.date.format("YYYYMMDD")
						);
					});
					const currDate = day.date.clone();
					const isAddOpen =
						uncollapsedItem && uncollapsedItem === currDate.format("YYYYMMDD")
							? true
							: false;
					return (
						<React.Fragment key={day_index}>
							<div className="flex flex-col w-full items-center text-xs">
								<div className="flex flex-row w-full justify-between font-bold bg-blue-300/50 px-2 py-2 rounded-md">
									<div className="flex flex-row w-full gap-1 capitalize justify-between ">
										<div className="">{day.title}</div>
										<div className="">{currDate.format("DD MMM")}</div>
									</div>
									<div
										className="pl-3"
										onClick={() => {
											const newDate = currDate.format("YYYYMMDD");
											setCurrentText("");
											setUncollapsedItem(
												uncollapsedItem === newDate ? undefined : newDate
											);
										}}
									>
										<PlusIcon className="h-4 w-4" />
									</div>
								</div>
								<Fade in={isAddOpen}>
									<div
										className={`${
											isAddOpen ? "grid" : "hidden"
										} grid-cols-[auto_1fr] justify-between items-center w-full mt-2  text-xs bg-white/50 p-2 rounded-md`}
									>
										<div className="flex  ">
											<Input
												color="blue"
												shrink
												label="Nouveau todo"
												className=""
												containerProps={{ className: "min-w-0" }}
												// className="peer block min-h-[auto] w-full rounded border-0 bg-transparent outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary motion-reduce:transition-none` placeholder:opacity-0 disabled:bg-gray-100 read-only:bg-gray-100 dark:disabled:bg-gray-700 dark:read-only:bg-gray-700 dark:placeholder:text-gray-200 dark:peer-focus:text-primary px-3 py-[0.32rem] leading-[2.15] text-gray-800 dark:text-gray-200  mb-6"
												value={currentText}
												onChange={(e) => {
													setCurrentText(e.currentTarget.value);
												}}
												crossOrigin={currentText}
												onKeyDown={(e) => {
													if (e.key === "Enter") {
														handleSendMessage(currDate);
													}
												}}
											/>
										</div>
										<div className="">
											<RoundButton
												onClick={async () => {
													handleSendMessage(currDate);
												}}
												className="h-8 w-8 ml-2 bg-blue-200/50"
											>
												<CheckIcon className="h-6 w-6 text-blue-700/50" />
											</RoundButton>
										</div>
									</div>
								</Fade>

								<div className=""></div>
								<div className=""></div>
							</div>
							{sectionTodos.map((sectionTodo) => {
								return (
									<React.Fragment key={sectionTodo.id}>
										<div className="flex flex-row w-full text-xs  justify-between bg-white/50 p-2 rounded-md">
											<div
												className={`${
													sectionTodo.completed ? "line-through" : ""
												}`}
											>
												{sectionTodo.text}
											</div>
											<div className="">
												<Switch
													color="blue"
													onChange={() => handleSwitchCompleted(sectionTodo.id)}
													checked={sectionTodo.completed}
													crossOrigin={sectionTodo.id}
												/>
											</div>
										</div>
									</React.Fragment>
								);
							})}
						</React.Fragment>
					);
				})}
			</div>
		</div>
	);
}

export default TodoList;
