import {
	BuildingOfficeIcon,
	ChartBarIcon,
	HomeIcon,
} from "@heroicons/react/20/solid";
import React, { useEffect, useState } from "react";
import { useAppSettingStore } from "../service/useAppSettingStore";
import { useClientStore } from "../service/useClientStore";
import { useNavigate, useParams } from "react-router-dom";
import TrelloIcon from "../svg/TrelloIcon";
import ChefIcon from "../svg/ChefIcon";
import WaiterIcon from "../svg/WaiterIcon";
import PickIcon from "../svg/PickIcon";
import RestoHomeIcon from "../svg/RestoHomeIcon";

function LeftNav() {
	const { categ, categValue } = useParams();
	const params = useParams();
	const navigate = useNavigate();
	const [isCollapsed] = useAppSettingStore((state) => [state.isCollapsed]);
	const [loading, setLoading] = useState(true);
	// const [getClients, clients] = useClientStore((state) => [
	// 	state.getClients,
	// 	state.clients,
	// ]);

	const menu = [
		{
			id: 1,
			icon: <TrelloIcon className="" />,
			label: "home",
			link: "/admin",
		},
		{
			id: 2,
			icon: <TrelloIcon className="" />,
			label: "Dashboard",
			link: "/admin/dashboard",
		},
		{
			id: 3,
			icon: <ChefIcon className="" />,
			label: "Gestion de la cuisine",
			link: "/admin/kitchen",
		},
		{
			id: 4,
			icon: <WaiterIcon className="" />,
			label: "Gestion des salles",
			link: "/admin/restaurant",
		},
		{
			id: 5,
			icon: <PickIcon className="" />,
			label: "Gestion des commandes",
			link: "/admin/commandes",
		},
		{
			id: 5,
			icon: <PickIcon className="" />,
			label: "Gestion des employés",
			link: "/admin/employees",
		},
		{
			id: 5,
			icon: <PickIcon className="" />,
			label: "Rapports",
			link: "/admin/rapports",
		},
	];
	useEffect(() => {
		if (menu.length > 0) {
			setLoading(false);
		} else {
			//
		}
	}, []);
	return (
		<nav
			className={`flex ${
				isCollapsed
					? "w-[56px] absolute hover:w-[205px] hover:shadow-[0_0_10px_0_rgba(0,0,0,0.3)] z-[1]"
					: "w-[250px]"
			} overflow-hidden transition-all duration-150 ease-in-out timing-function  h-full flex-col bg-[#6D93CC] text-white shadow-sm`}
		>
			<div className="flex flex-row h-14 gap-2 px-4 items-center border-b-[1px] border-b-[#254374]/20  bg-[#254374] text-white">
				<div className={`flex w-6 h-6 my-2 mr-8 items-center `}>
					<ChartBarIcon className={`flex w-6 h-6 my-2`} />
				</div>
				<div className="flex text-sm"> Statistics</div>
			</div>
			<div className="relative flex flex-col border-b-[1px] pl-2 py-[5px] gap-[5px] border-b-[#254374]/20">
				{menu.map((menu_item, index) => {
					return (
						<React.Fragment key={index}>
							<div
								className={`relative flex flex-row rounded-l-md items-center cursor-pointer hover:bg-[#E9EFF9] hover:text-[#254374] ${
									menu_item.link === `/admin/${categ}/${categValue}` ||
									menu_item.link === `/admin/${categ}`
										? "bg-[#E9EFF9] text-[#254374]"
										: ""
								}  
								`}
								onClick={() => {
									navigate(menu_item.link);
								}}
							>
								<div className="flex w-8 h-8 my-2 ml-1 mr-3 self-start ">
									{menu_item.icon}
								</div>
								<div className="flex text-xs whitespace-nowrap">
									{" "}
									{menu_item.label}
								</div>
							</div>
						</React.Fragment>
					);
				})}
			</div>
			{/* <div className="flex flex-col p-2 border-b-[1px] border-b-gray-300">
				{loading ? (
					<>loading ..</>
				) : (
					<>
						{menu.map((menu_item, index) => {
							return (
								<React.Fragment key={index}>
									<div
										className={`flex flex-row gap-2 px-2 items-center cursor-pointer ${
											clientId && clientId === menu_item.id.toString()
												? "bg-sky-700/30"
												: ""
										}`}
										onClick={() => {
											navigate(`/client/${menu_item.id}`);
										}}
									>
										<div className="flex w-6 h-6 my-2 mr-8 self-start text-gray-600">
											<BuildingOfficeIcon className="w-6 h-6" />
										</div>
										<div className="flex text-sm"> {menu_item.name}</div>
									</div>
								</React.Fragment>
							);
						})}
					</>
				)}
			</div> */}
		</nav>
	);
}

export default LeftNav;
