import React from "react";
import {
	Card,
	CardHeader,
	CardBody,
	Button,
	Typography,
} from "@material-tailwind/react";

function DrawerOpenings() {
	const openings = [
		{ vendor: "Manager", manuel: 0, auto: 6, drawerAccount: 7 },
	];
	return (
		<>
			<Card className="rounded-md p-0 text-xs">
				<CardBody className=" flex flex-col p-0">
					<table className="min-w-full leading-normal">
						<tbody>
							<tr className="font-bold">
								<td className="p-2 border-b border-gray-200">
									Vendeur
								</td>
								<td className="p-2 border-b  border-gray-200">
									Manuelle
								</td>
								<td className="p-2 border-b border-gray-200">
									Automatique
								</td>
								<td className="p-2 border-b  border-gray-200">
									Compte du tiroir-caisse
								</td>
							</tr>
							{openings.map((opening, line_index) => {
								return (
									<React.Fragment key={line_index}>
										<tr>
											<td className="p-2 border-b border-gray-200 ">
												{opening.vendor}
											</td>
											<td className="p-2 border-b border-gray-200 ">
												{opening.manuel}x
											</td>
											<td className="p-2 border-b border-gray-200 ">
												{opening.auto}x
											</td>
											<td className="p-2 border-b border-gray-200 ">
												{opening.drawerAccount}x
											</td>
										</tr>
									</React.Fragment>
								);
							})}
						</tbody>
					</table>
				</CardBody>
			</Card>
		</>
	);
}

export default DrawerOpenings;
