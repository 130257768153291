import { useAuth } from "@/components/auth/AuthContext";
import CalendarHome from "@/components/calendar/CalendarHome";
import { useAppSettingStore } from "_services/useAppSettingStore";
import { useEffect } from "react";

function AdminContent() {
	const { currentUser } = useAuth();
	const [getCalendarOption, getAppConfig] = useAppSettingStore((state) => [
		state.getCalendarOption,
		state.getAppConfig,
	]);
	useEffect(() => {
		getAppConfig(currentUser.uid);
		getCalendarOption(currentUser.uid);
	}, []);
	return (
		<div className="grid gridcols-1 w-full h-full overflow-x-hidden overflow-y-auto  bg-[#E9EFF9] text-[#254374]">
			<CalendarHome />
		</div>
	);
}

export default AdminContent;
