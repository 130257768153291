import { useModalStore } from "_services/useModalStore";
import { Moment } from "moment";
import React, { useState } from "react";
import RoundButton from "../buttons/RoundButton";
import { XMarkIcon } from "@heroicons/react/24/solid";
import {
	Card,
	Switch,
	Input,
	Textarea,
	Button,
	Checkbox,
} from "@material-tailwind/react";
import { useTodoListStore } from "_services/useTodoListStore";
import SubSectionTitle from "../sections/SubSectionTitle";
import * as types from "@/lib/todo-types";
import { generateUniqueID } from "@/lib/functions";
import { useAuth } from "../auth/AuthContext";

function TodoDetail({ date }: { date: Moment }) {
	const { currentUser } = useAuth();
	const [closeModalDetail] = useModalStore((state) => [state.closeModalDetail]);
	const [todos, getTodos, toggleTodo, addTodo] = useTodoListStore((state) => [
		state.todos,
		state.getTodos,
		state.toggleTodo,
		state.addTodo,
	]);
	const [currentText, setCurrentText] = useState("");
	const [dateDebut, setDateDebut] = useState(date.format("YYYY-MM-DDTHH:mm"));
	const [dateFin, setDateFin] = useState();
	const [todoItem, setTodoItem] = useState<types.TodoItem>({
		id: generateUniqueID({ length: 10 }),
		text: "",
		dateDebut: dateDebut,
		dateFin: "",
		completed: false,
		description: "",
		uid: currentUser.uid,
	});

	const handleChange = (e) => {
		const { name, value, checked, type } = e.target;
		setTodoItem({
			...todoItem,
			[name]: type === "checkbox" ? checked : value,
		});
	};

	// Gérer la soumission du formulaire
	const handleSubmit = (e) => {
		e.preventDefault();
		// Logique de création du TodoItem
		console.log(todoItem);
		// Reset du formulaire ou logique supplémentaire
	};

	return (
		<div className="grid grid-cols-1 md:grid-cols-2">
			<form
				onSubmit={handleSubmit}
				className="grid grid-cols-1 p-5 gap-3"
			>
				<SubSectionTitle>Ticket</SubSectionTitle>
				<Input
					type="text"
					color="light-blue"
					size="md"
					label="Texte de la tâche"
					placeholder="Texte de la tâche"
					name="text"
					value={todoItem.text}
					onChange={handleChange}
				/>
				<Input
					type="datetime-local"
					color="light-blue"
					size="md"
					label="Date de début (DD/MM/YYYY)"
					placeholder="Date de début (DD/MM/YYYY)"
					name="dateDebut"
					value={todoItem.dateDebut}
					onChange={handleChange}
				/>
				<Input
					type="datetime-local"
					color="light-blue"
					size="md"
					label="Date de fin (DD/MM/YYYY)"
					placeholder="Date de fin (DD/MM/YYYY)"
					name="dateFin"
					value={todoItem.dateFin}
					onChange={handleChange}
				/>
				<Checkbox
					color="light-blue"
					label="Tâche complétée"
					id="completed-checkbox"
					name="completed"
					checked={todoItem.completed}
					onChange={handleChange}
				/>
				<Textarea
					color="light-blue"
					size="md"
					label="Description"
					placeholder="Description"
					name="description"
					value={todoItem.description}
					onChange={handleChange}
				/>

				<Button
					color="light-blue"
					buttonType="filled"
					size="md"
					rounded={false}
					block={false}
					iconOnly={false}
					type="submit"
				>
					Créer la tâche
				</Button>
			</form>
			<div className="flex flex-col w-full">
				<SubSectionTitle>Ticket</SubSectionTitle>
				<div className="flex flex-row gap-3 px-3">
					<div className="flex w-10 h-10">
						<svg
							className=""
							id="check"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 136.449 135.876"
						>
							<rect
								id="Rectangle_1036"
								data-name="Rectangle 1036"
								width="121.276"
								height="4.257"
								transform="translate(7.587 38.206)"
								fill="#666"
							/>
							<rect
								id="Rectangle_1037"
								data-name="Rectangle 1037"
								width="31.984"
								height="4.257"
								transform="translate(74.801 72.182)"
								fill="#999"
							/>
							<g
								id="Groupe_1488"
								data-name="Groupe 1488"
								transform="translate(56.899 69.098)"
							>
								<rect
									id="Rectangle_1038"
									data-name="Rectangle 1038"
									width="10.425"
									height="4.257"
									fill="#999"
								/>
								<rect
									id="Rectangle_1039"
									data-name="Rectangle 1039"
									width="10.425"
									height="4.257"
									transform="translate(0 6.168)"
									fill="#999"
								/>
								<rect
									id="Rectangle_1040"
									data-name="Rectangle 1040"
									width="4.257"
									height="10.425"
									fill="#999"
								/>
								<rect
									id="Rectangle_1041"
									data-name="Rectangle 1041"
									width="4.257"
									height="10.425"
									transform="translate(6.168)"
									fill="#999"
								/>
							</g>
							<g
								id="Groupe_1489"
								data-name="Groupe 1489"
								transform="translate(56.899 86.045)"
							>
								<rect
									id="Rectangle_1042"
									data-name="Rectangle 1042"
									width="10.425"
									height="4.257"
									fill="#999"
								/>
								<rect
									id="Rectangle_1043"
									data-name="Rectangle 1043"
									width="10.425"
									height="4.257"
									transform="translate(0 6.168)"
									fill="#999"
								/>
								<rect
									id="Rectangle_1044"
									data-name="Rectangle 1044"
									width="4.257"
									height="10.425"
									fill="#999"
								/>
								<rect
									id="Rectangle_1045"
									data-name="Rectangle 1045"
									width="4.257"
									height="10.425"
									transform="translate(6.168)"
									fill="#999"
								/>
							</g>
							<g
								id="Groupe_1490"
								data-name="Groupe 1490"
								transform="translate(56.899 103.046)"
							>
								<rect
									id="Rectangle_1046"
									data-name="Rectangle 1046"
									width="10.425"
									height="4.257"
									fill="#999"
								/>
								<rect
									id="Rectangle_1047"
									data-name="Rectangle 1047"
									width="10.425"
									height="4.257"
									transform="translate(0 6.168)"
									fill="#999"
								/>
								<rect
									id="Rectangle_1048"
									data-name="Rectangle 1048"
									width="4.257"
									height="10.425"
									fill="#999"
								/>
								<rect
									id="Rectangle_1049"
									data-name="Rectangle 1049"
									width="4.257"
									height="10.425"
									transform="translate(6.168)"
									fill="#999"
								/>
							</g>
							<rect
								id="Rectangle_1050"
								data-name="Rectangle 1050"
								width="31.984"
								height="4.257"
								transform="translate(74.801 89.183)"
								fill="#999"
							/>
							<rect
								id="Rectangle_1051"
								data-name="Rectangle 1051"
								width="31.984"
								height="4.257"
								transform="translate(74.801 106.158)"
								fill="#999"
							/>
							<rect
								id="Rectangle_1052"
								data-name="Rectangle 1052"
								width="4.448"
								height="4.257"
								transform="translate(109.814 72.182)"
								fill="#999"
							/>
							<rect
								id="Rectangle_1053"
								data-name="Rectangle 1053"
								width="4.448"
								height="4.257"
								transform="translate(109.814 106.158)"
								fill="#999"
							/>
							<rect
								id="Rectangle_1054"
								data-name="Rectangle 1054"
								width="4.448"
								height="4.257"
								transform="translate(117.374 72.182)"
								fill="#999"
							/>
							<rect
								id="Rectangle_1055"
								data-name="Rectangle 1055"
								width="4.448"
								height="4.257"
								transform="translate(117.374 89.183)"
								fill="#999"
							/>
							<rect
								id="Rectangle_1056"
								data-name="Rectangle 1056"
								width="4.448"
								height="4.257"
								transform="translate(117.374 106.158)"
								fill="#999"
							/>
							<g
								id="Groupe_1491"
								data-name="Groupe 1491"
								transform="translate(0 12.744)"
							>
								<path
									id="Tracé_1106"
									data-name="Tracé 1106"
									d="M128.863,4.67H109.923v8.487h18.939V119.315H7.587V13.157H26.526V4.67H7.587C3.384,4.67,0,8.463,0,13.157V119.315c0,4.694,3.384,8.487,7.587,8.487H128.863c4.175,0,7.587-3.793,7.587-8.487V13.157C136.449,8.463,133.065,4.67,128.863,4.67Z"
									transform="translate(0 -4.67)"
									fill="#999"
								/>
								<rect
									id="Rectangle_1057"
									data-name="Rectangle 1057"
									width="75.811"
									height="8.487"
									transform="translate(30.319)"
									fill="#999"
								/>
							</g>
							<path
								id="Tracé_1107"
								data-name="Tracé 1107"
								d="M15.917,4.257V21.231H12.123V4.257h3.793m0-4.257H12.123A4.062,4.062,0,0,0,8.33,4.257V21.231a4.044,4.044,0,0,0,3.793,4.257h3.793a4.044,4.044,0,0,0,3.793-4.257V4.257A4.044,4.044,0,0,0,15.917,0Z"
								transform="translate(14.402)"
								fill="#666"
							/>
							<path
								id="Tracé_1108"
								data-name="Tracé 1108"
								d="M45.087,4.257V21.231H41.293V4.257h3.793m0-4.257H41.293A4.044,4.044,0,0,0,37.5,4.257V21.231a4.044,4.044,0,0,0,3.793,4.257h3.793a4.044,4.044,0,0,0,3.793-4.257V4.257A4.062,4.062,0,0,0,45.087,0Z"
								transform="translate(64.837)"
								fill="#666"
							/>
							<path
								id="Tracé_1109"
								data-name="Tracé 1109"
								d="M19.517,49.177a1.777,1.777,0,0,1-1.337-.628L6.8,35.805a2.315,2.315,0,0,1,0-3,1.763,1.763,0,0,1,2.674,0L19.517,44.047l21.4-23.988a1.763,1.763,0,0,1,2.674,0,2.315,2.315,0,0,1,0,3L20.854,48.55A1.688,1.688,0,0,1,19.517,49.177Z"
								transform="translate(10.802 33.62)"
								fill="#99b815"
							/>
						</svg>
					</div>
					<div className="grid grid-cols-2 gap-2 w-full">
						<div className="col-span-2">
							<Input
								color="blue"
								shrink
								label="text"
								className=""
								containerProps={{ className: "min-w-0" }}
								// className="peer block min-h-[auto] w-full rounded border-0 bg-transparent outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary motion-reduce:transition-none` placeholder:opacity-0 disabled:bg-gray-100 read-only:bg-gray-100 dark:disabled:bg-gray-700 dark:read-only:bg-gray-700 dark:placeholder:text-gray-200 dark:peer-focus:text-primary px-3 py-[0.32rem] leading-[2.15] text-gray-800 dark:text-gray-200  mb-6"
								value={currentText}
								onChange={(e) => {
									setCurrentText(e.currentTarget.value);
								}}
								crossOrigin={currentText}
							/>
						</div>
						<div className="">
							<Input
								color="blue"
								type="datetime-local"
								shrink
								label="date début"
								className=""
								containerProps={{ className: "min-w-0" }}
								// className="peer block min-h-[auto] w-full rounded border-0 bg-transparent outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary motion-reduce:transition-none` placeholder:opacity-0 disabled:bg-gray-100 read-only:bg-gray-100 dark:disabled:bg-gray-700 dark:read-only:bg-gray-700 dark:placeholder:text-gray-200 dark:peer-focus:text-primary px-3 py-[0.32rem] leading-[2.15] text-gray-800 dark:text-gray-200  mb-6"
								value={dateDebut}
								onChange={(e) => {
									setDateDebut(e.currentTarget.value);
								}}
								crossOrigin={currentText}
							/>
						</div>
						<div className="">
							<Input
								color="blue"
								type="datetime-local"
								shrink
								label="date fin"
								className=""
								containerProps={{ className: "min-w-0" }}
								// className="peer block min-h-[auto] w-full rounded border-0 bg-transparent outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary motion-reduce:transition-none` placeholder:opacity-0 disabled:bg-gray-100 read-only:bg-gray-100 dark:disabled:bg-gray-700 dark:read-only:bg-gray-700 dark:placeholder:text-gray-200 dark:peer-focus:text-primary px-3 py-[0.32rem] leading-[2.15] text-gray-800 dark:text-gray-200  mb-6"
								value={dateFin}
								onChange={(e) => {
									setDateDebut(e.currentTarget.value);
								}}
								crossOrigin={currentText}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="flex flex-col w-full">
				<SubSectionTitle>Périodicité</SubSectionTitle>
				<div className="flex flex-row gap-3  px-3">
					<div className="flex w-10 h-10">
						<svg
							className=""
							id="check"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 136.449 135.876"
						>
							<rect
								id="Rectangle_1037"
								data-name="Rectangle 1037"
								width="15.988"
								height="4.257"
								transform="translate(30.966 44.182)"
								fill="#999"
							/>
							<rect
								id="Rectangle_2847"
								data-name="Rectangle 2847"
								width="15.988"
								height="4.257"
								transform="translate(87.966 44.182)"
								fill="#999"
							/>
							<rect
								id="Rectangle_2840"
								data-name="Rectangle 2840"
								width="15.988"
								height="4.257"
								transform="translate(30.966 96.182)"
								fill="#999"
							/>
							<rect
								id="Rectangle_2850"
								data-name="Rectangle 2850"
								width="15.988"
								height="4.257"
								transform="translate(87.966 96.182)"
								fill="#999"
							/>
							<g
								id="Groupe_1488"
								data-name="Groupe 1488"
								transform="translate(16.732 41.098)"
							>
								<rect
									id="Rectangle_1038"
									data-name="Rectangle 1038"
									width="10.425"
									height="4.257"
									fill="#999"
								/>
								<rect
									id="Rectangle_1039"
									data-name="Rectangle 1039"
									width="10.425"
									height="4.257"
									transform="translate(0 6.168)"
									fill="#999"
								/>
								<rect
									id="Rectangle_1040"
									data-name="Rectangle 1040"
									width="4.257"
									height="10.425"
									fill="#999"
								/>
								<rect
									id="Rectangle_1041"
									data-name="Rectangle 1041"
									width="4.257"
									height="10.425"
									transform="translate(6.168)"
									fill="#999"
								/>
							</g>
							<g
								id="Groupe_5126"
								data-name="Groupe 5126"
								transform="translate(73.732 41.098)"
							>
								<rect
									id="Rectangle_1038-2"
									data-name="Rectangle 1038"
									width="10.425"
									height="4.257"
									fill="#999"
								/>
								<rect
									id="Rectangle_1039-2"
									data-name="Rectangle 1039"
									width="10.425"
									height="4.257"
									transform="translate(0 6.168)"
									fill="#999"
								/>
								<rect
									id="Rectangle_1040-2"
									data-name="Rectangle 1040"
									width="4.257"
									height="10.425"
									fill="#999"
								/>
								<rect
									id="Rectangle_1041-2"
									data-name="Rectangle 1041"
									width="4.257"
									height="10.425"
									transform="translate(6.168)"
									fill="#999"
								/>
							</g>
							<g
								id="Groupe_5125"
								data-name="Groupe 5125"
								transform="translate(16.732 93.098)"
							>
								<rect
									id="Rectangle_1038-3"
									data-name="Rectangle 1038"
									width="10.425"
									height="4.257"
									fill="#999"
								/>
								<rect
									id="Rectangle_1039-3"
									data-name="Rectangle 1039"
									width="10.425"
									height="4.257"
									transform="translate(0 6.168)"
									fill="#999"
								/>
								<rect
									id="Rectangle_1040-3"
									data-name="Rectangle 1040"
									width="4.257"
									height="10.425"
									fill="#999"
								/>
								<rect
									id="Rectangle_1041-3"
									data-name="Rectangle 1041"
									width="4.257"
									height="10.425"
									transform="translate(6.168)"
									fill="#999"
								/>
							</g>
							<g
								id="Groupe_5129"
								data-name="Groupe 5129"
								transform="translate(73.732 93.098)"
							>
								<rect
									id="Rectangle_1038-4"
									data-name="Rectangle 1038"
									width="10.425"
									height="4.257"
									fill="#999"
								/>
								<rect
									id="Rectangle_1039-4"
									data-name="Rectangle 1039"
									width="10.425"
									height="4.257"
									transform="translate(0 6.168)"
									fill="#999"
								/>
								<rect
									id="Rectangle_1040-4"
									data-name="Rectangle 1040"
									width="4.257"
									height="10.425"
									fill="#999"
								/>
								<rect
									id="Rectangle_1041-4"
									data-name="Rectangle 1041"
									width="4.257"
									height="10.425"
									transform="translate(6.168)"
									fill="#999"
								/>
							</g>
							<g
								id="Groupe_1489"
								data-name="Groupe 1489"
								transform="translate(16.732 58.045)"
							>
								<rect
									id="Rectangle_1042"
									data-name="Rectangle 1042"
									width="10.425"
									height="4.257"
									fill="#999"
								/>
								<rect
									id="Rectangle_1043"
									data-name="Rectangle 1043"
									width="10.425"
									height="4.257"
									transform="translate(0 6.168)"
									fill="#999"
								/>
								<rect
									id="Rectangle_1044"
									data-name="Rectangle 1044"
									width="4.257"
									height="10.425"
									fill="#999"
								/>
								<rect
									id="Rectangle_1045"
									data-name="Rectangle 1045"
									width="4.257"
									height="10.425"
									transform="translate(6.168)"
									fill="#999"
								/>
							</g>
							<g
								id="Groupe_5127"
								data-name="Groupe 5127"
								transform="translate(73.732 58.045)"
							>
								<rect
									id="Rectangle_1042-2"
									data-name="Rectangle 1042"
									width="10.425"
									height="4.257"
									fill="#999"
								/>
								<rect
									id="Rectangle_1043-2"
									data-name="Rectangle 1043"
									width="10.425"
									height="4.257"
									transform="translate(0 6.168)"
									fill="#999"
								/>
								<rect
									id="Rectangle_1044-2"
									data-name="Rectangle 1044"
									width="4.257"
									height="10.425"
									fill="#999"
								/>
								<rect
									id="Rectangle_1045-2"
									data-name="Rectangle 1045"
									width="4.257"
									height="10.425"
									transform="translate(6.168)"
									fill="#999"
								/>
							</g>
							<g
								id="Groupe_5123"
								data-name="Groupe 5123"
								transform="translate(16.732 110.045)"
							>
								<rect
									id="Rectangle_1042-3"
									data-name="Rectangle 1042"
									width="10.425"
									height="4.257"
									fill="#999"
								/>
								<rect
									id="Rectangle_1043-3"
									data-name="Rectangle 1043"
									width="10.425"
									height="4.257"
									transform="translate(0 6.168)"
									fill="#999"
								/>
								<rect
									id="Rectangle_1044-3"
									data-name="Rectangle 1044"
									width="4.257"
									height="10.425"
									fill="#999"
								/>
								<rect
									id="Rectangle_1045-3"
									data-name="Rectangle 1045"
									width="4.257"
									height="10.425"
									transform="translate(6.168)"
									fill="#999"
								/>
							</g>
							<g
								id="Groupe_5130"
								data-name="Groupe 5130"
								transform="translate(73.732 110.045)"
							>
								<rect
									id="Rectangle_1042-4"
									data-name="Rectangle 1042"
									width="10.425"
									height="4.257"
									fill="#999"
								/>
								<rect
									id="Rectangle_1043-4"
									data-name="Rectangle 1043"
									width="10.425"
									height="4.257"
									transform="translate(0 6.168)"
									fill="#999"
								/>
								<rect
									id="Rectangle_1044-4"
									data-name="Rectangle 1044"
									width="4.257"
									height="10.425"
									fill="#999"
								/>
								<rect
									id="Rectangle_1045-4"
									data-name="Rectangle 1045"
									width="4.257"
									height="10.425"
									transform="translate(6.168)"
									fill="#999"
								/>
							</g>
							<g
								id="Groupe_1490"
								data-name="Groupe 1490"
								transform="translate(16.732 75.046)"
							>
								<rect
									id="Rectangle_1046"
									data-name="Rectangle 1046"
									width="10.425"
									height="4.257"
									fill="#999"
								/>
								<rect
									id="Rectangle_1047"
									data-name="Rectangle 1047"
									width="10.425"
									height="4.257"
									transform="translate(0 6.168)"
									fill="#999"
								/>
								<rect
									id="Rectangle_1048"
									data-name="Rectangle 1048"
									width="4.257"
									height="10.425"
									fill="#999"
								/>
								<rect
									id="Rectangle_1049"
									data-name="Rectangle 1049"
									width="4.257"
									height="10.425"
									transform="translate(6.168)"
									fill="#999"
								/>
							</g>
							<g
								id="Groupe_5128"
								data-name="Groupe 5128"
								transform="translate(73.732 75.046)"
							>
								<rect
									id="Rectangle_1046-2"
									data-name="Rectangle 1046"
									width="10.425"
									height="4.257"
									fill="#999"
								/>
								<rect
									id="Rectangle_1047-2"
									data-name="Rectangle 1047"
									width="10.425"
									height="4.257"
									transform="translate(0 6.168)"
									fill="#999"
								/>
								<rect
									id="Rectangle_1048-2"
									data-name="Rectangle 1048"
									width="4.257"
									height="10.425"
									fill="#999"
								/>
								<rect
									id="Rectangle_1049-2"
									data-name="Rectangle 1049"
									width="4.257"
									height="10.425"
									transform="translate(6.168)"
									fill="#999"
								/>
							</g>
							<rect
								id="Rectangle_1050"
								data-name="Rectangle 1050"
								width="15.988"
								height="4.257"
								transform="translate(30.966 61.183)"
								fill="#999"
							/>
							<rect
								id="Rectangle_2848"
								data-name="Rectangle 2848"
								width="15.988"
								height="4.257"
								transform="translate(87.966 61.183)"
								fill="#999"
							/>
							<rect
								id="Rectangle_2841"
								data-name="Rectangle 2841"
								width="15.988"
								height="4.257"
								transform="translate(30.966 113.183)"
								fill="#999"
							/>
							<rect
								id="Rectangle_2854"
								data-name="Rectangle 2854"
								width="15.988"
								height="4.257"
								transform="translate(87.966 113.183)"
								fill="#999"
							/>
							<rect
								id="Rectangle_1051"
								data-name="Rectangle 1051"
								width="15.988"
								height="4.257"
								transform="translate(30.966 78.158)"
								fill="#999"
							/>
							<rect
								id="Rectangle_2849"
								data-name="Rectangle 2849"
								width="15.988"
								height="4.257"
								transform="translate(87.966 78.158)"
								fill="#999"
							/>
							<rect
								id="Rectangle_1052"
								data-name="Rectangle 1052"
								width="4.517"
								height="4.257"
								transform="translate(50.031 44.182)"
								fill="#999"
							/>
							<rect
								id="Rectangle_2851"
								data-name="Rectangle 2851"
								width="4.517"
								height="4.257"
								transform="translate(107.031 44.182)"
								fill="#999"
							/>
							<rect
								id="Rectangle_2843"
								data-name="Rectangle 2843"
								width="4.517"
								height="4.257"
								transform="translate(50.031 96.182)"
								fill="#999"
							/>
							<rect
								id="Rectangle_2852"
								data-name="Rectangle 2852"
								width="4.517"
								height="4.257"
								transform="translate(107.031 96.182)"
								fill="#999"
							/>
							<rect
								id="Rectangle_1053"
								data-name="Rectangle 1053"
								width="4.517"
								height="4.257"
								transform="translate(50.031 78.158)"
								fill="#999"
							/>
							<rect
								id="Rectangle_2853"
								data-name="Rectangle 2853"
								width="4.517"
								height="4.257"
								transform="translate(107.031 78.158)"
								fill="#999"
							/>
							<rect
								id="Rectangle_1054"
								data-name="Rectangle 1054"
								width="4.517"
								height="4.257"
								transform="translate(57.707 44.182)"
								fill="#999"
							/>
							<rect
								id="Rectangle_2855"
								data-name="Rectangle 2855"
								width="4.517"
								height="4.257"
								transform="translate(114.707 44.182)"
								fill="#999"
							/>
							<rect
								id="Rectangle_2845"
								data-name="Rectangle 2845"
								width="4.517"
								height="4.257"
								transform="translate(57.707 96.182)"
								fill="#999"
							/>
							<rect
								id="Rectangle_2856"
								data-name="Rectangle 2856"
								width="4.517"
								height="4.257"
								transform="translate(114.707 96.182)"
								fill="#999"
							/>
							<rect
								id="Rectangle_1055"
								data-name="Rectangle 1055"
								width="4.517"
								height="4.257"
								transform="translate(57.707 61.183)"
								fill="#999"
							/>
							<rect
								id="Rectangle_2857"
								data-name="Rectangle 2857"
								width="4.517"
								height="4.257"
								transform="translate(114.707 61.183)"
								fill="#999"
							/>
							<rect
								id="Rectangle_2846"
								data-name="Rectangle 2846"
								width="4.517"
								height="4.257"
								transform="translate(57.707 113.183)"
								fill="#999"
							/>
							<rect
								id="Rectangle_2858"
								data-name="Rectangle 2858"
								width="4.517"
								height="4.257"
								transform="translate(114.707 113.183)"
								fill="#999"
							/>
							<rect
								id="Rectangle_1056"
								data-name="Rectangle 1056"
								width="4.517"
								height="4.257"
								transform="translate(57.707 78.158)"
								fill="#999"
							/>
							<rect
								id="Rectangle_2859"
								data-name="Rectangle 2859"
								width="4.517"
								height="4.257"
								transform="translate(114.707 78.158)"
								fill="#999"
							/>
							<g
								id="Groupe_1491"
								data-name="Groupe 1491"
								transform="translate(0 12.744)"
							>
								<path
									id="Tracé_1106"
									data-name="Tracé 1106"
									d="M128.863,4.67H109.923v8.487h18.939V119.315H7.587V13.157H26.526V4.67H7.587C3.384,4.67,0,8.463,0,13.157V119.315c0,4.694,3.384,8.487,7.587,8.487H128.863c4.175,0,7.587-3.793,7.587-8.487V13.157C136.449,8.463,133.065,4.67,128.863,4.67Z"
									transform="translate(0 -4.67)"
									fill="#999"
								/>
								<rect
									id="Rectangle_1057"
									data-name="Rectangle 1057"
									width="75.811"
									height="8.487"
									transform="translate(30.319)"
									fill="#999"
								/>
							</g>
							<path
								id="Tracé_1107"
								data-name="Tracé 1107"
								d="M15.917,4.257V21.231H12.123V4.257h3.793m0-4.257H12.123A4.062,4.062,0,0,0,8.33,4.257V21.231a4.044,4.044,0,0,0,3.793,4.257h3.793a4.044,4.044,0,0,0,3.793-4.257V4.257A4.044,4.044,0,0,0,15.917,0Z"
								transform="translate(14.402)"
								fill="#666"
							/>
							<path
								id="Tracé_1108"
								data-name="Tracé 1108"
								d="M45.087,4.257V21.231H41.293V4.257h3.793m0-4.257H41.293A4.044,4.044,0,0,0,37.5,4.257V21.231a4.044,4.044,0,0,0,3.793,4.257h3.793a4.044,4.044,0,0,0,3.793-4.257V4.257A4.062,4.062,0,0,0,45.087,0Z"
								transform="translate(64.837)"
								fill="#666"
							/>
							<path
								id="Tracé_11678"
								data-name="Tracé 11678"
								d="M34.335,18.459v9.876L47.5,15.167,34.335,2v9.876A26.288,26.288,0,0,0,12.082,52.234l4.806-4.806a19.516,19.516,0,0,1-2.3-9.217A19.746,19.746,0,0,1,34.335,18.459Z"
								transform="translate(71.403 26.651) rotate(47)"
							/>
						</svg>
					</div>
					<div className="grid grid-cols-2 gap-2 w-full">
						<div className="col-span-2">
							<Input
								color="blue"
								shrink
								label="text"
								className=""
								containerProps={{ className: "min-w-0" }}
								// className="peer block min-h-[auto] w-full rounded border-0 bg-transparent outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary motion-reduce:transition-none` placeholder:opacity-0 disabled:bg-gray-100 read-only:bg-gray-100 dark:disabled:bg-gray-700 dark:read-only:bg-gray-700 dark:placeholder:text-gray-200 dark:peer-focus:text-primary px-3 py-[0.32rem] leading-[2.15] text-gray-800 dark:text-gray-200  mb-6"
								value={currentText}
								onChange={(e) => {
									setCurrentText(e.currentTarget.value);
								}}
								crossOrigin={currentText}
							/>
						</div>
						<div className="">
							<Input
								color="blue"
								type="datetime-local"
								shrink
								label="date début"
								className=""
								containerProps={{ className: "min-w-0" }}
								// className="peer block min-h-[auto] w-full rounded border-0 bg-transparent outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary motion-reduce:transition-none` placeholder:opacity-0 disabled:bg-gray-100 read-only:bg-gray-100 dark:disabled:bg-gray-700 dark:read-only:bg-gray-700 dark:placeholder:text-gray-200 dark:peer-focus:text-primary px-3 py-[0.32rem] leading-[2.15] text-gray-800 dark:text-gray-200  mb-6"
								value={dateDebut}
								onChange={(e) => {
									setDateDebut(e.currentTarget.value);
								}}
								crossOrigin={currentText}
							/>
						</div>
						<div className="">
							<Input
								color="blue"
								type="datetime-local"
								shrink
								label="date fin"
								className=""
								containerProps={{ className: "min-w-0" }}
								// className="peer block min-h-[auto] w-full rounded border-0 bg-transparent outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary motion-reduce:transition-none` placeholder:opacity-0 disabled:bg-gray-100 read-only:bg-gray-100 dark:disabled:bg-gray-700 dark:read-only:bg-gray-700 dark:placeholder:text-gray-200 dark:peer-focus:text-primary px-3 py-[0.32rem] leading-[2.15] text-gray-800 dark:text-gray-200  mb-6"
								value={dateFin}
								onChange={(e) => {
									setDateDebut(e.currentTarget.value);
								}}
								crossOrigin={currentText}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="flex flex-col w-full">
				<SubSectionTitle>Tickets à cette date</SubSectionTitle>
			</div>
		</div>
	);
}

export default TodoDetail;
