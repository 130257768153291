/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useState, useEffect } from "react";

import {
	GoogleAuthProvider,
	signInWithPopup,
	GithubAuthProvider,
	createUserWithEmailAndPassword,
	signInWithEmailAndPassword,
	sendPasswordResetEmail,
	updateProfile,
} from "firebase/auth";
import { auth } from "@/firebase/BaseConfig";

interface IAuthProviderProps {
	children: JSX.Element;
}

const AuthContext = React.createContext({});

export function useAuth(): any {
	return useContext(AuthContext);
}

export function AuthProvider({ children }: IAuthProviderProps): JSX.Element {
	const [currentUser, setCurrentUser] = useState<any>();
	const [loading, setLoading] = useState(true);

	function signup(email: string, password: string): Promise<any> {
		return createUserWithEmailAndPassword(auth, email, password)
			.then((userCredential) => {
				// Signed up
				const user = userCredential.user;
				return userCredential;
				// ...
			})
			.catch((error) => {
				const errorCode = error.code;
				const errorMessage = error.message;
				throw new Error(error);
				// ..
			});
	}

	function googleSignin(): Promise<any> {
		const provider = new GoogleAuthProvider();
		return signInWithPopup(auth, provider);
	}

	function githubSignin(): Promise<any> {
		const provider = new GithubAuthProvider();
		return signInWithPopup(auth, provider);
	}

	function login(email: string, password: string): Promise<any> {
		debugger;
		return signInWithEmailAndPassword(auth, email, password)
			.then((userCredential) => {
				// Signed in
				const user = userCredential.user;
				return userCredential;
				// ...
			})
			.catch((error) => {
				const errorCode = error.code;
				const errorMessage = error.message;
				if (error.code !== "auth/user-not-found")
					throw new Error("Email ou mot de passe incorrect");
				throw new Error(error);
			});
	}

	function logout(): Promise<any> {
		return auth.signOut();
	}

	function resetPassword(email: string): Promise<any> {
		return sendPasswordResetEmail(auth, email);
	}

	function updateEmail(email: string): Promise<any> {
		return currentUser.updateEmail(email);
	}

	function updatePassword(password: string): Promise<any> {
		return currentUser.updatePassword(password);
	}

	function updateDisplayName(displayName: string): Promise<any> {
		return updateProfile(currentUser, { displayName });
	}

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged((user) => {
			setCurrentUser(user);
			setLoading(false);
		});

		return unsubscribe;
	}, []);

	const value = {
		currentUser,
		login,
		signup,
		googleSignin,
		githubSignin,
		logout,
		resetPassword,
		updateEmail,
		updatePassword,
		updateDisplayName,
	};

	return (
		<AuthContext.Provider value={value}>
			{!loading && children}
		</AuthContext.Provider>
	);
}
