import React from "react";

function ChefIcon({ className }: { className?: string }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			className={className}
			viewBox="0 0 25.599 25.599"
		>
			<g
				id="Groupe_5730"
				data-name="Groupe 5730"
				transform="translate(9043.216 382.261)"
			>
				<path
					id="Tracé_11362"
					data-name="Tracé 11362"
					d="M0,0H25.6V25.6H0Z"
					transform="translate(-9043.216 -382.261)"
					fill="none"
				/>
				<g
					id="salle"
					transform="translate(-9788.533 5427.256)"
				>
					<path
						id="Tracé_11366"
						data-name="Tracé 11366"
						d="M751.708-5785.831v-.269l-2.759-1.727-.413-.612v-.8l2.06-5.1a3.3,3.3,0,0,1,.794-.992,7.769,7.769,0,0,1,1.4-.875l.766-.474v-1.37a3.2,3.2,0,0,0,1.558.6,1.774,1.774,0,0,0,1.141-.478v1.25l1.588.813.913.536,1.171,2.878,1.389-1.886,2.4.1-3,5.3-.556.139-.536-.139-1.4-1.427v4.535Z"
						fill="#fff"
					/>
					<path
						id="Tracé_11369"
						data-name="Tracé 11369"
						d="M759.1-5797.206a1.209,1.209,0,0,0,.568,1.058,4.654,4.654,0,0,0,1.806.129h5.809a1.125,1.125,0,0,0,.925-.129,2.4,2.4,0,0,0,.568-1.058h-.568a7.443,7.443,0,0,0-.49-2.116c-.393-.852-.452-.755-1.187-1.29a7.007,7.007,0,0,0-1.755-.852h-1.213a4.2,4.2,0,0,0-1.858.49,4.443,4.443,0,0,0-1.7,1.5,2.631,2.631,0,0,0-.542,1.522v.748Z"
						fill="#d5d5d5"
					/>
					<path
						id="Tracé_11368"
						data-name="Tracé 11368"
						d="M753.926-5802l-.729,1.634-.675.536-.516-.675-.258-1.5Z"
						fill="rgba(37,67,116,0.69)"
					/>
					<path
						id="Tracé_11367"
						data-name="Tracé 11367"
						d="M751.748-5801.858s-.432.049-.576-.2a.783.783,0,0,1,0-.794,1.212,1.212,0,0,1,.576-.317v-1.27a7.171,7.171,0,0,1-.675-.675,1.261,1.261,0,0,1-.1-1.013,1.62,1.62,0,0,1,.913-.972,1.389,1.389,0,0,1,1.052,0l.4.437a3.1,3.1,0,0,1,.476-1.23,1.364,1.364,0,0,1,.913-.437,3.048,3.048,0,0,1,1.171.2,1.225,1.225,0,0,1,.576.675l.119.635a.97.97,0,0,1,.675-.417,1.418,1.418,0,0,1,1.211.238c.337.288.3.477.4.973s.208.516,0,.913a2,2,0,0,1-.754.675v1.35a.862.862,0,0,1,.357.437,1.655,1.655,0,0,1,0,.695h-.556Z"
						fill="#fff"
					/>
					<path
						id="Tracé_9943"
						data-name="Tracé 9943"
						d="M775.333-5709.649h-4.675a.2.2,0,0,1-.2-.2v-6.407l-.335-.536a.2.2,0,0,1,.064-.275.2.2,0,0,1,.276.063l.365.584a.2.2,0,0,1,.031.106v6.265h4.275v-7.36a.176.176,0,0,1,.018-.083,1,1,0,0,0-.2-.9,8.475,8.475,0,0,0-1.453-1.414.2.2,0,0,1-.078-.159v-.694a.2.2,0,0,1,.2-.2.2.2,0,0,1,.2.2v.6a8.2,8.2,0,0,1,1.464,1.447,1.63,1.63,0,0,1,.3.9,1.067,1.067,0,0,1-.051.345v7.524A.2.2,0,0,1,775.333-5709.649Z"
						transform="translate(-18.868 -75.954)"
						fill="#254374"
					/>
					<path
						id="Tracé_9944"
						data-name="Tracé 9944"
						d="M750.8-5719.188a.2.2,0,0,1-.112-.035l-1.68-1.132-.013-.01a2,2,0,0,1-.655-.967,1.947,1.947,0,0,1,.2-1.135l1.945-4.852,0-.007a3.058,3.058,0,0,1,.895-1.116,20.82,20.82,0,0,1,1.961-1.081v-1.264a.2.2,0,0,1,.2-.2.2.2,0,0,1,.2.2v1.388a.2.2,0,0,1-.112.18c-.015.007-1.5.74-2.018,1.1a2.66,2.66,0,0,0-.759.949l-1.947,4.855-.008.018a1.58,1.58,0,0,0-.165.875,1.575,1.575,0,0,0,.508.734l1.673,1.127a.2.2,0,0,1,.054.278A.2.2,0,0,1,750.8-5719.188Z"
						transform="translate(0 -67.182)"
						fill="#254374"
					/>
					<path
						id="Tracé_9945"
						data-name="Tracé 9945"
						d="M816.152-5685.94h-.986a.2.2,0,0,1-.2-.2.2.2,0,0,1,.2-.2h.786v-6.521a.2.2,0,0,1,.015-.077l.183-.438a.2.2,0,0,1,.262-.107.2.2,0,0,1,.108.262l-.167.4v6.681A.2.2,0,0,1,816.152-5685.94Z"
						transform="translate(-57.751 -99.663)"
						fill="#254374"
					/>
					<path
						id="Tracé_9946"
						data-name="Tracé 9946"
						d="M829.534-5698a1.405,1.405,0,0,1-.44-.062,1.481,1.481,0,0,1-.69-.557.2.2,0,0,1,.06-.276.2.2,0,0,1,.276.06,1.087,1.087,0,0,0,.479.393,1.487,1.487,0,0,0,.649.012L833-5703.8a.2.2,0,0,1,.274-.071.2.2,0,0,1,.072.273l-3.178,5.442a.2.2,0,0,1-.125.094A2.374,2.374,0,0,1,829.534-5698Z"
						transform="translate(-69.368 -90.653)"
						fill="#254374"
					/>
					<path
						id="Tracé_9947"
						data-name="Tracé 9947"
						d="M845.682-5716.757h2.377a.2.2,0,0,1,.153.071.2.2,0,0,1,.044.164,3.459,3.459,0,0,1-.636,1.491,1.45,1.45,0,0,1-.681.4,1.946,1.946,0,0,1-.507.069H843.9a.2.2,0,0,1-.159-.079.2.2,0,0,1-.034-.174,3.822,3.822,0,0,1,.746-1.44A1.863,1.863,0,0,1,845.682-5716.757Zm0,.4h0a1.465,1.465,0,0,0-.951.38,2.93,2.93,0,0,0-.561,1.009h2.263a1.281,1.281,0,0,0,.9-.34,2.617,2.617,0,0,0,.481-1.051h-2.126Z"
						transform="translate(-82.645 -79.511)"
						fill="#254374"
					/>
					<path
						id="Tracé_9948"
						data-name="Tracé 9948"
						d="M810.336-5723.609a.2.2,0,0,1-.187-.129l-.694-1.827a.217.217,0,0,1-.008-.026,2.736,2.736,0,0,0-.4-.907,1.676,1.676,0,0,0-.676-.482l-.017-.007-1.753-.8a.2.2,0,0,1-.117-.182v-1.169a.2.2,0,0,1,.2-.2.2.2,0,0,1,.2.2v1.041l1.629.747a2.1,2.1,0,0,1,.848.61,3.074,3.074,0,0,1,.473,1.051l.689,1.814a.2.2,0,0,1-.116.258A.2.2,0,0,1,810.336-5723.609Z"
						transform="translate(-50.401 -68.604)"
						fill="#254374"
					/>
					<path
						id="Tracé_9949"
						data-name="Tracé 9949"
						d="M831.308-5700.685a.2.2,0,0,1-.19-.137.2.2,0,0,1,.08-.231c.15-.148,1.035-1.375,1.626-2.212a.2.2,0,0,1,.279-.048.2.2,0,0,1,.048.279c0,.006-.4.564-.8,1.121-.237.328-.43.59-.575.781-.087.115-.158.205-.21.268-.085.1-.131.148-.194.169A.193.193,0,0,1,831.308-5700.685Z"
						transform="translate(-71.738 -91.128)"
						fill="#254374"
					/>
					<path
						id="Tracé_9950"
						data-name="Tracé 9950"
						d="M828.573-5724.692H838a.2.2,0,0,1,.15.067.2.2,0,0,1,.049.157,2.616,2.616,0,0,1-.066.327,1.514,1.514,0,0,1-.271.581,1.152,1.152,0,0,1-.766.364h-7.505a1.328,1.328,0,0,1-.832-.355,1.474,1.474,0,0,1-.306-.583,2.381,2.381,0,0,1-.075-.329.2.2,0,0,1,.047-.159A.2.2,0,0,1,828.573-5724.692Zm9.182.4h-8.934a1.2,1.2,0,0,0,.227.467.921.921,0,0,0,.553.229h7.475a.747.747,0,0,0,.474-.22A1.267,1.267,0,0,0,837.755-5724.292Z"
						transform="translate(-69.368 -72.634)"
						fill="#254374"
					/>
					<path
						id="Tracé_9951"
						data-name="Tracé 9951"
						d="M873.833-5752.232h-.006a.2.2,0,0,1-.195-.206,5.081,5.081,0,0,0-.082-.913,3.845,3.845,0,0,0-.679-1.661,4.606,4.606,0,0,0-1.542-1.171,7.131,7.131,0,0,0-.955-.405.2.2,0,0,1-.13-.252.2.2,0,0,1,.252-.13,7.492,7.492,0,0,1,1.008.427,6.569,6.569,0,0,1,.879.53,3.986,3.986,0,0,1,.8.754,3.563,3.563,0,0,1,.513.934,5.066,5.066,0,0,1,.246.914,5.372,5.372,0,0,1,.086.984A.2.2,0,0,1,873.833-5752.232Z"
						transform="translate(-105.642 -44.658)"
						fill="#254374"
					/>
					<path
						id="Tracé_9952"
						data-name="Tracé 9952"
						d="M831.309-5757.759a.211.211,0,0,1-.046,0,.2.2,0,0,1-.149-.241,5.8,5.8,0,0,1,.33-.941,5.391,5.391,0,0,1,.458-.833,3.617,3.617,0,0,1,.705-.784,3.77,3.77,0,0,1,.926-.536,5.974,5.974,0,0,1,.937-.3,7.33,7.33,0,0,1,.839-.145v-.437a.2.2,0,0,1,.2-.2.2.2,0,0,1,.2.2v.621a.2.2,0,0,1-.189.2,6.524,6.524,0,0,0-.968.153,4.368,4.368,0,0,0-1.694.752,4,4,0,0,0-1.046,1.46,5.521,5.521,0,0,0-.31.877A.2.2,0,0,1,831.309-5757.759Z"
						transform="translate(-71.738 -40.154)"
						fill="#254374"
					/>
					<path
						id="Tracé_9953"
						data-name="Tracé 9953"
						d="M842.526-5742.75a.2.2,0,0,1-.087-.02.2.2,0,0,1-.094-.267,4.632,4.632,0,0,1,.5-.808,2.4,2.4,0,0,1,.6-.522.2.2,0,0,1,.273.076.2.2,0,0,1-.076.272,2.018,2.018,0,0,0-.491.428,4.332,4.332,0,0,0-.449.727A.2.2,0,0,1,842.526-5742.75Z"
						transform="translate(-81.459 -55.565)"
						fill="#254374"
					/>
					<path
						id="Tracé_9954"
						data-name="Tracé 9954"
						d="M781.385-5755a1.956,1.956,0,0,1-1.191-.347,3.88,3.88,0,0,1-.757-.8l-.1-.121a2.366,2.366,0,0,1-.464-.909,1.258,1.258,0,0,1-.04-.408l.4.045v0a.967.967,0,0,0,.037.293,1.989,1.989,0,0,0,.383.732l.1.122c.47.6.781.995,1.634.995a1.457,1.457,0,0,0,1.336-.582c.085-.086.182-.184.294-.289a1.6,1.6,0,0,0,.461-.87,1.883,1.883,0,0,0,.036-.486.2.2,0,0,1,.082-.182,1.748,1.748,0,0,0,.528-.678,2.721,2.721,0,0,0,.12-1.007.2.2,0,0,1,.184-.216h.016a.2.2,0,0,1,.2.185,3.107,3.107,0,0,1-.147,1.185,2.109,2.109,0,0,1-.579.786,2.16,2.16,0,0,1-.626,1.575c-.107.1-.2.2-.284.28A1.832,1.832,0,0,1,781.385-5755Z"
						transform="translate(-26.443 -42.288)"
						fill="#254374"
					/>
					<path
						id="Tracé_9955"
						data-name="Tracé 9955"
						d="M773.8-5757.415a.543.543,0,0,1-.449-.187,1.229,1.229,0,0,1-.186-.4c-.027-.08-.058-.171-.1-.271a4.382,4.382,0,0,1-.234-1.236.2.2,0,0,1,.192-.208h.008a.2.2,0,0,1,.2.192h0a4.043,4.043,0,0,0,.2,1.1c.044.109.077.206.106.292.107.313.121.313.256.313.176,0,.225-.06.417-.393.042-.072.089-.153.143-.242a4.19,4.19,0,0,0,.413-1.105.2.2,0,0,1,.237-.154.2.2,0,0,1,.155.237,4.522,4.522,0,0,1-.464,1.233c-.052.084-.1.162-.137.232a1.815,1.815,0,0,1-.284.412A.648.648,0,0,1,773.8-5757.415Z"
						transform="translate(-21.239 -42.287)"
						fill="#254374"
					/>
					<path
						id="Tracé_9957"
						data-name="Tracé 9957"
						d="M775.463-5776.056h-7.232a.2.2,0,0,1-.193-.149,1.666,1.666,0,0,1,.014-.981.791.791,0,0,1,.459-.373,1.225,1.225,0,0,1,.288-.065h1.148a1.883,1.883,0,0,0-.014-.388,1.123,1.123,0,0,0-.15-.395.2.2,0,0,1,.053-.278.2.2,0,0,1,.278.053,1.516,1.516,0,0,1,.215.555,2.706,2.706,0,0,1,0,.68.2.2,0,0,1-.2.174h-1.305c-.1.01-.34.067-.415.218a1.072,1.072,0,0,0-.017.55h6.9a1.828,1.828,0,0,0-.014-.636.175.175,0,0,0-.117-.119.277.277,0,0,0-.085-.014h-1.143a.2.2,0,0,1-.2-.2v-1.242a.2.2,0,0,1,.2-.2.2.2,0,0,1,.2.2v1.042h.949a.6.6,0,0,1,.574.4,2.6,2.6,0,0,1,.008,1A.2.2,0,0,1,775.463-5776.056Z"
						transform="translate(-17.025 -25.691)"
						fill="#254374"
					/>
					<path
						id="Tracé_9958"
						data-name="Tracé 9958"
						d="M797.181-5777.735h-.621a.2.2,0,0,1-.2-.2v-1.278a.2.2,0,0,1,.2-.2.2.2,0,0,1,.2.2v1.078h.421a.2.2,0,0,1,.2.2A.2.2,0,0,1,797.181-5777.735Z"
						transform="translate(-41.629 -25.218)"
						fill="#254374"
					/>
					<path
						id="Tracé_9959"
						data-name="Tracé 9959"
						d="M774.046-5802.989a.2.2,0,0,1-.2-.2v-1.242a.2.2,0,0,1,.13-.187,1.241,1.241,0,0,0,.625-.6,1.43,1.43,0,0,0-.048-1.113,1.024,1.024,0,0,0-.605-.654,1.4,1.4,0,0,0-.849.047,2.221,2.221,0,0,0-.432.194.2.2,0,0,1-.191.011.2.2,0,0,1-.113-.153,1.686,1.686,0,0,0-.271-.641,1.408,1.408,0,0,0-1.226-.589h-.053a1.233,1.233,0,0,0-1.172.716,1.943,1.943,0,0,0-.179.74.2.2,0,0,1-.128.187.2.2,0,0,1-.221-.053,1.414,1.414,0,0,0-.9-.437h-.01a1.091,1.091,0,0,0-.8.435,1.021,1.021,0,0,0-.287.8,1.971,1.971,0,0,0,.731,1.135.2.2,0,0,1,.08.16v.475a.2.2,0,0,1-.2.2.2.2,0,0,1-.2-.2v-.379a2.271,2.271,0,0,1-.809-1.349,1.418,1.418,0,0,1,.384-1.106,1.467,1.467,0,0,1,1.1-.572h.011a1.592,1.592,0,0,1,.9.311,2.261,2.261,0,0,1,.166-.507,1.622,1.622,0,0,1,1.53-.956h.062a1.8,1.8,0,0,1,1.564.773,2.105,2.105,0,0,1,.263.534,1.978,1.978,0,0,1,1.383-.156,1.4,1.4,0,0,1,.852.888,1.807,1.807,0,0,1,.038,1.427,1.612,1.612,0,0,1-.718.746v1.115A.2.2,0,0,1,774.046-5802.989Z"
						transform="translate(-15.937 0)"
						fill="#254374"
					/>
					<path
						id="Tracé_9960"
						data-name="Tracé 9960"
						d="M770.951-5747.815a1.069,1.069,0,0,1-.472-.1,1.486,1.486,0,0,1-.469-.311.794.794,0,0,1-.192-.563,1.2,1.2,0,0,1,.318-.711,2.839,2.839,0,0,1,.3-.315.2.2,0,0,1,.283.018.2.2,0,0,1-.017.282,2.435,2.435,0,0,0-.254.271.856.856,0,0,0-.226.455c0,.267.088.345.435.514a1.143,1.143,0,0,0,.726-.01.2.2,0,0,1,.253.127.2.2,0,0,1-.127.253,1.945,1.945,0,0,1-.343.074A1.8,1.8,0,0,1,770.951-5747.815Z"
						transform="translate(-18.631 -50.823)"
						fill="#254374"
					/>
					<path
						id="Tracé_9961"
						data-name="Tracé 9961"
						d="M814.113-5747.764a.61.61,0,0,1-.162-.021.53.53,0,0,1-.234-.135l.293-.273L814-5748.2c.017.015.11.085.305-.031.37-.22.633-.4.633-.668,0-.1-.1-.234-.288-.376a2.3,2.3,0,0,0-.332-.212.2.2,0,0,1-.09-.269.2.2,0,0,1,.269-.089,2.7,2.7,0,0,1,.4.253.933.933,0,0,1,.443.692.87.87,0,0,1-.294.637,2.914,2.914,0,0,1-.534.376A.782.782,0,0,1,814.113-5747.764Z"
						transform="translate(-56.669 -50.823)"
						fill="#254374"
					/>
					<path
						id="Ellipse_112"
						data-name="Ellipse 112"
						d="M.267,0A.267.267,0,1,1,0,.267.267.267,0,0,1,.267,0Z"
						transform="translate(762.826 -5800.438)"
						fill="#254374"
					/>
					<path
						id="Ellipse_113"
						data-name="Ellipse 113"
						d="M.267,0A.267.267,0,1,1,0,.267.267.267,0,0,1,.267,0Z"
						transform="translate(754.95 -5794.031)"
						fill="#254374"
					/>
					<path
						id="Ellipse_114"
						data-name="Ellipse 114"
						d="M.267,0A.267.267,0,1,1,0,.267.267.267,0,0,1,.267,0Z"
						transform="translate(754.95 -5790.693)"
						fill="#254374"
					/>
					<path
						id="Ellipse_115"
						data-name="Ellipse 115"
						d="M.267,0A.267.267,0,1,1,0,.267.267.267,0,0,1,.267,0Z"
						transform="translate(754.95 -5787.222)"
						fill="#254374"
					/>
				</g>
			</g>
		</svg>
	);
}

export default ChefIcon;
