import React from "react";

function PickIcon({ className }: { className?: string }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			className={className}
			viewBox="0 0 25.599 25.599"
		>
			<g
				id="Groupe_5730"
				data-name="Groupe 5730"
				transform="translate(9043.216 382.26)"
			>
				<path
					id="Tracé_11362"
					data-name="Tracé 11362"
					d="M0,0H25.6V25.6H0Z"
					transform="translate(-9043.216 -382.26)"
					fill="none"
				/>
				<g
					id="Groupe_5777"
					data-name="Groupe 5777"
					transform="translate(-10140.523 13509.564)"
				>
					<rect
						id="Rectangle_288"
						data-name="Rectangle 288"
						width="11"
						height="18"
						rx="2"
						transform="translate(1105.705 -13889.668)"
						fill="#cee0fe"
					/>
					<path
						id="Tracé_11034"
						data-name="Tracé 11034"
						d="M1105.918-13788.865v-3.955a3.085,3.085,0,0,0-.791-1.8,2.379,2.379,0,0,0-1.748-.684.532.532,0,0,0-.589.539,58.33,58.33,0,0,1,0,6.4c-.216,2.4-.584,1.825-.863,3.2a12.37,12.37,0,0,0-.252,2.481l-1.366,1.978v3.813h7.156s1.618-2.122,1.726-2.158a8.151,8.151,0,0,0,2.625-.468,9.873,9.873,0,0,0,2.373-1.475"
						transform="translate(0 -90.173)"
						fill="none"
						stroke="#254374"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="1"
					/>
					<path
						id="Tracé_11035"
						data-name="Tracé 11035"
						d="M1222.217-13875.012v2.375a1.94,1.94,0,0,0,.468,1.078,1.921,1.921,0,0,0,1.115.359h7.372a1.56,1.56,0,0,0,.971-.359,2.4,2.4,0,0,0,.5-1.078v-16.039a2.017,2.017,0,0,0-.549-.828,1.924,1.924,0,0,0-.925-.322H1223.8a2.373,2.373,0,0,0-1.069.322,1.556,1.556,0,0,0-.513.828v5.789"
						transform="translate(-116.299 0.001)"
						fill="none"
						stroke="#254374"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="1"
					/>
					<g
						id="Rectangle_287"
						data-name="Rectangle 287"
						transform="translate(1108.484 -13874.74)"
						fill="none"
						stroke="#254374"
						strokeWidth="1"
					>
						<rect
							width="5.706"
							height="1.749"
							rx="0.874"
							stroke="none"
						/>
						<rect
							x="0.5"
							y="0.5"
							width="4.706"
							height="0.749"
							rx="0.374"
							fill="none"
						/>
					</g>
					<path
						id="Tracé_11036"
						data-name="Tracé 11036"
						d="M1261.314-13760.1h5.344l.683,5.358h-6.832Z"
						transform="translate(-152.829 -123.754)"
						fill="none"
						stroke="#254374"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="1"
					/>
					<path
						id="Tracé_11037"
						data-name="Tracé 11037"
						d="M1312.086-13799.389v-2.049a1.079,1.079,0,0,1,1.115-.863,1.025,1.025,0,0,1,1.079.863v2.049"
						transform="translate(-202.033 -83.497)"
						fill="none"
						stroke="#254374"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="1"
					/>
					<g
						id="Ellipse_122"
						data-name="Ellipse 122"
						transform="translate(1116.123 -13885.798)"
						fill="none"
						stroke="#254374"
						strokeWidth="1"
					>
						<ellipse
							cx="1.786"
							cy="1.894"
							rx="1.786"
							ry="1.894"
							stroke="none"
						/>
						<ellipse
							cx="1.786"
							cy="1.894"
							rx="1.286"
							ry="1.394"
							fill="none"
						/>
					</g>
					<g
						id="Ellipse_125"
						data-name="Ellipse 125"
						transform="translate(1116.123 -13880.294)"
						fill="none"
						stroke="#254374"
						strokeWidth="1"
					>
						<ellipse
							cx="1.786"
							cy="1.894"
							rx="1.786"
							ry="1.894"
							stroke="none"
						/>
						<ellipse
							cx="1.786"
							cy="1.894"
							rx="1.286"
							ry="1.394"
							fill="none"
						/>
					</g>
					<g
						id="Ellipse_123"
						data-name="Ellipse 123"
						transform="translate(1116.123 -13883.033)"
						fill="none"
						stroke="#254374"
						strokeWidth="1"
					>
						<ellipse
							cx="1.786"
							cy="1.894"
							rx="1.786"
							ry="1.894"
							stroke="none"
						/>
						<ellipse
							cx="1.786"
							cy="1.894"
							rx="1.286"
							ry="1.394"
							fill="none"
						/>
					</g>
					<g
						id="Ellipse_124"
						data-name="Ellipse 124"
						transform="translate(1116.123 -13877.528)"
						fill="none"
						stroke="#254374"
						strokeWidth="1"
					>
						<ellipse
							cx="1.786"
							cy="1.894"
							rx="1.786"
							ry="1.894"
							stroke="none"
						/>
						<ellipse
							cx="1.786"
							cy="1.894"
							rx="1.286"
							ry="1.394"
							fill="none"
						/>
					</g>
					<path
						id="Tracé_11038"
						data-name="Tracé 11038"
						d="M1222.217-13676.5v-1.02"
						transform="translate(-116.299 -200.006)"
						fill="none"
						stroke="#254374"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="1"
					/>
				</g>
			</g>
		</svg>
	);
}

export default PickIcon;
