import React from "react";
import CanceledReceipts from "./rapport-operationnel/CanceledReceipts";
import RapportTVA from "./rapport-operationnel/RapportTVA";
import ResultatNet from "./rapport-operationnel/ResultatNet";
import ResultatBrut from "./rapport-operationnel/ResultatBrut";
import { ReportData } from "@/lib/invoice-types";
import NotesDeRapport from "./rapport-operationnel/NotesDeRapport";
import SubSectionTitle from "../sections/SubSectionTitle";
import DrawerOpenings from "./rapport-operationnel/DrawerOpenings";

function ClassicReportByDepartement({
	rapportData,
	
}: {
	rapportData: ReportData;
	
}) {
	return (
		<>
			{/* Card A. Résultat Brut (Ventes) */}
			<div className="flex flex-col w-full">
				<SubSectionTitle className="font-bold">
					A. Résultat Brut (Ventes)
				</SubSectionTitle>
				<ResultatBrut
					rapportData={rapportData}
				/>
			</div>
			{/* Card B. Résultat Net (Recettes) */}
			<div className="flex flex-col w-full">
				<SubSectionTitle className="font-bold">
					B. Résultat Net (Recettes)
				</SubSectionTitle>
				<ResultatNet
					recettes={rapportData.recettes}
					soldeReporte={0}
					reportANouveau={0}
					taxes={8.5}
				/>
			</div>
			{/* Card C. Rapport de TVA */}
			<div className="flex flex-col w-full">
				<SubSectionTitle className="font-bold">
					C. Rapport de TVA
				</SubSectionTitle>
				<RapportTVA
					report={rapportData}
					soldeReporte={0}
					reportANouveau={0}
					taxes={8.5}
					totalOnComplementaryItems={0}
				/>
			</div>
			{/* Card D. Ouvertures de tiroir */}
			<div className="flex flex-col w-full">
				<SubSectionTitle className="font-bold">
					D. Ouvertures de tiroir
				</SubSectionTitle>
				<DrawerOpenings />
			</div>
			{/* Card E. Notes du rapport */}
			<div className="flex flex-col w-full">
				<SubSectionTitle className="font-bold">
					E. Notes du rapport
				</SubSectionTitle>
				{rapportData.reportNotes.length > 0 ? (
					<NotesDeRapport reportNotes={rapportData.reportNotes} />
				) : (
					"Aucune note de rapport enregistrée"
				)}
			</div>
			{/* Card F. Indice météorologique */}
			<div className="flex flex-col w-full">
				<SubSectionTitle className="font-bold">
					F. Indice météorologique
				</SubSectionTitle>
			</div>
			{/* Card G. Articles saisis en mode formation */}
			<div className="flex flex-col w-full">
				<SubSectionTitle className="font-bold">
					G. Articles saisis en mode formation
				</SubSectionTitle>
			</div>
			{/* Card H. Articles offerts */}
			<div className="flex flex-col w-full">
				<SubSectionTitle className="font-bold">
					H. Articles offerts
				</SubSectionTitle>
			</div>
			{/* Card I. Pertes d'articles */}
			<div className="flex flex-col w-full">
				<SubSectionTitle className="font-bold">
					I. Articles en perte
				</SubSectionTitle>
			</div>
			{/* Card J. Articles dans les reçus annulés */}
			<div className="flex flex-col w-full">
				<SubSectionTitle className="font-bold">
					J. Articles présents dans des tickets annulés
				</SubSectionTitle>
				<CanceledReceipts canceledReceipts={rapportData.canceledReceipts} />
			</div>
			{/* Card K. Back Office corrections included in the report and made after period close */}
			<div className="flex flex-col w-full">
				<SubSectionTitle className="font-bold">
					K. Corrections de Back Office incluses dans le rapport et effectuées
					après la clôture de la période.
				</SubSectionTitle>
			</div>
		</>
	);
}

export default ClassicReportByDepartement;
