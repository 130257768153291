import { IconButton } from "@material-tailwind/react";
import { ReactNode } from "react";

interface ButtonProperties {
	children: ReactNode;
	placeholder?: string;
	className?: string;
	onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

function RoundButton({
	children,
	placeholder,
	className,
	onClick,
}: ButtonProperties) {
	return (
		<IconButton
			onClick={onClick}
			variant="filled"
			className={`flex rounded-full bg-gray-200 shadow-sm p-2 ${className}`}
			placeholder={placeholder}
		>
			{children}
		</IconButton>
	);
}

export default RoundButton;
