/* eslint-disable no-loop-func */
import React from "react";
import moment, { Moment } from "moment";
import * as enums from "@/lib/enums";
import * as functions from "@/lib/functions";
import NbTodos from "../todos/NbTodos";
import { useModalStore } from "_services/useModalStore";
import TodoDetail from "../todos/TodoDetail";
import SettingButton from "../buttons/SettingButton";

moment.locale("fr", enums.momentFR);

CalendarMonth.propTypes = {};

function CalendarMonth({ currentDate }: { currentDate: Moment }) {
	const [isOpenDetail, detailNode, openModalDetail] = useModalStore((state) => [
		state.isOpenDetail,
		state.detailNode,
		state.openModalDetail,
	]);
	const currentCalendar = functions.getMonthDays(
		parseInt(currentDate.format("MM")),
		parseInt(currentDate.format("YYYY"))
	);
	let strMonth = "";
	return (
		<>
			<div className="grid grid-cols-1 h-full w-full bg-blue-50/50 border-[2px] border-blue-300/50">
				{currentCalendar.map((calDate, calDate_index) => {
					return (
						<React.Fragment key={calDate_index}>
							<div className="grid grid-cols-7">
								{calDate.map((day, day_index) => {
									let isDiff = false;
									const testDay = moment(day.format("YYYYMMDD"), "YYYYMMDD");
									if (strMonth !== testDay.format("MMM.")) {
										isDiff = true;
										strMonth = testDay.format("MMM.");
									}
									const today = moment().clone().format("YYYYMMDD");
									const isPast =
										parseInt(testDay.format("YYYYMMDD")) < parseInt(today);
									const isToday = today === testDay.format("YYYYMMDD");

									const isThisMonth = today === testDay.format("YYYYMM");
									const isCurrMonth =
										currentDate.format("YYYYMM") === testDay.format("YYYYMM");

									return (
										<React.Fragment key={day_index}>
											<SettingButton
												title={`Notes du ${day.format("D MMMM YYYY")}`}
												className={
													"flex w-full h-full rounded-none bg-transparent p-0 m-0 shadow-none text-inherit font-normal"
												}
												element={<TodoDetail date={day} />}
											>
												<div
													onClick={() => {
														openModalDetail(<TodoDetail date={day} />);
													}}
													className={`grid grid-rows-[auto_auto_1fr] w-full min-h-12 h-full ${"border-r-blue-300/50 border-b-blue-300/50 border-r-[1px] border-b-[1px]"} ${
														isToday
															? "bg-blue-100"
															: !isCurrMonth && !isToday && !isPast
															? "text-gray-400 bg-gray-50"
															: isPast
															? "text-gray-400 bg-gray-100"
															: ""
													}`}
												>
													<div
														className={`h-1 mb-1 ${
															isToday ? " bg-blue-300/50" : ""
														}`}
													></div>
													<div
														className={`flex flex-row px-1 gap-1 ${
															isDiff ? "font-bold" : ""
														}`}
													>
														<div className="text-xs leading-4">
															{day.format("D")}
														</div>
														<div className="text-xs leading-4 items-end">
															{isDiff ? strMonth : ""}
														</div>
													</div>
													<div className="hidden md:flex px-1">
														<NbTodos date={day} />
													</div>
												</div>
											</SettingButton>
										</React.Fragment>
									);
								})}
							</div>
						</React.Fragment>
					);
				})}
			</div>
		</>
	);
}

export default CalendarMonth;
