import { ReactNode, useContext } from "react";
import AppPathWay from "./AppPathWay";
import AppFooter from "./AppFooter";
import { useAppSettingStore } from "../service/useAppSettingStore";
import LeftNav from "./LeftNav";
import Login from "@/components/auth/Login";
import { useAuthContextStore } from "@/service/useAuthContextStore";
import { useAuth } from "@/components/auth/AuthContext";

function PageContent({ children }: { children: ReactNode | undefined }) {
	const [isCollapsed] = useAppSettingStore((state) => [state.isCollapsed]);
	const { currentUser } = useAuth();

	return (
		<>
			{!currentUser ? (
				<Login />
			) : (
				<div className="grid grid-cols-[auto_1fr] w-full h-full overflow-hidden bg-gray-50">
					<div
						className={`relative flex ${
							isCollapsed ? "w-[56px]" : "w-[250px]"
						} `}
					>
						<LeftNav />
					</div>
					<div
						className={`grid grid-cols-1 grid-rows-[auto_1fr_auto] w-full overflow-hidden ${
							isCollapsed ? "" : ""
						}`}
					>
						<AppPathWay />
						<div className="grid w-full h-full overflow-hidden">{children}</div>
						<AppFooter />
					</div>
				</div>
			)}
		</>
	);
}

export default PageContent;
