import React from "react";
import {
	Card,
	CardHeader,
	CardBody,
	Button,
	Typography,
} from "@material-tailwind/react";
import TaxesLines from "./TaxesLines";
import { StatisticalReportItem } from "../RapportOperationnel";
import * as enums from "@/lib/enums";
import * as invoiceTypes from "@/lib/invoice-types";
import { useReportStore } from "_services/useReportStore";

const ResultatBrut = ({
	rapportData,
}: {
	rapportData: invoiceTypes.ReportData;
}) => {
	const [displayView, takingView, groupBy, timeInterval] = useReportStore(
		(state) => [
			state.displayView,
			state.takingView,
			state.groupBy,
			state.timeInterval,
		]
	);

	const isResume: boolean =
		displayView === enums.REPORT_FILTER_VIEW_ACCOUNTING ||
		displayView === enums.REPORT_FILTER_VIEW_STATISTICAL;
	let totalVentesArticles = 0;
	let totalVentesHT = 0;
	let totalVentesTTC = 0;
	let totalVentesDiscount = 0;

	rapportData.ventes.map((vente) => {
		totalVentesArticles += vente.quantite;
		totalVentesHT += (vente.totalTTC * vente.tva) / 100;
		// vente.montantTaxe6 +
		// vente.montantTaxe8 +
		// vente.montantTaxe12 +
		// vente.montantTaxe20; //vente.totalHT;
		totalVentesTTC += vente.totalTTC;
		totalVentesDiscount += vente.discounts;
	});
	const totaux = rapportData.ventes.reduce(
		(acc, item) => {
			return {
				quantite: acc.quantite + item.quantite,
				//total: acc.total + item.total,
				totalTTC: acc.totalTTC + item.totalTTC,
				discounts: acc.discounts + item.discounts,
				totalTTC_less_discounts:
					acc.totalTTC_less_discounts + (item.totalTTC - item.discounts),
				montantTva: acc.montantTva + item.montantTva,
				tva: acc.tva + item.tva,
				//montantTaxe: acc.montantTaxe + item.montantTaxe,
				// tva6: acc.tva6 + item.tva6,
				// montantTaxe6: acc.montantTaxe6 + item.montantTaxe6,
				// tva8: acc.tva8 + item.tva8,
				// montantTaxe8: acc.montantTaxe8 + item.montantTaxe8,
				// tva12: acc.tva12 + item.tva12,
				// montantTaxe12: acc.montantTaxe12 + item.montantTaxe12,
				// tva20: acc.tva20 + item.tva20,
				// montantTaxe20: acc.montantTaxe20 + item.montantTaxe20,
				// totalTaxes:
				// 	acc.totalTaxes +
				// 	(item.montantTaxe +
				// 		item.montantTaxe6 +
				// 		item.montantTaxe8 +
				// 		item.montantTaxe12 +
				// 		item.montantTaxe20),
				// totalHT:
				// 	acc.totalHT +
				// 	(item.montantTaxe -
				// 		(item.tva6 + item.tva8 + item.tva12 + item.tva20)),
				totalHT: acc.totalHT + (item.montantTva - item.tva),
				//pourcentageHT: acc.pourcentageHT + item.pourcentageHT,
			};
		},
		{
			quantite: 0,
			//total: 0,
			totalTTC: 0,
			discounts: 0,
			totalTTC_less_discounts: 0,
			montantTva: 0,
			tva: 0,
			tva6: 0,
			montantTaxe6: 0,
			tva8: 0,
			montantTaxe8: 0,
			tva12: 0,
			montantTaxe12: 0,
			tva20: 0,
			montantTaxe20: 0,
			totalTaxes: 0,
			totalHT: 0,
			//pourcentageSansTVA: 0,
		}
	);

	return (
		<Card className="rounded-md p-0 text-xs">
			<CardBody className="p-0">
				<Typography
					variant="h6"
					color="blue-gray"
					className="p-2"
				>
					Départements
				</Typography>
				{isResume ? (
					<table className="min-w-full leading-normal">
						<tr className="bg-gray-100 font-bold">
							<th className="p-2 border-b border-gray-200">Départements</th>
							<th className="p-2 border-b border-gray-200">Quantité</th>
							<th className="p-2 border-b border-gray-200">Total</th>
							<th className="p-2 border-b border-gray-200">Remises</th>
							<th className="p-2 border-b border-gray-200">
								Total TTC (Moins discounts)
							</th>
							<th className="p-2 border-b border-gray-200">Montant TVA</th>
							<th className="p-2 border-b border-gray-200">TVA</th>
							{/* <th>Montant Taxé</th>
							<th>TVA 6%</th>
							<th>Montant TVA 6%</th>
							<th>TVA 8.5%</th>
							<th>Montant TVA 8.5%</th>
							<th>TVA 12%</th>
							<th>Montant TVA 12%</th>
							<th>TVA 20%</th>
							<th>Montant TVA 20%</th> */}

							<th className="p-2 border-b border-gray-200">% sans TVA</th>
							<th className="p-2 border-b border-gray-200">Total HT</th>
						</tr>

						{rapportData.ventes.map((stat) => {
							const totalTaxes = stat.tva;
							//stat.tva6 + stat.tva8 + stat.tva12 + stat.tva20;

							const totalHT = stat.montantTva - totalTaxes;
							return (
								<tr key={stat.departement}>
									<td className="p-2 border-b border-gray-200">
										{stat.departement}
									</td>

									<td className="p-2 border-b border-gray-200 text-right">
										{stat.quantite.toFixed(2)}
									</td>

									<td className="p-2 border-b border-gray-200 text-right">
										{stat.totalTTC.toFixed(2)}€
									</td>

									<td className="p-2 border-b border-gray-200 text-right">
										{stat.discounts.toFixed(2)}€
									</td>

									<td className="p-2 border-b border-gray-200 text-right">
										{(stat.totalTTC - stat.discounts).toFixed(2)}€
									</td>

									<td className="p-2 border-b border-gray-200 text-right">
										{stat.montantTva.toFixed(2)}€
									</td>

									<td className="p-2 border-b border-gray-200 text-right">
										{stat.tva.toFixed(2)}%
									</td>
									{/* 
							<td className="p-2 border-b border-gray-200">{stat.tva6}</td>									
							<td className="p-2 border-b border-gray-200">{stat.montantTaxe6}</td>									
							<td className="p-2 border-b border-gray-200">{stat.tva8}</td>									
							<td className="p-2 border-b border-gray-200">{stat.montantTaxe8}</td>									
							<td className="p-2 border-b border-gray-200">{stat.tva12}</td>									
							<td className="p-2 border-b border-gray-200">{stat.montantTaxe12}</td>									
							<td className="p-2 border-b border-gray-200">{stat.tva6}</td>									
							<td className="p-2 border-b border-gray-200">{stat.montantTaxe6}</td>									
							<td className="p-2 border-b border-gray-200">{stat.tva20}</td>									
							<td className="p-2 border-b border-gray-200">{stat.montantTaxe20}</td> */}

									<td className="p-2 border-b border-gray-200 text-right">
										{((totalHT / totaux.totalHT) * 100).toFixed(2)}%
									</td>

									<td className="p-2 border-b border-gray-200 text-right">
										{totalHT.toFixed(2)}€
									</td>
								</tr>
							);
						})}
						<tr className="bg-gray-100 font-bold">
							<td className="p-2 border-b border-gray-200">Total EUR</td>
							<td className="p-2 border-b border-gray-200 text-right">
								{totaux.quantite}
							</td>
							<td className="p-2 border-b border-gray-200 text-right">
								{totaux.totalTTC.toFixed(2)}€
							</td>
							<td className="p-2 border-b border-gray-200 text-right">
								{totaux.discounts.toFixed(2)}€
							</td>
							<td className="p-2 border-b border-gray-200 text-right">
								{(totaux.totalTTC_less_discounts ?? 0).toFixed(2)}€
							</td>
							<td className="p-2 border-b border-gray-200 text-right">
								{totaux.montantTva.toFixed(2)}€
							</td>
							<td className="p-2 border-b border-gray-200 text-right"></td>
							{/* <td>{totaux.tva6}</td>
							<td>{totaux.montantTaxe6}</td>
							<td>{totaux.tva8}</td>
							<td>{totaux.montantTaxe8}</td>
							<td>{totaux.tva12}</td>
							<td>{totaux.montantTaxe12}</td>
							<td>{totaux.tva6}</td>
							<td>{totaux.montantTaxe6}</td>
							<td>{totaux.tva20}</td>
							<td>{totaux.montantTaxe20}</td> */}
							<td className="p-2 border-b border-gray-200 text-right">100%</td>
							<td className="p-2 border-b border-gray-200 text-right">
								{totaux.totalHT.toFixed(2)}€
							</td>
						</tr>
					</table>
				) : (
					<table className="min-w-full leading-normal">
						<tbody>
							{/* Section Drinks */}
							{rapportData.ventes.map((vente, vente_index) => {
								return (
									<React.Fragment key={vente_index}>
										<tr className="bg-gray-100 font-bold">
											<td className="p-2 border-b border-gray-200 font-bold">
												{vente.departement}
											</td>
											<td className="p-2 border-b border-gray-200 text-right">
												{vente.quantite} articles
											</td>
											<td className="p-2 border-b border-gray-200 text-right">
												{((vente.quantite / totalVentesArticles) * 100).toFixed(
													2
												)}
												%
											</td>
											<td className="p-2 border-b border-gray-200 text-right">
												{vente.totalTTC.toFixed(2)}€
											</td>
										</tr>

										{vente.tva > 0 && (
											<>
												<TaxesLines
													tva={vente.tva}
													montantTva={vente.montantTva}
													totalTTC={vente.totalTTC}
													discount={vente.discounts}
												/>
											</>
										)}
										{/* {vente.montantTaxe8 > 0 && (
										<>
											<TaxesLines
												taxePercent={vente.tva8}
												taxedValue={vente.montantTaxe8}
												totalTTC={vente.totalTTC}
												discount={vente.discounts}
											/>
										</>
									)}
									{vente.montantTaxe12 > 0 && (
										<>
											<TaxesLines
												taxePercent={vente.tva12}
												taxedValue={vente.montantTaxe12}
												totalTTC={vente.totalTTC}
												discount={vente.discounts}
											/>
										</>
									)}
									{vente.montantTaxe20 > 0 && (
										<>
											<TaxesLines
												taxePercent={vente.tva20}
												taxedValue={vente.montantTaxe20}
												totalTTC={vente.totalTTC}
												discount={vente.discounts}
											/>
										</>
									)} */}
									</React.Fragment>
								);
							})}
							{/* Total */}

							<tr className="bg-gray-100 font-bold">
								<td className="p-2 border-b border-gray-200">Ventes TTC</td>
								<td className="p-2 border-b border-gray-200 whitespace-nowrap">
									{totalVentesArticles} articles
								</td>
								<td className="p-2 border-b border-gray-200"></td>
								<td className="p-2 border-b border-gray-200 text-right">
									{totalVentesTTC.toFixed(2)}€
								</td>
							</tr>
							<tr className={`${totalVentesDiscount === 0 && "hidden"}`}>
								<td className="p-2 border-b border-gray-300">
									Moins les remises
								</td>
								<td className="p-2 border-b border-gray-300 text-right">
									{totalVentesDiscount.toFixed(2)}€
								</td>
								<td className="p-2 border-b border-gray-300"></td>
								<td className="p-2 border-b border-gray-300"></td>
							</tr>
							{/* Resultat */}

							<tr className="bg-gray-100 font-bold text-lg">
								<td className="p-2 border-b border-gray-200">
									CA Brut (Ventes)
								</td>
								<td className="p-2 border-b border-gray-200"></td>
								<td className="p-2 border-b border-gray-200"></td>
								<td className="p-2 border-b border-gray-200 text-right">
									{(totalVentesTTC - totalVentesDiscount).toFixed(2)}€
								</td>
							</tr>
							<tr className={`${totalVentesDiscount === 0 && "hidden"}`}>
								<td className="p-2">moyenne par couverture sur les ventes</td>
								<td className="p-2"></td>
								<td className="p-2"></td>
								<td className="p-2"></td>
							</tr>

							{/* Ajouter les lignes similaires pour la section Food et les totaux */}

							{/* Autres sections du tableau comme Total, Réductions, etc. */}
						</tbody>
					</table>
				)}
			</CardBody>
		</Card>
	);
};

export default ResultatBrut;
