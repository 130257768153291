import React from "react";
import PageContent from "../../PageContent";

function DashBoard() {
	return (
		<PageContent>
			<div className="grid grid-cols-[auto_1fr]">
				<div className="flex flex-col w-full h-full overflow-y-auto">
					<div className="flex flex-col w-full">
						<div className="flex flex-row gap-1">
							<div className="">Filtre période</div>
							<div className="">hier</div>
							<div className="">semaien X</div>
							<div className="">MMM YYYY</div>
							<div className="">Année en cours</div>
						</div>
						<div className="">
							Les chiffres ci-dessous concernent la période du ddd DD/MM/YYYY au
							ddd DD/MM/YYYY HH:mm{" "}
						</div>
					</div>
					<div className="">Resultat brut</div>
					<div className="">Resultat par période</div>
					<div className=""></div>
				</div>

				<div className="flex flex-col w-full h-full overflow-y-auto">
					<div className="flex flex-col w-full">
						<div className="flex flex-row gap-1">
							<div className="">30 derniers jours</div>
							<div className="">3 derniers mois</div>
							<div className="">Année en cours</div>
							<div className="">Année YYYY</div>
						</div>
						<div className="">
							Les chiffres ci-dessous concernent la période du ddd DD/MM/YYYY au
							ddd DD/MM/YYYY HH:mm{" "}
						</div>
					</div>
					<div className="flex flex-col">
						<div className="grid grid-cols-3">
							<div className="">Occupation moyenne des tables (HH:mm)</div>
							<div className="">Revenu par couvert</div>
							<div className="">Revenu par ticket</div>
							<div className="">Meilleurs 5 paiements</div>
							<div className="">Meilleures 5 ventes</div>
							<div className="">Meilleures 5 heures</div>
						</div>
						<div className="grid grid-cols-2">
							<div className="">Annulations</div>
							<div className="">stock critique</div>
							<div className="">Meilleure journée</div>
							<div className="">Meilleurs clients</div>
							<div className=""></div>
							<div className=""></div>
						</div>
						<div className=""></div>
						<div className=""></div>
						<div className=""></div>
						<div className=""></div>
						<div className=""></div>
						<div className=""></div>
					</div>
				</div>
			</div>
		</PageContent>
	);
}

export default DashBoard;
