import * as clientTypes from "./client-types";
import * as enums from "./enums";
import { Board, BoardCard, BoardColumn } from "./board-types";
import * as firebase from "firebase/auth";
import { Icons } from "./images";

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface Campagne {
	id: number;
	client?: clientTypes.Client;
	createdAt: string;
	updatedAt: string;
	date_debut: string;
	date_fin: string;
	nom_campagne: string;
	stats: GeoStat;
	slug: string;
}

export interface Agency {
	id: number;
	name: string;
	slug: string;
	Adresse: string;
	Adresse2: string;
	CodePostal: string;
	Ville: string;
	Pays: string;
	phone: string;
	email: string;
}

export interface GraphSerie {
	//bar & line
	name: string;
	type: string;
	data: number[];
	smooth: boolean;
	seriesLayoutBy: string;
	itemStyle?: {
		color?: string;
	};
	lineStyle?: {
		width?: number;
	};

	//bar
	symbol?: string;
	symbolSize?: number;
	sampling?: string;
	stack?: string;
	campagne?: string;
}

export interface GeoStat {
	campagne: string;
	series: GraphSerie[];
	headers: string[];
	countries?: string[];
	pages?: string[];
	/*createdAt: string;
	id: number;
	href: string;
	count: number;
	url: string;
	countryCode: string;
	countryName: string;
	continentCode: string;
	continentName: string;
	latitude: string;
	longitude: string;
	timezone: string;
	city: string;
	region: string;
	regionCode: string;
	regionName: string;
	areaCode: string;
	dmaCode: string;
	campagne: Campagne;
	search: string;
	format: string;

	score: number;
	totalScore: number;*/
}

export type GeoStatType = keyof GeoStat;

export interface CalPeriod {
	from: string;
	to: string;
}

export interface DegreeList {
	level: number;
	data?: any;
}

export interface IGroupedList {
	keyz: string[];
	list: any[];
	[key: string]: any;
	count?: number;
}

export interface ListMenuItem {
	title: string;
	description: string;
	icon: React.ForwardRefExoticComponent<
		Omit<React.SVGProps<SVGSVGElement>, "ref"> & {
			title?: string | undefined;
			titleId?: string | undefined;
		} & React.RefAttributes<SVGSVGElement>
	>;
}

export type Data = {
	[x: string]: any;
	todos: Todo[];
};

export type PageProps = {
	[key: string]: any;
	params?: PageParams;
};

export type PageParams = {
	boardId: string;
	[key: string]: any;
};
/**______________________________ */

export interface Agence {
	id?: number | string;
	name: string;
	slug: string;
	Adresse?: string;
	Adresse2?: string;
	CodePostal?: string;
	Ville?: string;
	Pays?: string;
	phone?: string;
	email?: string;
}

export class Contrat {
	id?: number | string;
	client: clientTypes.Client = new clientTypes.Client();
	amount: number = 0;
	amount_payed: number = 0;
	date_livraison?: Date;
	date_paiement?: Date;
	date_signature?: Date;
	name: string = "";
	slug: string = "";
	taxe: number = 0;
	cancelled: boolean = false;
	devis_sended: boolean = false;
	devis_signed: boolean = false;
	facture_payed: boolean = false;
	facture_sended: boolean = false;
	project_sended: boolean = false;
	status?: number = enums.INVOICE_STATUS_PENDING;
	[key: string]: any;
}

export interface EncodedPdf {
	id?: number | string;
	name: string;
	encoded: string;
}
/**______________________________ */

export type Todo = {
	$id: string;
	title: string;
	description?: string;
	order?: number;
	status: enums.TodoStatus;
	image?: string;

	$dateCreated?: string;
	$dateUpdated?: string;
	active: boolean;
	isDeleting?: boolean;

	labels?: TodoLabel[];
};

export type TodoLabel = {
	title: string;
	type: string;
};

export type TodoStore = {
	todos?: Todo[];
	todo?: Todo;
};

export type TodoService = TodoStore & {
	getAll: () => Promise<void>;
	getById: (id: number) => Promise<void>;
	create: (owner: Todo) => Promise<void>;
	update: (id: number, params: Partial<Todo>) => Promise<void>;
	delete: (id: number) => Promise<void>;
};

/**______________________________ */

export type Alert = {
	type: string;
	message: string;
	showAfterRedirect: boolean;
};

export type AlertStore = {
	alert?: Alert;
};

export type AlertService = AlertStore & {
	success: (message: string, showAfterRedirect?: boolean) => void;
	error: (message: string, showAfterRedirect?: boolean) => void;
	clear: () => void;
};

/**______________________________ */

export interface Limits {
	cards: Cards;
}

export interface Cards {
	openPerList: OpenPerList;
	totalPerList: TotalPerList;
}

export interface OpenPerList {
	status: string;
	disableAt: number;
	warnAt: number;
}

export interface TotalPerList {
	status: string;
	disableAt: number;
	warnAt: number;
}

export interface Column {
	id: string;
	todos: BoardCard[];
}

export type Image = {
	bucketId: string;
	fileId: string;
};

export type BoardStore = {
	board: Board;
	boards: Board[];
};

export interface BoardService extends BoardStore {
	get: () => Promise<void>;
	getById: (id: string) => Promise<void>;
	create: (board: Board) => Promise<void>;
	update: (id: string, params: Partial<Todo>) => Promise<void>;
	delete: (taskIndex: number, todo: BoardCard, id: string) => Promise<void>;
	searchString: string;
	newTaskInput: string;
	newTaskType: string;
	image: File | null;
	setSearchString: (searchString: string) => void;
	setNewTaskInput: (input: string) => void;
	setNewTaskType: (type: string) => void;
	setImage: (image: File | null) => void;
	addTask: (
		todo: string,
		boardId: string,
		columnId: string,
		image?: File | null
	) => void;
	resetColumns: (columns: BoardColumn[]) => Promise<void>;
	resetCards: (cards: BoardCard[]) => Promise<void>;
}

export interface GlobalState {
	logMessage: string;
}

export type CallBackType = (
	event: React.MouseEvent<HTMLButtonElement, MouseEvent>
) => void;

export type Field = {
	begin?: any;
	end?: any;
};

export type Task = {
	title: string;
	description?: string;
};

export type Action = {
	timestamp: number;
	action: { type: string; idLabel: string };
	card_label?: string;
	context: {
		idCard: string;
		idList: string;
		idBoard: string;
		idLabels: [];
		idMembers: [];
	};
};

export type FilterType =
	| "invoiceClients"
	| "invoiceStatus"
	| "sexe"
	| "remplacant"
	| "experience"
	| "specialite";

export type ListFiltersType = "clients" | "status";

export type Alignment = "start" | "end";
export type Side = "top" | "right" | "bottom" | "left";
export type AlignedPlacement = `${Side}-${Alignment}`;
export type Placement = Side | AlignedPlacement;
export type Strategy = "absolute" | "fixed";
export type Axis = "x" | "y";
export type Length = "width" | "height";
export type SideObject = {
	[key in Side]: number;
};
export type Rect = {
	x: number;
	y: number;
	width: number;
	height: number;
};

export class RoomOption {
	showWaiterSector: boolean = true;
	showTimer: boolean = false;
}

export interface Section {
	id: string;
	title: string;
	value?: string;
	content: React.ReactNode | string;
	slug: string;
}

export interface IAnamneseBase {
	id: number;
	label: string;
	src: string;
	categ: string;
}

export interface IEnumsList {
	id: any;
	title: string;
	label?: string;
	code?: string;
	tag?: string;
	code_region?: number;
}

export interface LoginFormValues {
	email: string;
	password: string;
}

export interface UserFormValues {
	email: string;
	password: string;
	displayName: string;
}

//IAuth context
export interface IAuthContext {
	user: firebase.User | null; //type User comes from firebase
	loading: boolean;
	SignIn: (creds: LoginFormValues) => Promise<firebase.UserCredential>;
	SignUp: (creds: UserFormValues) => Promise<firebase.UserCredential>;
	SignOut: () => void;
}

export interface IAuth {
	user: firebase.User | null; //type User comes from firebase
	loading: boolean;
	SignIn: (creds: LoginFormValues) => void;
	SignUp: (creds: UserFormValues) => void;
	SignOut: () => void;
}

export interface LoginError {
	message: string;
}

export class CalendarOption {
	id?: string;
	showHolidays: boolean = true;
	showRemplas: boolean = true;
	showClosedDays: boolean = true;
	showDeletedMeetings: boolean = true;
	showEndedMeetings: boolean = true;
	showUrgences: boolean = true;
	showEmptySlots: boolean = true;
	showMonday: boolean = true;
	showTuesday: boolean = true;
	showWednesday: boolean = true;
	showThursday: boolean = true;
	showFriday: boolean = true;
	showSaturday: boolean = true;
	showSunday: boolean = true;
	consultStepMin: number = 20;
	pauseHourRange: string[] = ["12:00", "14:00"];
	dayHourRange: string[] = ["08:00", "19:00"];
}

export class AppConfig {}

export type IconNames = keyof typeof Icons;

export interface IconProperties {
	className?: string;
	viewBox?: string;
	title?: string;
	style?: any;
	role?: string;
	size?: sizeList;
	width?: number | string;
	height?: number | string;
	name: IconNames;
	fill?: string;
	transform?: string;
	isImg?: boolean;
}

export type sizeList = "16" | "24" | "32" | "40" | "45" | "50" | "60";