import React from "react";

function SubSectionTitle({
	children,
	className,
}: {
	children: React.ReactNode;
	className?: string;
}) {
	return (
		<div className={`flex text-xl p-3 my-2 ${className ?? "w-full"}`}>
			{children}
		</div>
	);
}

export default SubSectionTitle;
