import React from "react";

export interface TaxeType {
	tva: number;
	montantTva: number;
	totalTTC: number;
	discount: number;
}
const TaxesLines: React.FC<TaxeType> = ({
	tva,
	montantTva,
	totalTTC,
	discount,
}) => {
	const totalHT = totalTTC - discount - montantTva;
	return (
		<>
			{discount > 0 && (
				<>
					<tr>
						<td className="p-2 border-b border-gray-200">
							Ventes (TVA {tva}%)
						</td>
						<td className="p-2 border-b border-gray-200 text-right">
							{totalTTC.toFixed(2)}€
						</td>
						<td className="p-2 border-b border-gray-200"></td>
						<td className="p-2 border-b border-gray-200 text-right">
							{totalTTC.toFixed(2)}€
						</td>
					</tr>
					<tr>
						<td className="p-2 border-b border-gray-200">Moins les remises</td>
						<td className="p-2 border-b border-gray-200"></td>
						<td className="p-2 border-b border-gray-200"></td>
						<td className="p-2 border-b border-gray-200 text-right">
							{(totalTTC - discount).toFixed(2)}€
						</td>
					</tr>
				</>
			)}
			<tr className="font-bold">
				<td className="p-2 border-b border-gray-200">
					Ventes TTC (TVA {tva}%)
				</td>
				<td className="p-2 border-b border-gray-200 text-right">
					{totalTTC.toFixed(2)}€
				</td>
				<td className="p-2 border-b border-gray-200"></td>
				<td className="p-2 border-b border-gray-200 text-right">
					{totalTTC.toFixed(2)}€
				</td>
			</tr>
			<tr>
				<td className="p-2 border-b border-gray-200">Moins les taxes</td>
				<td className="p-2 border-b border-gray-200 text-right">
					{montantTva.toFixed(2)}€
				</td>
				<td className="p-2 border-b border-gray-200"></td>
				<td className="p-2 border-b border-gray-200"></td>
			</tr>
			<tr>
				<td className="p-2 border-b border-gray-300">Ventes HT (TVA {tva}%)</td>
				<td className="p-2 border-b border-gray-300 text-right">
					{totalHT.toFixed(2)}€
				</td>
				<td className="p-2 border-b border-gray-300"></td>
				<td className="p-2 border-b border-gray-300"></td>
			</tr>
		</>
	);
};

export default TaxesLines;
