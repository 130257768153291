import PDFSVG from "@/components/svg/PDFSVG";
import { Button, Card } from "@material-tailwind/react";
import React from "react";

export interface RapportInfoProps {
	nbReceipts: number;
	dateOfFirstReceipt: string;
	dateOfLastReceipt: string;
}
const RapportInfo: React.FC<RapportInfoProps> = ({
	nbReceipts,
	dateOfFirstReceipt,
	dateOfLastReceipt,
}) => {
	return (
		<>
			<div className="">
				<Card className="bg-white shadow-md rounded p-4 mb-4 text-xs">
					<div className="">
						Ce rapport présente les informations financières pour la période
						sélectionnée. Les articles offerts (vendus à prix zéro) ne sont pas
						inclus ni dans le CA Brut ni dans le CA net et ne sont pas soumis à
						la TVA.
					</div>
					<div className="">
						{nbReceipts} reçus valides ont été créés pendant la période.
					</div>
					<div className="">
						<div className="">Date du premier reçu :</div>{" "}
						<div className="">{dateOfFirstReceipt}</div>{" "}
					</div>
					<div className="">
						<div className="">Date du dernier reçu :</div>{" "}
						<div className="">{dateOfLastReceipt}</div>{" "}
					</div>
				</Card>
				<Card className="bg-white shadow-md rounded p-4 mb-4 text-xs gap-4">
					<div className="">
						Utilisez le bouton ci-dessous pour générer un rapport PDF
					</div>
					<div className="flex justify-between gap-2">
						<Button
							className={`flex flex-row gap-1 text-xs capitalize font-normal px-2 py-1 rounded-md text-gray-800 bg-gray-300/50 items-center`}
						>
							<PDFSVG className="h-10" />
							Rapport partiel
						</Button>
						<Button
							className={`flex flex-row gap-1 text-xs capitalize font-normal px-2 py-1 rounded-md text-gray-800 bg-gray-300/50 items-center`}
						>
							<PDFSVG className="h-10" />
							Rapport complet
						</Button>
					</div>
				</Card>
			</div>
		</>
	);
};

export default RapportInfo;
