import React from "react";
import PageContent from "../../PageContent";

function Restaurant() {
	return (
		<PageContent>
			<div>
				<div className="">Restaurant</div>
				<div className=""></div>
				<div className=""></div>
				<div className=""></div>
				<div className=""></div>
				<div className=""></div>
				<div className=""></div>
				<div className=""></div>
				<div className=""></div>
				<div className=""></div>
			</div>
		</PageContent>
	);
}

export default Restaurant;
