//"use server";
//import { databases } from "@/appwrite";
import * as types from "./types";
import * as enums from "./enums";
import * as formaters from "./formaters";
import { Board, BoardCard, BoardColumn, CardLabel } from "./board-types";
import { User } from "./user-types";
import { Invoice } from "./invoice-types";
import moment, { Moment } from "moment";
import { Restaurant, RestaurantRoom, TableDetail } from "./restaurant-types";
import {
	IngredientStock,
	Language,
	MenuCateg,
	MenuProduct,
	RestaurantMenuCateg,
	RestaurantMenuProduct,
} from "./menu-types";
import { IngredientsWishList } from "./recipe-type";

export const getTodosGroupedByColumn = (
	todos: types.Todo[],
	orderedColumns?: string[]
) => {
	//debugger;
	//const data = await databases.todos;

	//console.log(data);
	const columns: Map<enums.TodoStatus, types.Column> = todos.reduce(
		(acc: any, todo: types.Todo) => {
			if (!acc.get(todo.status)) {
				acc.set(todo.status, { id: todo.status, todos: [] });
			}

			acc.get(todo.status)!.todos.push({
				...todo,
				$id: todo.$id,
				$dateCreated: todo.$dateCreated,
				title: todo.title,
				status: todo.status,
				...(todo.image && { image: todo.image }),
				active: true,
			});

			return acc;
		},
		new Map<enums.TodoStatus, types.Column>()
	);

	const cols = orderedColumns
		? orderedColumns.map((c) => c as enums.TodoStatus)
		: undefined;

	const columnTypes: enums.TodoStatus[] = cols ?? [
		enums.TodoStatus.todo,
		enums.TodoStatus.inprogress,
		enums.TodoStatus.done,
	];

	for (const columnType of columnTypes) {
		if (!columns.get(columnType)) {
			columns.set(columnType, { id: columnType, todos: [] });
		}
	}

	const sortedColumns = new Map(
		Array.from(columns.entries()).sort(
			(a: any, b: any) => columnTypes.indexOf(a[0]) - columnTypes.indexOf(b[0])
		)
	);

	//const _board: Board = { columns: sortedColumns };
	return sortedColumns;
};

export const fetchSuggestion = async (board: Board) => {
	/*const todos = formaters.formatTodosForAI(board);
	//console.log("formatted todo to send >> ", todos);
	const url = `http://localhost:3000/api/generateSummary`;
	const res = await fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(todos),
	});

	const GPTdata = await res.json();
	const { content } = GPTdata;
	return content;*/
};

export const fetchSaveClientMenuCateg = async (
	restaurantId: string,
	newRestaurantClientMenuCategs: RestaurantMenuCateg[]
) => {
	const data = { restaurantId, menuCategs: newRestaurantClientMenuCategs };
	//console.log("formatted todo to send >> ", todos);
	const url = `http://localhost:3000/api/saveClientMenuCateg`;
	const res = await fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(data),
	});

	const GPTdata = await res.json();
	const { content } = GPTdata;
	return content;
};

export const fetchSaveRestaurantIngredientsWishList = async (
	userId: string,
	ingredientsWishLists: IngredientsWishList[]
) => {
	const data = {
		userId,
		ingredientsWishLists: ingredientsWishLists,
	};
	//console.log("formatted todo to send >> ", todos);
	const url = `http://localhost:3000/api/saveRestaurantIngredientsWishList`;
	const res = await fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(data),
	});

	const GPTdata = await res.json();
	const { content } = GPTdata;
	return content;
};

export const fetchSaveRestaurantFavoriteIngredients = async (
	userId: string,
	favoriteIngredients: string[]
) => {
	const data = {
		userId,
		favoriteIngredients: favoriteIngredients,
	};
	//console.log("formatted todo to send >> ", todos);
	const url = `http://localhost:3000/api/saveRestaurantFavoriteIngredients`;
	const res = await fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(data),
	});

	const GPTdata = await res.json();
	const { content } = GPTdata;
	return content;
};

export const fetchSaveClientMenuProduct = async (
	restaurantId: string,
	newRestaurantClientMenuProducts: RestaurantMenuProduct[]
) => {
	const data = { restaurantId, menuProducts: newRestaurantClientMenuProducts };
	//console.log("formatted todo to send >> ", todos);
	const url = `http://localhost:3000/api/saveClientMenuProduct`;
	const res = await fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(data),
	});

	const GPTdata = await res.json();
	const { content } = GPTdata;
	return content;
};
export const fetchSaveClientMenuProductDispo = async (
	restaurantId: string,
	newRestaurantClientMenuProductsDispo: IngredientStock[]
) => {
	const data = {
		restaurantId,
		ingredientStock: newRestaurantClientMenuProductsDispo,
	};
	//console.log("formatted todo to send >> ", todos);
	const url = `http://localhost:3000/api/saveClientMenuProductDispo`;
	const res = await fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(data),
	});

	const GPTdata = await res.json();
	const { content } = GPTdata;
	return content;
};
export const fetchSaveTodos = async (todos: BoardCard[]) => {
	//console.log("formatted todo to send >> ", todos);
	const url = `http://localhost:3000/api/saveTodos`;

	const res = await fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(todos),
	});

	const GPTdata = await res.json();
	const { content } = GPTdata;
	return content;
};
export const fetchSaveLabels = async (labels: CardLabel[]) => {
	//console.log("formatted todo to send >> ", todos);
	const url = `http://localhost:3000/api/saveLabels`;

	const res = await fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(labels),
	});

	const GPTdata = await res.json();
	const { content } = GPTdata;
	return content;
};

export const fetchSaveUser = async (user: User) => {
	//console.log("formatted todo to send >> ", todos);
	const url = `http://localhost:3000/api/saveUser`;
	const res = await fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(user),
	});

	const GPTdata = await res.json();
	const { content } = GPTdata;
	return content;
};
export const fetchSaveColumns = async (columns: BoardColumn[]) => {
	//console.log("formatted todo to send >> ", board);
	const url = `http://localhost:3000/api/saveColumns`;
	const res = await fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(columns),
	});

	const GPTdata = await res.json();
	const { content } = GPTdata;
	return content;
};
export const fetchSaveBoard = async (board: Board) => {
	//console.log("formatted todo to send >> ", board);
	const url = `http://localhost:3000/api/saveBoard`;
	const res = await fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(board),
	});

	const GPTdata = await res.json();
	const { content } = GPTdata;
	return content;
};
export const fetchSaveMenus = async (board: Board) => {
	//console.log("formatted todo to send >> ", board);
	const url = `http://localhost:3000/api/saveBoard`;
	const res = await fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(board),
	});

	const GPTdata = await res.json();
	const { content } = GPTdata;
	return content;
};

export const fetchSaveInvoices = async (invoices: Invoice[]) => {
	//console.log("formatted todo to send >> ", board);
	const url = `http://localhost:3000/api/saveInvoices`;
	const res = await fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(invoices),
	});

	const GPTdata = await res.json();
	const { content } = GPTdata;
	return content;
};
export const fetchSaveRestaurants = async (restaurants: Restaurant[]) => {
	//console.log("formatted todo to send >> ", board);
	const url = `http://localhost:3000/api/saveRestaurants`;
	const res = await fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(restaurants),
	});

	const GPTdata = await res.json();
	const { content } = GPTdata;
	return content;
};

export const fetchSaveRooms = async (rooms: RestaurantRoom[]) => {
	//console.log("formatted todo to send >> ", board);
	const url = `http://localhost:3000/api/saveRooms`;
	const res = await fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(rooms),
	});
};

export const fetchSaveTablesDetails = async (tablesDetails: TableDetail[]) => {
	//console.log("formatted todo to send >> ", board);
	const url = `http://localhost:3000/api/saveTableDetails`;
	const res = await fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(tablesDetails),
	});

	const GPTdata = await res.json();
	const { content } = GPTdata;
	return content;
};

export const uploadImage = async (file: File) => {
	if (!file) return;

	const formData = new FormData();

	//formData.append("name", file, file.name);
	formData.append("file", file);
	const url = `http://localhost:3000/api/saveFile`;
	const res = await fetch(url, {
		method: "POST",
		headers: {
			//"Content-Type": "application/json"
			//"Content-Type": "multipart/form-data",
		},
		body: formData,
	});

	const image: types.Image = await res.json();

	return image;
};

export const downloadImage = async (fileName: string) => {
	if (!fileName) return null;

	const url = `http://localhost:3000/api/saveFile?fileName=${fileName}`;
	const res = await fetch(url, {
		method: "GET",
		headers: {
			fileName: fileName,
			//"Content-Type": "application/json"
			//"Content-Type": "multipart/form-data",
		},
		//body: JSON.stringify({ fileName: fileName }),
	});

	if (!res.ok) {
		throw new Error(`Erreur lors du téléchargement de l'image : ${res.status}`);
	}

	const imageBlob = await res.blob();
	return imageBlob;
};

export const wrapText = (text: string) => {
	let str = text;
	//let regex = /(?<=*)(.*?)(?=*)/;
	const regex = /^(.*)\n===============================/gm;
	const regex2 = /\n===============================/gm;
	let securityCount = 0;
	while (str.includes(`===============================`)) {
		const matched = regex.exec(str);
		if (matched) {
			const wrap =
				"<h1>" + matched[0].replace(regex2, "").replace("\n", "") + "</h1>";
			const tmp = str.replace(`${matched[0]}`, wrap);
			str = tmp;
		}
		securityCount++;
		if (securityCount > 50) break;
	}
	securityCount = 0;

	const regex3 = /\n/gm;
	while (str.includes(`\n`)) {
		const matched = regex3.exec(str);
		if (matched) {
			const wrap = matched[0].replace("\n", "<br/>");
			const tmp = str.replace(`${matched[0]}`, wrap);
			str = tmp;
		}
		securityCount++;
		if (securityCount > 50) break;
	}
	securityCount = 0;
	const regex4 = /<\/h1><br\/>/gm;
	while (str.includes(`</h1><br/>`)) {
		const matched = regex4.exec(str);
		if (matched) {
			const wrap = matched[0].replace("</h1><br/>", "</h1>");
			const tmp = str.replace(`${matched[0]}`, wrap);
			str = tmp;
		}
		securityCount++;
		if (securityCount > 50) break;
	}
	return str;
};

export const SortByName = (a: any, b: any, order?: "asc" | "desc") => {
	const _order = order && order === "desc" ? -1 : 1;
	if (a.name < b.name) {
		return -1 * _order;
	}
	if (a.name > b.name) {
		return 1 * _order;
	}
	return 0;
};

export const exportToPdf = (base64String: string, filename: string) => {
	const byteCharacters = atob(base64String);
	const byteNumbers = new Array(byteCharacters.length);
	for (let i = 0; i < byteCharacters.length; i++) {
		byteNumbers[i] = byteCharacters.charCodeAt(i);
	}
	const byteArray = new Uint8Array(byteNumbers);
	const pdfBlob = new Blob([byteArray], { type: "application/pdf" });
	const pdfUrl = URL.createObjectURL(pdfBlob);
	const downloadLink = document.createElement("a");
	downloadLink.href = pdfUrl;
	downloadLink.download = filename;
	document.body.appendChild(downloadLink);
	downloadLink.click();
	document.body.removeChild(downloadLink);
};

export function pdfToBase64(file: File): Promise<string> {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			const base64String = reader.result?.toString().split(",")[1];
			if (base64String) {
				resolve(base64String);
			} else {
				reject(new Error("Impossible de lire le fichier PDF."));
			}
		};
		reader.onerror = () => {
			reject(new Error("Erreur lors de la lecture du fichier PDF."));
		};
	});
}

export const getStringMemorySize = (str: string) => {
	let bytes = 0,
		len = str.length,
		codePoint,
		next,
		i;

	for (i = 0; i < len; i++) {
		codePoint = str.charCodeAt(i);

		// Lone surrogates cannot be passed to encodeURI
		if (codePoint >= 0xd800 && codePoint < 0xe000) {
			if (codePoint < 0xdc00 && i + 1 < len) {
				next = str.charCodeAt(i + 1);

				if (next >= 0xdc00 && next < 0xe000) {
					bytes += 4;
					i++;
					continue;
				}
			}
		}

		bytes += codePoint < 0x80 ? 1 : codePoint < 0x800 ? 2 : 3;
	}

	return bytes;
};

export const getShortStringMemorySizeOctet = (str: string) => {
	const octet = parseInt((getStringMemorySize(str) / 8).toString());
	if (octet.toString().length < 4) {
		return `${parseInt(octet.toString())} o`;
	} else if (octet.toString().length < 7) {
		return `${parseInt((octet / 1000).toString())} ko`;
	} else if (octet.toString().length < 10) {
		return `${parseInt((octet / 1000000).toString())} Mo`;
	} else {
		return `${parseInt((octet / 1000000000).toString())} Go`;
	}
};
export const getShortStringMemorySizeByte = (str: string) => {
	const octet = parseInt(getStringMemorySize(str).toString());
	if (octet.toString().length < 4) {
		return `${parseInt(octet.toString())} B`;
	} else if (octet.toString().length < 7) {
		return `${parseInt((octet / 1000).toString())} kB`;
	} else if (octet.toString().length < 10) {
		return `${parseInt((octet / 1000000).toString())} MB`;
	} else {
		return `${parseInt((octet / 1000000000).toString())} GB`;
	}
};

export const getAgeFromBDay = (dateNaissance: Moment) => {
	const patientAge = moment.duration(moment().diff(dateNaissance));

	const ageInDays = patientAge.asDays();
	const ageInMonths = patientAge.asMonths();
	const ageInYears = patientAge.asYears();

	let unitToShow = "";
	let ageToShow;
	let stade = 1;

	if (ageInYears > 1) {
		ageToShow = parseInt(ageInYears.toString());
		unitToShow = "ans";
		if (ageToShow > 2) {
			stade = 3;
		} else {
			stade = 2;
		}
	} else if (ageInMonths > 1) {
		ageToShow = parseInt(ageInMonths.toString());
		unitToShow = "mois";
		stade = 2;
	} else {
		ageToShow = parseInt(ageInDays.toString());
		unitToShow = "jours";
		stade = 1;
	}

	const ageStr = `${ageToShow} ${unitToShow}`;
	return {
		ageInDays,
		ageInMonths,
		ageInYears,
		ageStr,
	};
};

export const normalizeText = (text: string): string => {
	let normalizedText = "";
	for (let i = 0; i < text.length; i++) {
		const normalizedLetter = enums.letterCorrespondance[text[i]];
		if (normalizedLetter !== undefined) {
			normalizedText += normalizedLetter;
		} else {
			normalizedText += text[i];
		}
	}
	return normalizedText;
};
export const capitalize = (text: string) => {
	return text.charAt(0).toUpperCase() + text.slice(1);
};

export const removeDuplicates = (arr: any[]) => {
	return arr.filter((item, index) => arr.indexOf(item) === index);
};

export const tableIsFree = (status: enums.StatutTable) => {
	return (
		status === enums.StatutTable.Free ||
		status === enums.StatutTable.ToClear ||
		status === enums.StatutTable.Cleaning ||
		status === enums.StatutTable.OutOfService
	);
};

export const getMenuCategTraduction = (
	language: Language,
	categ: MenuCateg
) => {
	const newCateg: MenuCateg = {
		...categ,
		description:
			language === "fr"
				? categ.description_fr
				: language === "en"
				? categ.description_en
				: language === "es"
				? categ.description_es
				: categ.description_fr,
	};

	return newCateg;
};

export const getMenuProductTraduction = (
	language: Language,
	product?: MenuProduct
) => {
	if (!product) {
		const noProduct: MenuProduct = {
			...new MenuProduct(),
			label: "",
			ingredients: [],
			description: "",
		};
		return noProduct;
	}
	const newProduct: MenuProduct = {
		...product,
		description:
			language === "fr"
				? product.description_fr
				: language === "en"
				? product.description_en
				: language === "es"
				? product.description_es
				: product.description_fr,
		ingredients:
			language === "fr"
				? product.ingredients_fr
				: language === "en"
				? product.ingredients_en
				: language === "es"
				? product.ingredients_es
				: product.ingredients_fr,
		label:
			language === "fr"
				? product.label_fr ?? ""
				: language === "en"
				? product.label_en ?? ""
				: language === "es"
				? product.label_es ?? ""
				: product.label_fr ?? "",
	};

	return newProduct;
};

export const generateUniqueID = (params: {
	length?: number;
	prefix?: string;
	isDateUnique?: boolean;
}) => {
	const characters =
		"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	let id = params.prefix ?? "";

	for (let i = 0; i < (params.length ?? 10); i++) {
		const randomIndex = Math.floor(Math.random() * characters.length);
		id += characters.charAt(randomIndex);
	}

	if (params.isDateUnique) {
		const date = moment().format("DDMMYYYY");
		id += date;
	}
	return id;
};
export const generateUniqueNumericID = (params: {
	length?: number;
	isDateUnique?: boolean;
}) => {
	const characters = "0123456789";
	let id = "";

	for (let i = 0; i < (params.length ?? 10); i++) {
		const randomIndex = Math.floor(Math.random() * characters.length);
		id += characters.charAt(randomIndex);
	}
	if (params.isDateUnique) {
		const date = moment().format("DDMMYYYY");
		id += date;
	}
	return parseInt(id);
};
