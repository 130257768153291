import React, { useEffect, useState } from "react";
import RoundButton from "../components/buttons/RoundButton";
import { useAppSettingStore } from "../service/useAppSettingStore";
import { useClientStore } from "../service/useClientStore";
import { useParams } from "react-router-dom";
import * as types from "../lib/types";
import {
	Bars4Icon,
	GlobeAmericasIcon,
	NewspaperIcon,
	PhoneIcon,
	RectangleGroupIcon,
	SquaresPlusIcon,
	SunIcon,
	TagIcon,
	UserGroupIcon,
} from "@heroicons/react/24/solid";
import NavListMenu from "../components/nav/NavListMenu";
import ExpandMenu from "../svg/ExpandMenu";
import ReduceMenu from "../svg/ReduceMenu";
import RestaurantListFilters from "../components/restaurant/RestaurantListFilters";
import CustomDropDown from "@/components/buttons/CustomDropDown";
import {
	Avatar,
	Box,
	IconButton,
	Menu,
	MenuItem,
	Tooltip,
	Typography,
} from "@mui/material";
import UserMenu from "@/components/user/UserMenu";
import * as formatData from "@/lib/formatData";
import * as functions from "@/lib/functions";
import { useAuth } from "@/components/auth/AuthContext";

function AppPathWay() {
	const { currentUser } = useAuth();
	const params = useParams();
	const [isCollapsed, setIsCollapsed] = useAppSettingStore((state) => [
		state.isCollapsed,
		state.setIsCollapsed,
	]);
	const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
		null
	);
	const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
		null
	);

	const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElNav(event.currentTarget);
	};
	const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};
	//const [clients] = useClientStore((state) => [state.clients]);
	//const [currClient, setCurrClient] = useState<types.Client | undefined>();
	/*
	useEffect(() => {
		setCurrClient(clients.find((cli) => cli.id.toString() === params.clientId));
	}, [params.clientId]);
    */

	const navListMenuItems = [
		{
			title: "Products",
			description: "Find the perfect solution for your needs.",
			icon: SquaresPlusIcon,
		},
		{
			title: "About Us",
			description: "Meet and learn about our dedication",
			icon: UserGroupIcon,
		},
		{
			title: "Blog",
			description: "Find the perfect solution for your needs.",
			icon: Bars4Icon,
		},
		{
			title: "Services",
			description: "Learn how we can help you achieve your goals.",
			icon: SunIcon,
		},
		{
			title: "Support",
			description: "Reach out to us for assistance or inquiries",
			icon: GlobeAmericasIcon,
		},
		{
			title: "Contact",
			description: "Find the perfect solution for your needs.",
			icon: PhoneIcon,
		},
		{
			title: "News",
			description: "Read insightful articles, tips, and expert opinions.",
			icon: NewspaperIcon,
		},
		{
			title: "Products",
			description: "Find the perfect solution for your needs.",
			icon: RectangleGroupIcon,
		},
		{
			title: "Special Offers",
			description: "Explore limited-time deals and bundles",
			icon: TagIcon,
		},
	];
	if (!currentUser) {
		return <></>;
	}
	const avatar =
		currentUser.displayName ??
		currentUser.email.split("@")[0].replace(".", " ");
	return (
		<div className="flex w-full h-14 p-2 items-center bg-[#6D93CC] gap-2  border-b-[1px] border-b-[#254374]/40 text-white text-sm ">
			{/* <RoundButton
				className="w-10 h-10"
				onClick={() => {
					setIsCollapsed(!isCollapsed);
				}}
			>
				{isCollapsed ? (
					<ExpandMenu className="h-6 w-6" />
				) : (
					<ReduceMenu className="h-6 w-6" />
				)}
			</RoundButton> */}
			<div className="flex flex-row w-full justify-between items-center">
				<div className="">
					<div className=""></div>
					Title
				</div>
				<div className="flex flex-row">
					<Box sx={{ flexGrow: 0 }}>
						<Tooltip title="Open settings">
							<div
								className="grid grid-cols-[auto_1fr] items-center gap-2"
								onClick={handleOpenUserMenu}
							>
								<IconButton sx={{ p: 0 }}>
									<Avatar
										{...functions.stringAvatar(avatar)}
										sx={{ width: 42, height: 42 }}
									/>
								</IconButton>

								<div className="widget-content-left ml-0 header-user-info center aLeft">
									<div className="widget-heading">{avatar}</div>
									<div className="widget-subheading">
										{currentUser.function}
									</div>
								</div>
							</div>
						</Tooltip>
						<Menu
							sx={{ mt: "45px" }}
							id="menu-appbar"
							anchorEl={anchorElUser}
							anchorOrigin={{
								vertical: "top",
								horizontal: "right",
							}}
							keepMounted
							transformOrigin={{
								vertical: "top",
								horizontal: "right",
							}}
							open={Boolean(anchorElUser)}
							onClose={handleCloseUserMenu}
						>
							<UserMenu />
						</Menu>
					</Box>
				</div>
			</div>
		</div>
	);
}

export default AppPathWay;
