import { generateUniqueID } from "@/lib/functions";
import { TodoItem } from "@/lib/todo-types";
import { Moment } from "moment";
import { create } from "zustand";

import {
	doc,
	onSnapshot,
	getDocs,
	where,
	addDoc,
	collection,
	query,
	updateDoc,
	deleteDoc,
	db,
} from "@/firebase/BaseConfig";
import * as enums from "@/lib/enums";

interface StoreState {
	displayView: string;
	takingView: string;
	groupBy: string;
	timeInterval: string;
	setDisplayView: (displayView: string) => void;
	setTakingView: (takingView: string) => void;
	setGroupBy: (groupBy: string) => void;
	setTimeInterval: (timeInterval: string) => void;
}

export const useReportStore = create<StoreState>()((set, get) => ({
	displayView: enums.REPORT_FILTER_VIEW_CLASSIC,
	takingView: enums.REPORT_FILTER_TAKINGSVIEW_DETAILS,
	groupBy: enums.REPORT_FILTER_GROUPBY_ACCOUNTINGGROUPS,
	timeInterval: enums.REPORT_FILTER_TIME_INTERVAL_ALLDAY,
	setDisplayView: (displayView: string) => {
		set({ displayView });
	},
	setTakingView: (takingView: string) => {
		set({ takingView });
	},
	setGroupBy: (groupBy: string) => {
		set({ groupBy });
	},
	setTimeInterval: (timeInterval: string) => {
		set({ timeInterval });
	},
}));
