import React from "react";
import PageContent from "../../PageContent";
import { ReportCardsData } from "@/lib/invoice-types";
import ReportCard from "@/components/rapports/ReportCard";
import { Card } from "@material-tailwind/react";
import SubSectionTitle from "@/components/sections/SubSectionTitle";
import SectionTitle from "@/components/sections/SectionTitle";
import { useParams } from "react-router-dom";
import ClassicReportByDepartement from "@/components/rapports/ClassicReportByDepartement";
import RapportOperationnel from "@/components/rapports/RapportOperationnel";

function Rapports() {
	const params = useParams();
	const reportCardsData: ReportCardsData[] = [
		{
			title: "Rapports de ventes",
			description:
				"Consultez les rapports affichant des données nettes et brutes.",
			items: [
				{ label: "Rapports opérationnels", link: "rapports/classic" },
				{ label: "Rapport des produits", link: "" },
				{ label: "Rapport de la gamme de produits", link: "" },
				{ label: "Rapport des périodes horaires", link: "" },
				{ label: "Rapport des services", link: "" },
				{ label: "Rapports des caisses", link: "" },
				{ label: "Statistiques horaires", link: "" },
				{ label: "Rapport des messages texte", link: "" },
				{
					label: "Statistiques de l'écran d'affichage cuisine (KDS)",
					link: "",
				},
			],
		},
		{
			title: "Rapports d'établissement",
			description:
				"Consultez les rapports affichant les données consolidées de vos différents établissements.",
			items: [
				{ label: "Rapports opérationnels consolidés", link: "" },
				{ label: "Rapports des produits consolidés", link: "" },
				{ label: "Rabais consolidés par article", link: "" },
				{ label: "Rapports des périodes horaires consolidés", link: "" },
				{ label: "Résumé fiscal consolidé", link: "" },
				{ label: "Rapport fiscal consolidé", link: "" },
			],
		},
		{
			title: "Rapports fiscaux",
			description: "Consultez les rapports affichant des données fiscales.",
			items: [
				{ label: "Résumé fiscal", link: "" },
				{ label: "Rapport fiscal", link: "" },
				{ label: "Rapport des périodes horaires", link: "" },
				{ label: "Cash Drawer Report", link: "" },
				{ label: "Clôture fiscale", link: "" },
			],
		},
		{
			title: "Rapport des services",
			description:
				"Consultez les rapports affichant des données sur les services et les ventes du personnel.",
			items: [
				{ label: "Labor Report", link: "" },
				{ label: "Chiffre d'affaires du personnel", link: "" },
				{ label: "Chiffre d'affaires global", link: "" },
				{ label: "Chiffre d'affaires mensuel", link: "" },
			],
		},
		{
			title: "Rapports de comptes",
			description: "Consultez les rapports de comptes.",
			items: [
				{ label: "Rapport des tickets", link: "" },
				{ label: "Rapport des tickets intermédiaires", link: "" },
				{ label: "Tous les rapports de comptes", link: "" },
				{ label: "Comptes ouverts", link: "" },
				{ label: "Comptes annulés", link: "" },
			],
		},
		// ...autres cartes de rapport
	];

	return (
		<PageContent>
			<div className="grid grid-rows-[auto_1fr] w-full h-full overflow-hidden">
				{!params.categValue && (
					<>
						<SectionTitle>Rapports</SectionTitle>
						<div className="grid grid-cols-2 w-full h-full overflow-y-auto gap-5 p-5">
							{reportCardsData.map((card, index) => (
								<ReportCard
									key={index}
									title={card.title}
									description={card.description}
									items={card.items}
								/>
							))}
						</div>
					</>
				)}
				{params.categValue && params.categValue === "classic" && (
					<>
						<RapportOperationnel />
					</>
				)}
			</div>
		</PageContent>
	);
}

export default Rapports;
