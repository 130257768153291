import {
	Board,
	BoardCard,
	CardLabel,
	BoardColumn,
	newEmptyBoardCard,
} from "@/lib/board-types";
import * as funcs from "@/lib/funcs";

import * as types from "@/lib/types";
import { ReactNode } from "react";
import { create } from "zustand";

interface ModalState {
	//[x: string]: BoardCard | undefined;
	isOpen: boolean;
	todo?: BoardCard;
	currColumn?: BoardColumn;
	openModal: (todo?: BoardCard, currColumn?: BoardColumn) => void;
	closeModal: () => void;
	isOpenDetail: boolean;
	detailNode?: ReactNode;
	openModalDetail: (children: ReactNode) => void;
	closeModalDetail: () => void;
	board?: Board;
	boards?: Board[];
	lists?: BoardColumn[];
	cards?: BoardCard[];
	labels?: CardLabel[];
	selectedLabel: CardLabel;
	searchString: string;
	newTaskInput: string;
	newTaskType: string;
	image: File | null;
	selectedColumnForChange?: BoardColumn;
	setSelectedLabel: (selectedLabel: CardLabel) => void;
	setBoard: (board: Board) => void;
	updateBoardLists: (lists: BoardColumn[]) => void;
	setBoards: (boards: Board[]) => void;
	saveLabels: () => void;
	setSelectedColumnForChange: (selectedColumnForChange: BoardColumn) => void;
	deleteSelectedLabel: () => void;
	moveCard: (cardMovement: CardMouvement) => void;
	setNewTaskInput: (input: string) => void;
	setSearchString: (searchString: string) => void;
	setNewTaskType: (type: string) => void;
	setImage: (image: File | null) => void;
	addTask: (
		todo: string,
		boardId: string,
		columnId: string,
		image?: File | null
	) => void;
}

export const useModalStore = create<ModalState>()((set, get) => ({
	newTaskInput: "",
	searchString: "",
	newTaskType: "",
	image: null,
	isOpen: false,
	openModal: (todo?: BoardCard, currColumn?: BoardColumn) =>
		set({ isOpen: true, todo, currColumn }),
	closeModal: () =>
		set({ isOpen: false, todo: undefined, currColumn: undefined }),

	isOpenDetail: false,
	openModalDetail: (children: ReactNode) => {
		set({ detailNode: children, isOpenDetail: true });
	},
	closeModalDetail: () => {
		set({ detailNode: undefined, isOpenDetail: false });
	},
	selectedLabel: new CardLabel(),
	setBoard: (board: Board) =>
		set({
			board,
			lists: board.config?.lists,
			cards: board.config?.cards,
			labels: board.config?.labels,
		}),
	setBoards: (boards: Board[]) => set({ boards }),
	updateBoardLists: (lists: BoardColumn[]) => {
		const currBoard = get().board!;
		const currBoards = get().boards;
		const newBoard: Board = {
			...currBoard,
			config: {
				...(currBoard.config ?? { lists: [], cards: [], labels: [] }),
				lists: lists,
			},
		};

		const newBoards = currBoards?.map((brdz) => {
			if (brdz.id === newBoard.id) {
				return newBoard;
			} else {
				return brdz;
			}
		});

		set({
			board: newBoard,
			boards: newBoards,
			lists: newBoard.config?.lists,
			cards: newBoard.config?.cards,
			labels: newBoard.config?.labels,
		});
		//fetchSaveColumns(lists);
	},
	setSelectedLabel: (selectedLabel: CardLabel) => set({ selectedLabel }),
	saveLabels: () => {
		const brd = get().board!;
		const lbl = get().selectedLabel;
		const lblz = get().labels ?? [];
		if (!lbl || lbl.name === "" || lbl.color === "") {
			return;
		}
		const newList: CardLabel[] = [];
		if (lbl.id) {
			lblz.map((lab) => {
				if (lab.id === lbl.id) {
					newList.push(lbl);
				} else {
					newList.push(lab);
				}
			});
		} else {
			newList.push(...lblz, {
				...lbl,
				id: new Date().toISOString(),
				idBoard: brd.id,
			});
		}
		set({ labels: newList });
		//fetchSaveLabels(newList);
	},
	deleteSelectedLabel: () => {
		const brd = get().board!;
		const lbl = get().selectedLabel;
		const lblz = get().labels ?? [];
		if (!lbl || !lbl.id) {
			return;
		}
		const newList: CardLabel[] = lblz.filter((lab) => {
			return lab.id !== lbl.id;
		});

		set({ labels: newList });
		//fetchSaveLabels(newList);
	},
	setSelectedColumnForChange: (selectedColumnForChange: BoardColumn) => {
		set({ selectedColumnForChange });
	},

	moveCard: (cardMovement: CardMouvement) => {
		const currCard = {
			...get().todo!,
			idBoard: cardMovement.idBoard,
			idList: cardMovement.idList,
		}!;
		const currBoard = get().board;
		const currBoards = get().boards;
		const newCards: BoardCard[] =
			(get().cards ?? [])?.map((crd, index) => {
				const isCurrent = currCard?.id === crd.id;
				return isCurrent ? currCard! : crd;
			}) ?? [];
		const movedCardList: BoardCard[] = [];

		newCards
			.filter((card) => {
				return card.idList === cardMovement.idList && card.id !== currCard?.id;
			})
			.map((card, index) => {
				if (index === cardMovement.position) {
					movedCardList.push(currCard);
				}
				movedCardList.push(card);
			});

		const newConfig = { ...currBoard?.config };
		const newConfigCards = [
			...newCards.filter((card) => {
				return card.idList !== cardMovement.idList;
			}),
			...movedCardList,
		];
		newConfig.cards = newConfigCards;
		const newBoard: Board = { ...currBoard, config: newConfig } as Board;
		const newBoards: Board[] = (currBoards?.map((brd) => {
			return brd.id !== currBoard?.id ? brd : newBoard;
		}) ?? []) as Board[];

		set({ board: newBoard, boards: newBoards, cards: newConfigCards });
		//fetchSaveTodos(newConfigCards);
	},
	setNewTaskInput: (input: string) => set({ newTaskInput: input }),
	setSearchString: (searchString: string) => set({ searchString }),
	setNewTaskType: (type: string) => set({ newTaskType: type }),
	setImage: (image: File | null) => set({ image }),
	addTask: async (
		todo: string,
		boardId: string,
		columnId: string,
		image?: File | null
	) => {
		let file: types.Image | undefined;
		let newItem: BoardCard;
		const currBoard = get().board!;
		const currBoards = get().boards;

		//debugger;
		console.log(todo);
		if (image) {
			const fileUploaded = await funcs.uploadImage(image);
			if (fileUploaded) {
				file = { bucketId: fileUploaded.bucketId, fileId: fileUploaded.fileId };
			}
			newItem = {
				...newEmptyBoardCard,
				id: new Date().getTime().toString(),
				name: todo,
				idBoard: boardId,
				idList: columnId,
				...(file && { image: JSON.stringify(file) }),
			};
			//$id = file?.fileId;
		}
		const id = new Date().getTime().toString();

		//create document

		const newCards: BoardCard[] = currBoard.config?.cards ?? [];

		const newTodo: BoardCard = {
			...newEmptyBoardCard,
			id,
			//dateCreated: new Date().toISOString(),
			name: todo,
			idBoard: boardId,
			idList: columnId,
			//status: columnId,
			//active: true,
			...(file && { image: JSON.stringify(file) }),
		};
		newCards.push(newTodo);
		set({
			newTaskInput: "",
			cards: newCards,
			board: {
				...currBoard,
				config: {
					...(currBoard.config ?? { lists: [], cards: [], labels: [] }),
					cards: newCards,
				},
			},
		});

		//fetchSaveTodos(newCards);
	},
}));

interface CardMouvement {
	idBoard: string;
	idList: string;
	position: number;
}
