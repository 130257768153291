import { Button } from "@material-tailwind/react";
import { useReportStore } from "_services/useReportStore";
import React from "react";
import * as enums from "@/lib/enums";

function ReportTimeIntervalFilters() {
	const [timeInterval, setTimeInterval] = useReportStore((state) => [
		state.timeInterval,
		state.setTimeInterval,
	]); // Fonctions pour changer les filtres
	const changerPlageHoraire = (nouvellePlage: string) =>
		setTimeInterval(nouvellePlage);
	const buttons = [
		{
			label: <>Journée entière</>,
			type: enums.REPORT_FILTER_TIME_INTERVAL_ALLDAY,
		},
		{
			label: (
				<>
					<div className="">Matin</div>
					<div className="text-2xs">05h30-11h29</div>
				</>
			),
			type: enums.REPORT_FILTER_TIME_INTERVALMORNING,
		},
		{
			label: (
				<>
					<div className="">Déjeuner</div>
					<div className="text-2xs">11h30-15h59</div>
				</>
			),
			type: enums.REPORT_FILTER_TIME_INTERVAL_DAY,
		},
		{
			label: (
				<>
					<div className="">Café</div>
					<div className="text-2xs">16h00-19h59</div>
				</>
			),
			type: enums.REPORT_FILTER_TIME_INTERVAL_EVENING,
		},
		{
			label: (
				<>
					<div className="">Diner</div>
					<div className="text-2xs">20h00-01h29</div>
				</>
			),
			type: enums.REPORT_FILTER_TIME_INTERVAL_NIGHT,
		},
		{
			label: (
				<>
					<div className="">Nuit</div>
					<div className="text-2xs">1h30-05h29</div>
				</>
			),
			type: enums.REPORT_FILTER_TIME_INTERVAL_LATENIGHT,
		},
	];

	return (
		<div className="flex flex-col">
			<div className="">Plage horaire du rapport:</div>
			<div className="flex flex-row gap-1">
				{buttons.map((button, index) => {
					return (
						<Button
							key={index}
							onClick={() => changerPlageHoraire(button.type)}
							className={`text-xs capitalize font-normal px-4 py-2 rounded-md text-gray-800 ${
								timeInterval === button.type
									? "bg-blue-600/50 font-bold"
									: "bg-gray-300/50 font-normal"
							}`}
						>
							{button.label}
						</Button>
					);
				})}
			</div>
		</div>
	);
}

export default ReportTimeIntervalFilters;
