/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
//import { labels } from "./labels";
import * as types from "./types";

export const CALENDAR_MODE_DAY = 0;
export const CALENDAR_MODE_WEEK = 1;
export const CALENDAR_MODE_MONTH = 2;
export const CALENDAR_MODE_YEAR = 3;

export const CAMPAGNE_DATA_FILTER_ALL = 0;
export const CAMPAGNE_DATA_FILTER_USER = 1;
export const CAMPAGNE_DATA_FILTER_COUNTRY = 2;
export const CAMPAGNE_DATA_FILTER_FORMAT = 3;
export const CAMPAGNE_DATA_FILTER_HIGHTSCORE = 4;
export const CAMPAGNE_DATA_FILTER_TOTALSCORE = 5;

export const TODO_ITEM_NEW = 0;
export const TODO_ITEM_CLOSED = 1;
export const TODO_ITEM_DELETED = 2;

export const CODE_500 = -500;

export const FILE_TYPE_CSV = "csv";
export const FILE_TYPE_JSON = "json";

export const COLOR_TYPE_PRIMARY = "primary";
export const COLOR_TYPE_SECONDARY = "secondary";
export const COLOR_TYPE_SUCCESS = "success";
export const COLOR_TYPE_INFO = "info";
export const COLOR_TYPE_WARNING = "warning";
export const COLOR_TYPE_DANGER = "danger";
export const COLOR_TYPE_LIGHT = "light";
export const COLOR_TYPE_DARK = "dark";

export const GAMESTATUS_SPLASH = 0;
export const GAMESTATUS_START = 1;
export const GAMESTATUS_OVER = 2;

export const TRASH_TYPE_ALL = -1;
export const TRASH_TYPE_YELLOW = 0;
export const TRASH_TYPE_BROWN = 1;
export const TRASH_TYPE_BLUE = 2;
export const TRASH_TYPE_GREEN = 3;
export const TRASH_TYPE_RED = 4;

export const GAME_MODE_EASY = 0;
export const GAME_MODE_NORMAL = 1;
export const GAME_MODE_HARD = 2;

export const SCORE_TYPE_SUPERMODE = 0;
export const SCORE_TYPE_SUCCESS = 1;
export const SCORE_TYPE_LOOSE = 2;

export const INTRUCTION_PAGE_INTRO_1 = 0;
export const INTRUCTION_PAGE_INTRO_2 = 1;
export const INTRUCTION_PAGE_CONFIG = 2;

export const MOBILE_TYPE_IPHONE = 0;
export const MOBILE_TYPE_ANDROID = 1;
export const MOBILE_TYPE_WINDOWS = 2;
export const MOBILE_TYPE_BBERRY = 3;
export const MOBILE_TYPE_IPAD = 4;
export const MOBILE_TYPE_IPOD = 5;
export const MOBILE_TYPE_MINI = 5;
export const MOBILE_TYPE_PALM = 5;
export const MOBILE_TYPE_OTHER = 5;

export const CAMPAGNE_CITEO_STAT = 1;
export const CAMPAGNE_CITEO_CHARITY = 3;

export const NB_POINT_TO_REACH = 10000;
export const NB_EUROS_TO_REACH = 10000;

export const REDUCE_PINS_ON = "REDUCE_PINS_ON";
export const REDUCE_PINS_OFF = "REDUCE_PINS_OFF";

export const TASK_ACTION_TAG_MEMBERS = "members";
export const TASK_ACTION_TAG_LABEL = "label";
export const TASK_ACTION_TAG_CHECKLIST = "checklist";
export const TASK_ACTION_TAG_DATES = "dates";
export const TASK_ACTION_TAG_ATTACHMENT = "attachment";
export const TASK_ACTION_TAG_IMAGE = "image";
export const TASK_ACTION_TAG_CUSTOM = "custom";

export const TASK_TEXT = {
	TASK_TEXT_MEMBERS: { title: "Membres", icon: "icon-member" },
	TASK_TEXT_LABEL: { title: "Étiquettes", icon: "icon-label" },
	TASK_TEXT_CHECKLIST: { title: "checklist", icon: "icon-checklist" },
	TASK_TEXT_DATES: { title: "Dates", icon: "icon-clock" },
	TASK_TEXT_ATTACHMENT: { title: "Pièce jointe", icon: "icon-attachment" },
	TASK_TEXT_IMAGE: { title: "Image de couverture", icon: "icon-card-cover" },
	TASK_TEXT_CUSTOM: {
		title: "Champs personnalisés",
		icon: "icon-custom-field",
	},
};

export const TASK_ACTION_TYPE_BUTTON = "button-link";
export const TASK_ACTION_TYPE_LINK = "link";
export const TASK_ACTION_TYPE_ADD = "add_button";
export const TASK_ACTION_TYPE_DELETE = "delete_button";

export const ICON_SIZE_SMALL = "icon-sm";
export const ICON_SIZE_MEDIUM = "icon-md";
export const ICON_SIZE_LARGE = "icon-lg";

export const CARD_LABEL_TYPE_GREEN = "card-label-green";
export const CARD_LABEL_TYPE_YELLOW = "card-label-yellow";
export const CARD_LABEL_TYPE_ORANGE = "card-label-orange";
export const CARD_LABEL_TYPE_RED = "card-label-red";
export const CARD_LABEL_TYPE_PURPLE = "card-label-purple";
export const CARD_LABEL_TYPE_BLUE = "card-label-blue";
export const CARD_LABEL_TYPE_SKY = "card-label-sky";
export const CARD_LABEL_TYPE_LIME = "card-label-lime";
export const CARD_LABEL_TYPE_PINK = "card-label-pink";
export const CARD_LABEL_TYPE_BLACK = "card-label-black";
export const CARD_LABEL_TYPE_NONE = "edit-labels-no-color-section";

export const ACTIVITY_TYPE_ACTION_ADD_LABEL = "add-label";
export const ACTIVITY_TYPE_ACTION_ADD_CARD = "add-card";
export const ACTIVITY_TYPE_ACTION_ADD_LIST = "add-list";
export const ACTIVITY_TYPE_ACTION_ADD_DUE_DATE = "add-due-date";
export const ACTIVITY_TYPE_ACTION_ARCHIVE_CARD = "";
export const ACTIVITY_TYPE_ACTION_COMMENT_CARD = "comment-card";
export const ACTIVITY_TYPE_ACTION_DELETE_ATTACHMENT = "DeleteAttachment";
export const ACTIVITY_TYPE_ACTION_DONE_TASK = "DoneTask";
export const ACTIVITY_TYPE_ACTION_DUE_DATE_INCOMPLETE = "due-date-incomplete";
export const ACTIVITY_TYPE_ACTION_DUE_DATE_COMPLETE = "due-date-complete";
export const ACTIVITY_TYPE_ACTION_IMAGE_ATTACHMENT = "ImageAttachment";
export const ACTIVITY_TYPE_ACTION_LINKED = "Linked";
export const ACTIVITY_TYPE_ACTION_USER_REACHED = "UserReached";
export const ACTIVITY_TYPE_ACTION_MOVE = "move";
export const ACTIVITY_TYPE_ACTION_MOVE_TO = "MoveTo";
export const ACTIVITY_TYPE_ACTION_MOVE_CARD = "move-card";
export const ACTIVITY_TYPE_ACTION_COPY_TO_LIST4 = "copy";
export const ACTIVITY_TYPE_ACTION_COPY_CARD = "copy-card";
export const ACTIVITY_TYPE_ACTION_JOIN_CARD = "join-to-card";
export const ACTIVITY_TYPE_ACTION_REMOVE_LABEL = "remove-label";
export const ACTIVITY_TYPE_ACTION_UPDATE_DESCRIPTION = "update-description";
export const ACTIVITY_TYPE_ACTION_RENAME = "rename";
export const ACTIVITY_TYPE_ACTION_REMOVE_EXPIRATION_DATE = "remove-expire-date";

export enum APIStatus {
	IDLE,
	PENDING,
	REJECTED,
	FULFILLED,
	ABORDED,
}

export const MEETING_STATUS_RENDEZVOUS = 0;
export const MEETING_STATUS_SANSRENDEZVOUS = 1;
export const MEETING_STATUS_URGENCE = 2;
export const MEETING_STATUS_REMPLA = 3;
export const MEETING_STATUS_CANCEL = 4;
export const MEETING_STATUS_ENDED = 5;
export const MEETING_STATUS_ABS = 6;
export const MEETING_STATUS_LATE = 7;
export const MEETING_STATUS_EMPTY_URGENCE = 8;
export const MEETING_STATUS_EMPTY = 9;

export const getMeetingStrStatus = (intStatus: number) => {
	switch (intStatus) {
		case MEETING_STATUS_RENDEZVOUS:
			return "Rendez-vous pris.";
		case MEETING_STATUS_SANSRENDEZVOUS:
			return "Sans rendez-vous.";
		case MEETING_STATUS_URGENCE:
			return "Créneau d'urgence.";
		case MEETING_STATUS_REMPLA:
			return "Vu par remplaçant.";
		case MEETING_STATUS_CANCEL:
			return "Rendez-vous annulé.";
		case MEETING_STATUS_ENDED:
			return "Consultation terminée.";
		case MEETING_STATUS_ABS:
			return "Patient absent.";
		case MEETING_STATUS_LATE:
			return "Patient en retard.";
		case MEETING_STATUS_EMPTY_URGENCE:
			return "Créneau d'urgence non attribué.";
		case MEETING_STATUS_EMPTY:
			return "Créneau non attribué.";
	}
	return "Non définit.";
};

export const MEETING_CATEG_RDV = 0;
export const MEETING_CATEG_NOTE = 1;
export const MEETING_CATEG_RAPPEL = 2;

export const TASK_TYPE_URGENT = "urgent";
export const TASK_TYPE_MEMO = "memo";
export const TASK_TYPE_NORMAL = "other";

export const TASK_INPUT_TYPE_CHECKBOX = "checkbox";
export const TASK_INPUT_TYPE_LABEL = "label";
export const TASK_INPUT_TYPE_NORMAL = "other";

export const TASK_STATUS_NEW = 0;
export const TASK_STATUS_DONE = 1;
export const TASK_STATUS_DISABLED = 2;

export const STAT_TYPE_PATIENTS = 0;
export const STAT_TYPE_REGLEMENTS = 1;

export const GRAPH_TYPE_DAY = "reglementDaySort";
export const GRAPH_TYPE_MONTH = "reglementMonthSort";
export const GRAPH_TYPE_YEAR = "reglementYear";

export const MEETING_TYPE_RETROCESSION = 4;
export const MEETING_TYPE_CONSULTATIONGENERALE = 13;
export const MEETING_TYPE_REMPLA = 3;

export const AGENCY = 2;

export const TYPE_CABINET_ONGLET_ACTIVITY = "0";
export const TYPE_CABINET_ONGLET_INFO = "1";
export const TYPE_CABINET_ONGLET_PLANNING = "2";
export const TYPE_CABINET_ONGLET_CONGES = "3";
export const TYPE_CABINET_ONGLET_SUBVENTIONS = "4";

export const TYPE_REGLEMENT_TITULAIRE = "0";
export const TYPE_REGLEMENT_REMPLACANT = "1";
export const TYPE_REGLEMENT_REVENU = "2";

export const TYPE_REMPLA_REMPLACEMENT = 0;
export const TYPE_REMPLA_GARDE = 1;
export const TYPE_REMPLA_CONGES = 2;

export const TYPE_GRAPH_PATIENT = 0;
export const TYPE_GRAPH_REVENU = 1;

export const TYPE_FILTER_CONTACT = 0;
export const TYPE_FILTER_RDV = 1;

export const TYPE_ORDER_AZ = 0;
export const TYPE_ORDER_AZ_DESC = 1;
export const TYPE_ORDER_DATE = 2;
export const TYPE_ORDER_DATE_DESC = 3;

export const TYPE_SORTBY_MEETING_DATE = "dateRdv";
export const TYPE_SORTBY_MEETING_FIRSTNAME = "first_name";
export const TYPE_SORTBY_MEETING_LASTNAME = "last_name";

export const TYPE_METHOD_POST = "POST";
export const TYPE_METHOD_GET = "GET";

export const newGroupedList: types.IGroupedList = {
	keyz: [],
	list: [],
	count: 0,
};

export const TAB_ORIENTATION_COLS = "tab-orientation-col";
export const TAB_ORIENTATION_ROWS = "tab-orientation-row";

export const MEETING_ACT_GS = "GS";
export const MEETING_ACT_G = "G";
export const MEETING_ACT_GS_ALQP003 = "GS+ALQP003";
export const MEETING_ACT_GS_CAGD001 = "GS+CAGD001";
export const MEETING_ACT_GS_DEQP003 = "GS+DEQP003";
export const MEETING_ACT_GS_JKHD001 = "GS+JKHD001";
export const MEETING_ACT_GS_MEG = "GS+MEG";
export const MEETING_ACT_GS_MIC = "GS+MIC";
export const MEETING_ACT_GS_MSH = "GS+MSH";
export const MEETING_ACT_GS_MTX = "GS+MTX";
export const MEETING_ACT_GS_QZJA002 = "GS+QZJA002";
export const MEETING_ACT_G_DEQP003 = "G+DEQP003";
export const MEETING_ACT_G_JKHD001 = "G+JKHD001";
export const MEETING_ACT_G_MEG = "G+MEG";
export const MEETING_ACT_G_MSH = "G+MSH";
export const MEETING_ACT_G_MTX = "G+MTX";
export const MEETING_ACT_ALQP003 = "ALQP003";
export const MEETING_ACT_CCP = "CCP";
export const MEETING_ACT_COB = "COB";
export const MEETING_ACT_COD = "COD";
export const MEETING_ACT_COE = "COE";
export const MEETING_ACT_DEQP003_G = "DEQP003+G";
export const MEETING_ACT_GLQP007 = "GLQP007";
export const MEETING_ACT_GLQP007_PAV = "GLQP007+PAV";
export const MEETING_ACT_JKKD001 = "JKKD001";
export const MEETING_ACT_QZGA002 = "QZGA002";
export const MEETING_ACT_TCG = "TCG";
export const MEETING_ACT_VG_MD = "VG+MD";
export const MEETING_ACT_VL_MD = "VL+MD";
export const MEETING_ACT_VG_MU = "VG+MU";

export const CALENDAR_TYPE_DAY = "day";
export const CALENDAR_TYPE_WEEK = "week";
export const CALENDAR_TYPE_MONTH = "month";
export const CALENDAR_TYPE_YEAR = "year";
export const CALENDAR_TYPE_RDV = "rdv";

export const TYPE_MENU_HOME = "home";
export const TYPE_MENU_REMPLA = "rempla";
export const TYPE_MENU_COMPTA = "compta";
export const TYPE_MENU_ANAMNESE = "anamnese";
export const TYPE_MENU_POSTUROLOGY = "posturology";
export const TYPE_MENU_PODOLOGY = "podology";
export const TYPE_MENU_PEDICURY = "pedicury";
export const TYPE_MENU_BILANS = "bilans";
export const TYPE_MENU_SETTINGS = "setting";

export const TYPE_SUBMENU_HOME_DASHBOARD = "home_dashboard";
export const TYPE_SUBMENU_HOME_CAL_WEEK = "calendar_week";
export const TYPE_SUBMENU_HOME_CAL_MONTH = "calendar_month";
export const TYPE_SUBMENU_HOME_CAL_RDV = "calendar_rendezvous";
export const TYPE_SUBMENU_HOME_PATIENTS = "home_patients";
export const TYPE_SUBMENU_HOME_LISTEPATIENTS = "home_liste_patients";
export const TYPE_SUBMENU_HOME_PLANNING = "planning";
export const TYPE_SUBMENU_HOME_CONGES = "conges";
export const TYPE_SUBMENU_REMPLA_REGLEMENTS = "rempla_reglements";
export const TYPE_SUBMENU_REMPLA_STATS = "rempla_stats";
export const TYPE_SUBMENU_REMPLA_SIMULATIONS = "rempla_simulations";
export const TYPE_SUBMENU_REMPLA_CABINET = "rempla_cabinet";
export const TYPE_SUBMENU_COMPTA_REPARTITION = "compta_repartition";
export const TYPE_SUBMENU_COMPTA_CAMEMBERT = "compta_camembert";
export const TYPE_SUBMENU_COMPTA_PAIEMENTS = "compta_paiement";
export const TYPE_SUBMENU_COMPTA_PAIEMENT = "compta_Paiement";
export const TYPE_SUBMENU_COMPTA_SANSPAIEMENT = "sans_paiement";
export const TYPE_SUBMENU_ANAMNESE_PATHOLOGIES = "anamnese_pathologies";
export const TYPE_SUBMENU_ANAMNESE_SYMPTOMES = "anamnese_symptomes";
export const TYPE_SUBMENU_ANAMNESE_DOULEURS = "anamnese_douleurs";
export const TYPE_SUBMENU_ANAMNESE_ORDONNANCES = "anamnese_ordonnances";
export const TYPE_SUBMENU_ANAMNESE_PHOTO = "anamnese_photo";
export const TYPE_SUBMENU_ANAMNESE_NOTE = "anamnese_note";
export const TYPE_SUBMENU_POSTUROLOGY_AVANT = "posturology_avant";
export const TYPE_SUBMENU_POSTUROLOGY_ARRIERE = "posturology_arriere";
export const TYPE_SUBMENU_POSTUROLOGY_PROFIL = "posturology_profil";
export const TYPE_SUBMENU_POSTUROLOGY_TORSION = "posturology_torsion";
export const TYPE_SUBMENU_POSTUROLOGY_PHOTO = "posturology_photo";
export const TYPE_SUBMENU_POSTUROLOGY_NOTE = "posturology_note";
export const TYPE_SUBMENU_PODOLOGY_FACE = "podology_face";
export const TYPE_SUBMENU_PODOLOGY_PROFIL = "podology_profil";
export const TYPE_SUBMENU_PODOLOGY_APPUI = "podology_appui";
export const TYPE_SUBMENU_PODOLOGY_DEFORMATION = "podology_deformation";
export const TYPE_SUBMENU_PODOLOGY_PHOTO = "podology_photo";
export const TYPE_SUBMENU_PODOLOGY_NOTE = "podology_note";
export const TYPE_SUBMENU_PEDICURY_PATHO = "pedicury_patho";
export const TYPE_SUBMENU_PEDICURY_ONGLE = "pedicury_ongle";
export const TYPE_SUBMENU_PEDICURY_PHOTO = "pedicury_photo";
export const TYPE_SUBMENU_PEDICURY_NOTE = "pedicury_note";
export const TYPE_SUBMENU_BILANS_PODO = "bilans_podo";
export const TYPE_SUBMENU_BILANS_POSTURO = "bilans_posturo";
export const TYPE_SUBMENU_BILANS_ORTHESE = "bilans_orthese";
export const TYPE_SUBMENU_BILANS_DOC = "bilans_doc";
export const TYPE_SUBMENU_BILANS_ORDONNANCES = "bilans_ordonnances";
export const TYPE_SUBMENU_SETTING_COMPTE = "setting_compte";
export const TYPE_SUBMENU_SETTING_INVITE = "setting_invite";
export const TYPE_SUBMENU_SETTING_LOGOUT = "setting_logout";
export const TYPE_SUBMENU_SETTING_SHARE = "setting_share";
export const TYPE_SUBMENU_SETTING_VARIABLES = "setting_variables";
export const TYPE_SUBMENU_SETTING_USERS = "setting_users";
export const TYPE_SUBMENU_SETTING_UPDATE = "setting_update";
export const TYPE_SUBMENU_SETTING_CONTACT = "setting_contact";

export const TYPE_CATEG_ANAMNESE_ALL = "all";
export const TYPE_CATEG_ANAMNESE_DOULEURS = "douleur";
export const TYPE_CATEG_ANAMNESE_PLAIES = "plaie";
export const TYPE_CATEG_ANAMNESE_BLOOD = "saignement";
export const TYPE_CATEG_ANAMNESE_TROUBLES = "trouble";
export const TYPE_CATEG_ANAMNESE_OTHER = "other";

export const POSTUROLOGY_SUB_MENU_ALL = 0;
export const POSTUROLOGY_SUB_MENU_CERVICALES = 1;
export const POSTUROLOGY_SUB_MENU_EPAULES = 2;
export const POSTUROLOGY_SUB_MENU_COLONNE = 3;
export const POSTUROLOGY_SUB_MENU_BASSIN = 4;
export const POSTUROLOGY_SUB_MENU_JAMBE_GAUCHE = 5;
export const POSTUROLOGY_SUB_MENU_JAMBE_DROITE = 6;
export const POSTUROLOGY_SUB_MENU_PIED_GAUCHE = 7;
export const POSTUROLOGY_SUB_MENU_PIED_DROIT = 8;

export const REPORT_FILTER_VIEW_CLASSIC = "classic";
export const REPORT_FILTER_VIEW_ACCOUNTING = "accounting";
export const REPORT_FILTER_VIEW_STATISTICAL = "statistical";
export const REPORT_FILTER_VIEW_ACCOUNTPROFILES = "accountProfiles";
export const REPORT_FILTER_VIEW_CONDENCED = "condenced";

export const REPORT_FILTER_TAKINGSVIEW_DETAILS = "details";
export const REPORT_FILTER_TAKINGSVIEW_TOTAL = "total";

export const REPORT_FILTER_GROUPBY_ACCOUNTINGGROUPS = "accountingGRoups";
export const REPORT_FILTER_GROUPBY_CATEGORICAL = "categorical";

export const REPORT_FILTER_TIME_INTERVAL_ALLDAY = "allDay";
export const REPORT_FILTER_TIME_INTERVALMORNING = "morning";
export const REPORT_FILTER_TIME_INTERVAL_DAY = "day";
export const REPORT_FILTER_TIME_INTERVAL_EVENING = "evening";
export const REPORT_FILTER_TIME_INTERVAL_NIGHT = "night";
export const REPORT_FILTER_TIME_INTERVAL_LATENIGHT = "lateNight";
export const add_card_button_list = [
	{
		tag: TASK_ACTION_TAG_MEMBERS,
		title: TASK_TEXT.TASK_TEXT_MEMBERS.title,
		className: TASK_ACTION_TYPE_BUTTON,
		iconSize: ICON_SIZE_SMALL,
		icon: TASK_TEXT.TASK_TEXT_MEMBERS.icon,
		type: TASK_ACTION_TYPE_BUTTON,
		active: true,
	},
	{
		tag: TASK_ACTION_TAG_LABEL,
		title: TASK_TEXT.TASK_TEXT_LABEL.title,
		className: TASK_ACTION_TYPE_BUTTON,
		iconSize: ICON_SIZE_SMALL,
		icon: TASK_TEXT.TASK_TEXT_LABEL.icon,
		type: TASK_ACTION_TYPE_BUTTON,
		active: true,
	},
	{
		tag: TASK_ACTION_TAG_CHECKLIST,
		title: TASK_TEXT.TASK_TEXT_CHECKLIST.title,
		className: TASK_ACTION_TYPE_BUTTON,
		iconSize: ICON_SIZE_SMALL,
		icon: TASK_TEXT.TASK_TEXT_CHECKLIST.icon,
		type: TASK_ACTION_TYPE_BUTTON,
		active: true,
	},
	{
		tag: TASK_ACTION_TAG_DATES,
		title: TASK_TEXT.TASK_TEXT_DATES.title,
		className: TASK_ACTION_TYPE_BUTTON,
		iconSize: ICON_SIZE_SMALL,
		icon: TASK_TEXT.TASK_TEXT_DATES.icon,
		type: TASK_ACTION_TYPE_BUTTON,
		active: true,
	},
	{
		tag: TASK_ACTION_TAG_ATTACHMENT,
		title: TASK_TEXT.TASK_TEXT_ATTACHMENT.title,
		className: TASK_ACTION_TYPE_BUTTON,
		iconSize: ICON_SIZE_SMALL,
		icon: TASK_TEXT.TASK_TEXT_ATTACHMENT.icon,
		type: TASK_ACTION_TYPE_BUTTON,
		active: true,
	},
	{
		tag: TASK_ACTION_TAG_IMAGE,
		title: TASK_TEXT.TASK_TEXT_IMAGE.title,
		className: TASK_ACTION_TYPE_BUTTON,
		iconSize: ICON_SIZE_SMALL,
		icon: TASK_TEXT.TASK_TEXT_IMAGE.icon,
		type: TASK_ACTION_TYPE_BUTTON,
		active: true,
	},
	{
		tag: TASK_ACTION_TAG_CUSTOM,
		title: TASK_TEXT.TASK_TEXT_CUSTOM.title,
		className: TASK_ACTION_TYPE_BUTTON,
		iconSize: ICON_SIZE_SMALL,
		icon: TASK_TEXT.TASK_TEXT_CUSTOM.icon,
		type: TASK_ACTION_TYPE_BUTTON,
		active: true,
	},
];

export const task_actions = [
	{ title: "Ajouter à la carte", actions: add_card_button_list },
];
export const MeetingStatus: types.IEnumsList[] = [
	{ id: 0, tag: "rdv", title: "sur rendez-vous" },
	{ id: 1, tag: "no_rdv", title: "sans rendez-vous" },
	{ id: 2, tag: "urgence", title: "urgence" },
	{ id: 3, tag: "rempla", title: "remplaçant" },
	{ id: 4, tag: "cancel", title: "annulé" },
	{ id: 5, tag: "ended", title: "terminé" },
	{ id: 6, tag: "abs", title: "absent" },
	{ id: 7, tag: "late", title: "en retard" },
];

export const type_departement: types.IEnumsList[] = [
	{ id: 1, title: "Ain", code_region: 84 },
	{ id: 2, title: "Aisne", code_region: 32 },
	{ id: 3, title: "Allier", code_region: 84 },
	{ id: 4, title: "Alpes-de-Haute-Provence", code_region: 93 },
	{ id: 5, title: "Hautes-Alpes", code_region: 93 },
	{ id: 6, title: "Alpes-Maritimes", code_region: 93 },
	{ id: 7, title: "Ardèche", code_region: 84 },
	{ id: 8, title: "Ardennes", code_region: 44 },
	{ id: 9, title: "Ariège", code_region: 76 },
	{ id: 10, title: "Aube", code_region: 44 },
	{ id: 11, title: "Aude", code_region: 76 },
	{ id: 12, title: "Aveyron", code_region: 76 },
	{ id: 13, title: "Bouches-du-Rhône", code_region: 93 },
	{ id: 14, title: "Calvados", code_region: 28 },
	{ id: 15, title: "Cantal", code_region: 84 },
	{ id: 16, title: "Charente", code_region: 75 },
	{ id: 17, title: "Charente-Maritime", code_region: 75 },
	{ id: 18, title: "Cher", code_region: 24 },
	{ id: 19, title: "Corrèze", code_region: 75 },
	{ id: 21, title: "Côte-d'Or", code_region: 27 },
	{ id: 22, title: "Côtes-d'Armor", code_region: 53 },
	{ id: 23, title: "Creuse", code_region: 75 },
	{ id: 24, title: "Dordogne", code_region: 75 },
	{ id: 25, title: "Doubs", code_region: 27 },
	{ id: 26, title: "Drôme", code_region: 84 },
	{ id: 27, title: "Eure", code_region: 28 },
	{ id: 28, title: "Eure-et-Loir", code_region: 24 },
	{ id: 29, title: "Finistère", code_region: 53 },
	{ id: "2A", title: "Corse-du-Sud", code_region: 94 },
	{ id: "2B", title: "Haute-Corse", code_region: 94 },
	{ id: 30, title: "Gard", code_region: 76 },
	{ id: 31, title: "Haute-Garonne", code_region: 76 },
	{ id: 32, title: "Gers", code_region: 76 },
	{ id: 33, title: "Gironde", code_region: 75 },
	{ id: 34, title: "Hérault", code_region: 76 },
	{ id: 35, title: "Ille-et-Vilaine", code_region: 53 },
	{ id: 36, title: "Indre", code_region: 24 },
	{ id: 37, title: "Indre-et-Loire", code_region: 24 },
	{ id: 38, title: "Isère", code_region: 84 },
	{ id: 39, title: "Jura", code_region: 27 },
	{ id: 40, title: "Landes", code_region: 75 },
	{ id: 41, title: "Loir-et-Cher", code_region: 24 },
	{ id: 42, title: "Loire", code_region: 84 },
	{ id: 43, title: "Haute-Loire", code_region: 84 },
	{ id: 44, title: "Loire-Atlantique", code_region: 52 },
	{ id: 45, title: "Loiret", code_region: 24 },
	{ id: 46, title: "Lot", code_region: 76 },
	{ id: 47, title: "Lot-et-Garonne", code_region: 75 },
	{ id: 48, title: "Lozère", code_region: 76 },
	{ id: 49, title: "Maine-et-Loire", code_region: 52 },
	{ id: 50, title: "Manche", code_region: 28 },
	{ id: 51, title: "Marne", code_region: 44 },
	{ id: 52, title: "Haute-Marne", code_region: 44 },
	{ id: 53, title: "Mayenne", code_region: 52 },
	{ id: 54, title: "Meurthe-et-Moselle", code_region: 44 },
	{ id: 55, title: "Meuse", code_region: 44 },
	{ id: 56, title: "Morbihan", code_region: 53 },
	{ id: 57, title: "Moselle", code_region: 44 },
	{ id: 58, title: "Nièvre", code_region: 27 },
	{ id: 59, title: "Nord", code_region: 32 },
	{ id: 60, title: "Oise", code_region: 32 },
	{ id: 61, title: "Orne", code_region: 28 },
	{ id: 62, title: "Pas-de-Calais", code_region: 32 },
	{ id: 63, title: "Puy-de-Dôme", code_region: 84 },
	{ id: 64, title: "Pyrénées-Atlantiques", code_region: 75 },
	{ id: 65, title: "Hautes-Pyrénées", code_region: 76 },
	{ id: 66, title: "Pyrénées-Orientales", code_region: 76 },
	{ id: 67, title: "Bas-Rhin", code_region: 44 },
	{ id: 68, title: "Haut-Rhin", code_region: 44 },
	{ id: 69, title: "Rhône", code_region: 84 },
	{ id: 70, title: "Haute-Saône", code_region: 27 },
	{ id: 71, title: "Saône-et-Loire", code_region: 27 },
	{ id: 72, title: "Sarthe", code_region: 52 },
	{ id: 73, title: "Savoie", code_region: 84 },
	{ id: 74, title: "Haute-Savoie", code_region: 84 },
	{ id: 75, title: "Paris", code_region: 11 },
	{ id: 76, title: "Seine-Maritime", code_region: 28 },
	{ id: 77, title: "Seine-et-Marne", code_region: 11 },
	{ id: 78, title: "Yvelines", code_region: 11 },
	{ id: 79, title: "Deux-Sèvres", code_region: 75 },
	{ id: 80, title: "Somme", code_region: 32 },
	{ id: 81, title: "Tarn", code_region: 76 },
	{ id: 82, title: "Tarn-et-Garonne", code_region: 76 },
	{ id: 83, title: "Var", code_region: 93 },
	{ id: 84, title: "Vaucluse", code_region: 93 },
	{ id: 85, title: "Vendée", code_region: 52 },
	{ id: 86, title: "Vienne", code_region: 75 },
	{ id: 87, title: "Haute-Vienne", code_region: 75 },
	{ id: 88, title: "Vosges", code_region: 44 },
	{ id: 89, title: "Yonne", code_region: 27 },
	{ id: 90, title: "Territoire de Belfort", code_region: 27 },
	{ id: 91, title: "Essonne", code_region: 11 },
	{ id: 92, title: "Hauts-de-Seine", code_region: 11 },
	{ id: 93, title: "Seine-Saint-Denis", code_region: 11 },
	{ id: 94, title: "Val-de-Marne", code_region: 11 },
	{ id: 95, title: "Val-d'Oise", code_region: 11 },
	{ id: 971, title: "Guadeloupe", code_region: 1 },
	{ id: 972, title: "Martinique", code_region: 2 },
	{ id: 973, title: "Guyane", code_region: 3 },
	{ id: 974, title: "La Réunion", code_region: 4 },
	{ id: 976, title: "Mayotte", code_region: 6 },
];

export const type_region: types.IEnumsList[] = [
	{ id: 84, title: "Auvergne-Rhône-Alpes" },
	{ id: 32, title: "Hauts-de-France" },
	{ id: 84, title: "Auvergne-Rhône-Alpes" },
	{ id: 93, title: "Provence-Alpes-Côte d'Azur" },
	{ id: 93, title: "Provence-Alpes-Côte d'Azur" },
	{ id: 93, title: "Provence-Alpes-Côte d'Azur" },
	{ id: 84, title: "Auvergne-Rhône-Alpes" },
	{ id: 44, title: "Grand Est" },
	{ id: 76, title: "Occitanie" },
	{ id: 44, title: "Grand Est" },
	{ id: 76, title: "Occitanie" },
	{ id: 76, title: "Occitanie" },
	{ id: 93, title: "Provence-Alpes-Côte d'Azur" },
	{ id: 28, title: "Normandie" },
	{ id: 84, title: "Auvergne-Rhône-Alpes" },
	{ id: 75, title: "Nouvelle-Aquitaine" },
	{ id: 75, title: "Nouvelle-Aquitaine" },
	{ id: 24, title: "Centre-Val de Loire" },
	{ id: 75, title: "Nouvelle-Aquitaine" },
	{ id: 27, title: "Bourgogne-Franche-Comté" },
	{ id: 53, title: "Bretagne" },
	{ id: 75, title: "Nouvelle-Aquitaine" },
	{ id: 75, title: "Nouvelle-Aquitaine" },
	{ id: 27, title: "Bourgogne-Franche-Comté" },
	{ id: 84, title: "Auvergne-Rhône-Alpes" },
	{ id: 28, title: "Normandie" },
	{ id: 24, title: "Centre-Val de Loire" },
	{ id: 53, title: "Bretagne" },
	{ id: 94, title: "Corse" },
	{ id: 94, title: "Corse" },
	{ id: 76, title: "Occitanie" },
	{ id: 76, title: "Occitanie" },
	{ id: 76, title: "Occitanie" },
	{ id: 75, title: "Nouvelle-Aquitaine" },
	{ id: 76, title: "Occitanie" },
	{ id: 53, title: "Bretagne" },
	{ id: 24, title: "Centre-Val de Loire" },
	{ id: 24, title: "Centre-Val de Loire" },
	{ id: 84, title: "Auvergne-Rhône-Alpes" },
	{ id: 27, title: "Bourgogne-Franche-Comté" },
	{ id: 75, title: "Nouvelle-Aquitaine" },
	{ id: 24, title: "Centre-Val de Loire" },
	{ id: 84, title: "Auvergne-Rhône-Alpes" },
	{ id: 84, title: "Auvergne-Rhône-Alpes" },
	{ id: 52, title: "Pays de la Loire" },
	{ id: 24, title: "Centre-Val de Loire" },
	{ id: 76, title: "Occitanie" },
	{ id: 75, title: "Nouvelle-Aquitaine" },
	{ id: 76, title: "Occitanie" },
	{ id: 52, title: "Pays de la Loire" },
	{ id: 28, title: "Normandie" },
	{ id: 44, title: "Grand Est" },
	{ id: 44, title: "Grand Est" },
	{ id: 52, title: "Pays de la Loire" },
	{ id: 44, title: "Grand Est" },
	{ id: 44, title: "Grand Est" },
	{ id: 53, title: "Bretagne" },
	{ id: 44, title: "Grand Est" },
	{ id: 27, title: "Bourgogne-Franche-Comté" },
	{ id: 32, title: "Hauts-de-France" },
	{ id: 32, title: "Hauts-de-France" },
	{ id: 28, title: "Normandie" },
	{ id: 32, title: "Hauts-de-France" },
	{ id: 84, title: "Auvergne-Rhône-Alpes" },
	{ id: 75, title: "Nouvelle-Aquitaine" },
	{ id: 76, title: "Occitanie" },
	{ id: 76, title: "Occitanie" },
	{ id: 44, title: "Grand Est" },
	{ id: 44, title: "Grand Est" },
	{ id: 84, title: "Auvergne-Rhône-Alpes" },
	{ id: 27, title: "Bourgogne-Franche-Comté" },
	{ id: 27, title: "Bourgogne-Franche-Comté" },
	{ id: 52, title: "Pays de la Loire" },
	{ id: 84, title: "Auvergne-Rhône-Alpes" },
	{ id: 84, title: "Auvergne-Rhône-Alpes" },
	{ id: 11, title: "Île-de-France" },
	{ id: 28, title: "Normandie" },
	{ id: 11, title: "Île-de-France" },
	{ id: 11, title: "Île-de-France" },
	{ id: 75, title: "Nouvelle-Aquitaine" },
	{ id: 32, title: "Hauts-de-France" },
	{ id: 76, title: "Occitanie" },
	{ id: 76, title: "Occitanie" },
	{ id: 93, title: "Provence-Alpes-Côte d'Azur" },
	{ id: 93, title: "Provence-Alpes-Côte d'Azur" },
	{ id: 52, title: "Pays de la Loire" },
	{ id: 75, title: "Nouvelle-Aquitaine" },
	{ id: 75, title: "Nouvelle-Aquitaine" },
	{ id: 44, title: "Grand Est" },
	{ id: 27, title: "Bourgogne-Franche-Comté" },
	{ id: 27, title: "Bourgogne-Franche-Comté" },
	{ id: 11, title: "Île-de-France" },
	{ id: 11, title: "Île-de-France" },
	{ id: 11, title: "Île-de-France" },
	{ id: 11, title: "Île-de-France" },
	{ id: 11, title: "Île-de-France" },
	{ id: 1, title: "Guadeloupe" },
	{ id: 2, title: "Martinique" },
	{ id: 3, title: "Guyane" },
	{ id: 4, title: "La Réunion" },
	{ id: 6, title: "Mayotte" },
];

export const type_sector: types.IEnumsList[] = [
	{ id: 1, title: "Secteur 1" },
	{ id: 2, title: "Secteur 2" },
	{ id: 3, title: "Secteur 3" },
];

export const type_fonction: types.IEnumsList[] = [
	{ id: 0, title: "Tout" },
	{ id: 60, title: "Accoucheur" },
	{ id: 33, title: "Acupuncteur" },
	{ id: 120, title: "Addictologue" },
	{ id: 1, title: "Allergologue" },
	{ id: 2, title: "Anesthésiste" },
	{ id: 128, title: "Anesthésiste-Réanimateur" },
	{ id: 3, title: "Angiologue" },
	{ id: 96, title: "Arthroscopie épaule" },
	{ id: 97, title: "Arthroscopie genou" },
	{ id: 95, title: "Arthroscopie poignet" },
	{ id: 108, title: "Audioprothésiste" },
	{ id: 10, title: "Cardiologue" },
	{ id: 112, title: "Cardiologue du Sport spécialisé en HTA" },
	{ id: 119, title: "Chirurgie gynécologique" },
	{ id: 129, title: "Chirurgie Orale" },
	{ id: 83, title: "Chirurgien Bariatrique" },
	{ id: 35, title: "Chirurgien cardiovasculaire thoracique" },
	{ id: 37, title: "Chirurgien de la main" },
	{ id: 88, title: "Chirurgien de la main et des nerfs périphériques" },
	{ id: 94, title: "Chirurgien du coude" },
	{ id: 90, title: "Chirurgien du genou" },
	{ id: 89, title: "Chirurgien du genou et de la hanche" },
	{ id: 91, title: "Chirurgien du membre supérieur et du pied" },
	{ id: 93, title: "Chirurgien du poignet" },
	{ id: 92, title: "Chirurgien du rachis" },
	{ id: 87, title: "Chirurgien Esthétique" },
	{ id: 36, title: "Chirurgien généraliste" },
	{ id: 44, title: "Chirurgien orthodontiste" },
	{ id: 39, title: "Chirurgien orthopédiste" },
	{ id: 122, title: "Chirurgien Pédiatre" },
	{ id: 40, title: "Chirurgien plastique" },
	{ id: 126, title: "Chirurgien Viscéral" },
	{ id: 42, title: "Chirurgien viscéral et digestif" },
	{ id: 65, title: "Chirurgien-dentiste" },
	{ id: 6, title: "Dermatologue" },
	{ id: 31, title: "Diabétologue" },
	{ id: 116, title: "Diététicien" },
	{ id: 11, title: "Echographiste" },
	{ id: 30, title: "Endocrinologue" },
	{ id: 125, title: "Endocrinologue-Diabétologue" },
	{ id: 85, title: "Endodontiste Exclusif" },
	{ id: 101, title: "Ergonome " },
	{ id: 98, title: "Ergothérapeute" },
	{ id: 12, title: "Gastro-entérologue" },
	{ id: 59, title: "Gériatrie" },
	{ id: 117, title: "Gérontopsychologue" },
	{ id: 13, title: "Gynécologue" },
	{ id: 124, title: "Gynécologue-Obstétricien" },
	{ id: 14, title: "Hématologue" },
	{ id: 84, title: "Hépato-Gastro-Entérologue" },
	{ id: 32, title: "Homéopathe" },
	{ id: 113, title: "Imagerie Cardiaque (scaner cardiaque, IRM cardiaque)" },
	{ id: 106, title: "Infirmier" },
	{ id: 107, title: "Infirmier" },
	{ id: 47, title: "Infirmière" },
	{ id: 118, title: "Laboratoire d'analyses médicales" },
	{ id: 66, title: "Maïeuticien" },
	{ id: 58, title: "Masseur - Kinésithérapeute" },
	{ id: 100, title: "Médecin du travail" },
	{ id: 9, title: "Médecin généraliste" },
	{ id: 15, title: "Médecin nutritionniste" },
	{ id: 16, title: "Médecin Osteopathe" },
	{ id: 111, title: "Médecin Rééducateur" },
	{ id: 71, title: "Médecin Vasculaire" },
	{ id: 61, title: "Médecine anti-âge" },
	{ id: 17, title: "Médecine du sport" },
	{ id: 57, title: "Médecine esthétique" },
	{ id: 18, title: "Médecine interne" },
	{ id: 99, title: "Médecine Morphologique et Anti-âge" },
	{ id: 109, title: "Médecine Physique et Réadaptation" },
	{ id: 34, title: "Mésothérapeute" },
	{ id: 80, title: "Microchirurgie" },
	{ id: 86, title: "micronutritionniste" },
	{ id: 19, title: "Néphrologue" },
	{ id: 38, title: "Neurochirurgien" },
	{ id: 20, title: "Neurologue" },
	{ id: 50, title: "Nutritionniste" },
	{ id: 56, title: "Obstétricien" },
	{ id: 105, title: "Odontologie Chirurgicale" },
	{ id: 4, title: "Oncologue" },
	{ id: 21, title: "Ophtalmologue" },
	{ id: 22, title: "ORL" },
	{ id: 70, title: "Orthodontiste" },
	{ id: 23, title: "Orthopédiste" },
	{ id: 104, title: "Orthophoniste" },
	{ id: 67, title: "Orthoptiste" },
	{ id: 45, title: "Ostéopathe" },
	{ id: 24, title: "Pédiatre" },
	{ id: 123, title: "Pédiatre Gastro-Entérologue" },
	{ id: 75, title: "Phlébologue" },
	{ id: 46, title: "Physiothérapeute" },
	{ id: 121, title: "Pneumo-Pédiatre" },
	{ id: 25, title: "Pneumologue" },
	{ id: 51, title: "Podologue" },
	{ id: 110, title: "Posturologue" },
	{ id: 103, title: "Psychanalyste" },
	{ id: 26, title: "Psychiatre" },
	{ id: 49, title: "Psychologue" },
	{ id: 130, title: "Psychologue clinicienne psychopathologie" },
	{ id: 115, title: "Psychologue du Travail" },
	{ id: 27, title: "Radiologue" },
	{ id: 114, title: "Rééducation Cardio Vasculaire" },
	{ id: 28, title: "Rhumatologue" },
	{ id: 48, title: "Sage-femme" },
	{ id: 29, title: "Stomatologue" },
	{ id: 81, title: "Traumatologie" },
	{ id: 82, title: "Traumatologie du sport" },
	{ id: 127, title: "Traumatologie-Orthopédiste" },
	{ id: 41, title: "Urologue" },
];

export const type_rdv: { id: number; title: string; code?: string }[] = [
	{
		id: 1,
		title: "Consultation adulte",
		code: "G",
	},
	{
		id: 2,
		title: "Consultation enfants",
		code: "MEG",
	},
	{
		id: 3,
		title: "Remplaçant",
	},
	{
		id: 4,
		title: "Retrocession",
	},

	{
		id: 7,
		title: "Champ visuel",
	},
	{
		id: 13,
		title: "Consultation Générale",
		code: "GS",
	},
	{
		id: 15,
		title: "Visite à domicile",
		code: "VL",
	},
	{
		id: 17,
		title: "Visite laboratoire",
	},
	{
		id: 18,
		title: "Consultation urgence",
	},
	{
		id: 20,
		title: "Electrocardiogramme",
		code: "DEQP003",
	},
	{
		id: 24,
		title: "Soins esthétiques",
	},
	{
		id: 25,
		title: "Consultation de nutrition",
	},
	{
		id: 26,
		title: "Soins de pédicurie",
	},
	{
		id: 27,
		title: "Ongles incarnés",
	},
	{
		id: 28,
		title: "Semelles orthopédiques",
	},
	{
		id: 29,
		title: "Orthonyxie",
	},
	{
		id: 30,
		title: "Orthoplastie",
	},
	{
		id: 31,
		title: "Taping",
	},
	{
		id: 33,
		title: "Rééducation du périnée",
	},
	{
		id: 36,
		title: "Consultation bilan",
	},
	{
		id: 37,
		title: "Consultation 1er rdv adulte",
	},
	{
		id: 39,
		title: "Consultation 1er rdv enfant",
	},
	{
		id: 40,
		title: "Peeling",
	},
	{
		id: 41,
		title: "MésoPeel",
	},
	{
		id: 43,
		title: "Comblement acide hyaluronique",
	},
	{
		id: 45,
		title: "Laser aisselles",
	},
	{
		id: 46,
		title: "Laser aisselles seules",
	},
	{
		id: 47,
		title: "Laser menton seul",
	},
	{
		id: 49,
		title: "Laser abdomen",
	},
	{
		id: 51,
		title: "Laser aisselles + maillot + demi jambes",
	},
	{
		id: 54,
		title: "Laser maillot seul",
	},
	{
		id: 55,
		title: "Laser aisselles+maillot ou maillot",
	},
	{
		id: 56,
		title:
			"Laser abdomen ou aisselles+cuisses ou cuisses ou cuisses+maillot ou dos",
	},
	{
		id: 58,
		title: "Cryolipolyse abdo/poignées d'amour",
	},
	{
		id: 59,
		title: "Cryolipolyse culotte de cheval",
	},
	{
		id: 60,
		title: "Lampe LED",
	},
	{
		id: 61,
		title: "Retouche botox",
	},
	{
		id: 62,
		title: "Retouche acide hyaluronique",
	},
	{
		id: 63,
		title: "Bilan pré-esthétique",
	},
	{
		id: 67,
		title: "Consultation de kinésithérapie",
	},
	{
		id: 69,
		title: "Kiné respiration bébé",
	},
	{
		id: 71,
		title: "Kiné du sport",
	},
	{
		id: 73,
		title: "Echographie",
	},
	{
		id: 74,
		title: "Consultation nouveau patient",
	},
	{
		id: 77,
		title: "Pose stérilet",
	},
	{
		id: 81,
		title: "Echo de datation",
	},
	{
		id: 83,
		title: "Echo de surveillance du stérilet",
	},
	{
		id: 84,
		title: "Consultation Suivi de grossesse",
	},
	{
		id: 85,
		title: "Contraception / frottis / suivi gynécologique",
	},
	{
		id: 86,
		title: "Pose ou retrait stérilet / implant",
	},
	{
		id: 89,
		title: "Suivi Diabète Gestationnel",
	},
	{
		id: 90,
		title: "Dépistage frottis",
	},
	{
		id: 91,
		title: "Suivi Diabète Gestationnel 1er rdv",
	},
	{
		id: 92,
		title: "Suivi Diabète",
	},
	{
		id: 93,
		title: "Cryolipolyse menton, bras, genoux, et zone pectorale",
	},
	{
		id: 94,
		title: "Cryopen",
	},
	{
		id: 95,
		title: "Mésolift",
	},
	{
		id: 96,
		title: "Botox",
	},
	{
		id: 99,
		title: "Laser Vasculaire",
	},
	{
		id: 100,
		title: "Hydrotomie percutanée suivi",
	},
	{
		id: 101,
		title: "Résultats",
	},
	{
		id: 102,
		title: "Consultation stérilité",
	},
	{
		id: 103,
		title: "Echo-doppler TSA (Tronc Supra Aortique)",
	},
	{
		id: 104,
		title: "Echo-doppler AMI (Artères des Membres Inférieurs)",
	},
	{
		id: 105,
		title: "Echo-doppler AMI+TSA",
	},
	{
		id: 106,
		title: "Echo-doppler VMI (Veines Membres Inférieurs)",
	},
	{
		id: 107,
		title: "Echo-doppler Fistule",
	},
	{
		id: 108,
		title: "Echo-dopppler Membres sup",
	},
	{
		id: 109,
		title: "Echo-doppler Artères rénales",
	},
	{
		id: 110,
		title: "Echo-doppler Péniens",
	},
	{
		id: 111,
		title: "Sclérose",
	},
	{
		id: 112,
		title: "Pressothérapie",
	},
	{
		id: 113,
		title: "Colposcopie",
	},
	{
		id: 114,
		title: "Hysterosonographie",
	},
	{
		id: 115,
		title: "Chirurgie gynécologique",
	},
	{
		id: 116,
		title: "Consultation ASALÉE",
	},
	{
		id: 117,
		title: "Accoucheur",
	},
	{
		id: 118,
		title: "Bilan podologique",
	},
	{
		id: 119,
		title: "Bilan pré-esthétique patient",
	},
	{
		id: 120,
		title: "Soins d'homéopathie",
	},
	{
		id: 121,
		title: "Auriculothérapie",
	},
	{
		id: 122,
		title: "Soins de mésothérapie",
	},
	{
		id: 123,
		title: "Mésodissolution",
	},
	{
		id: 124,
		title: "Hydrotomie percutanée 1er rdv",
	},
	{
		id: 125,
		title: "Renouvellement d'ordonnance ou certificat ou les 2",
	},
	{
		id: 126,
		title: "Luminothérapie",
	},
	{
		id: 127,
		title: "Relaxation",
	},
	{
		id: 129,
		title: "Consultation Angiologue",
	},
	{
		id: 130,
		title: "Programmation Neuro Linguistique",
	},
	{
		id: 131,
		title: "Médecine du sport",
	},
	{
		id: 134,
		title: "Consultation gynécologique",
	},
	{
		id: 136,
		title: "Greffe capillaire",
	},
	{
		id: 138,
		title: "Soins ostéo articulaire",
	},
	{
		id: 139,
		title: "Hydrotimètre",
	},
	{
		id: 141,
		title: "Cysto",
	},
	{
		id: 145,
		title: "Bilan Nutrition",
	},
	{
		id: 146,
		title: "Suivi de grossesse 1ère consultation",
	},
	{
		id: 147,
		title: "Consultation d'ostéopathie",
	},
	{
		id: 148,
		title: "Echographie du col",
	},
	{
		id: 151,
		title: "Première consultation de psychiatrie",
	},
	{
		id: 152,
		title: "Consultation de suivi en psychiatrie",
	},
	{
		id: 153,
		title: "Consultation urgence gynéco",
	},
	{
		id: 154,
		title: "Consultation générale longue",
	},
	{
		id: 155,
		title: "Consultation pédiatrie générale",
	},
	{
		id: 157,
		title: "Consultation de cardiologie échographie cardiaque simple",
	},
	{
		id: 158,
		title: "Test d'effort VO2 Max",
	},
	{
		id: 159,
		title: "Test d'effort",
	},
	{
		id: 160,
		title: "Holter ECG ou Rhythmique",
	},
	{
		id: 161,
		title: "Holter tensionel",
	},
	{
		id: 162,
		title: "Polysomnographie",
	},
	{
		id: 163,
		title: "Polygraphie du sommeil (PVS)",
		code: "GLQP007",
	},
	{
		id: 164,
		title: "ECG de contrôle",
	},
	{
		id: 165,
		title: "Consultation d'ostéopathie pour enfant",
	},
	{
		id: 166,
		title: "Consultation d'ostéopathie pour nourrisson",
	},
	{
		id: 167,
		title: "Consultation d'ostéopathie pour sportif",
	},
	{
		id: 168,
		title: "Consultation d'ostéopathie pour femme enceinte",
	},
	{
		id: 169,
		title: "Soins à domicile",
	},
	{
		id: 170,
		title: "Bilan Orthoptique",
	},
	{
		id: 171,
		title:
			"Bilan orthoptique neurovisuel (troubles apprentissages, AVC, etc..)",
	},
	{
		id: 172,
		title: "Dépistage orthoptique pour bébé",
	},
	{
		id: 173,
		title: "Rééducation Orthoptique",
	},
	{
		id: 174,
		title: "Rééducation troubles spécifiques des apprentissages",
	},
	{
		id: 175,
		title: "Rééducation basse vision",
	},
	{
		id: 176,
		title: "Bilan basse vision",
	},
	{
		id: 177,
		title: "Test de vision binoculaire",
	},
	{
		id: 178,
		title: "Physiothérapie massage",
	},
	{
		id: 179,
		title: "Thérapie manuelle",
	},
	{
		id: 180,
		title: "Massage détente",
	},
	{
		id: 181,
		title: "École du dos",
	},
	{
		id: 182,
		title: "K taping",
	},
	{
		id: 183,
		title: "Drainage lymphatique manuel",
	},
	{
		id: 184,
		title: "Consultation traumatologie, orthopédique, rhumatologie",
	},
	{
		id: 185,
		title: "Technique niromathe",
	},
	{
		id: 186,
		title: "Rééducation fonctionnelle et activité physique",
	},
	{
		id: 190,
		title: "Echo morphologique de grossesse 1er 2e 3e trim",
	},
	{
		id: 191,
		title: "Consultation de gastro-entérologie",
	},
	{
		id: 192,
		title: "Suivi d'hospitalisation et résultats",
	},
	{
		id: 194,
		title: "Avis médical",
	},
	{
		id: 195,
		title:
			"Consultation coordonnée par le médecin traitant (courrier d'adressage)",
	},
	{
		id: 196,
		title: "Echo-doppler artériel",
	},
	{
		id: 197,
		title: "Echo-doppler veineux",
	},
	{
		id: 199,
		title: "Echo-doppler arteriel rénal",
	},
	{
		id: 200,
		title: "Capillaroscopie",
	},
	{
		id: 202,
		title: "Nouveaux patients",
	},
	{
		id: 215,
		title: "Consultation",
	},
	{
		id: 218,
		title: "Réflexologie plantaire",
	},
	{
		id: 219,
		title: "ENMG (électroneumyogramme) 4 membres",
	},
	{
		id: 220,
		title: "ENMG (électroneuromyogramme) mains ou bras",
	},
	{
		id: 221,
		title: "ENMG (électroneuromyogramme) Jambes",
	},
	{
		id: 222,
		title: "Suivi Parkinson sous pompe",
	},
	{
		id: 223,
		title: "Polygraphie ventilatoire",
	},
	{
		id: 224,
		title: "MAPA",
	},
	{
		id: 225,
		title: "Sclérose varices",
	},
	{
		id: 227,
		title: "Cartographie/FAV",
	},
	{
		id: 229,
		title: "Echo-doppler Greffons",
	},
	{
		id: 230,
		title: "Echo-doppler Transcranien",
	},
	{
		id: 231,
		title: "Varices",
	},
	{
		id: 232,
		title: "Hydrafacial",
	},
	{
		id: 233,
		title: "Dépistage CEGIDD",
	},
	{
		id: 237,
		title: "Fistule artério-veineuse",
	},
	{
		id: 238,
		title: "Echo artères digestives et ou rénales",
	},
	{
		id: 240,
		title: "Cartographie MS (des membres supérieurs) pré fistule AV",
	},
	{
		id: 243,
		title: "Holter",
	},
	{
		id: 248,
		title: "Artères des membres inférieurs (AMI)",
	},
	{
		id: 249,
		title: "Veines des membres inférieurs (VMI)",
	},
	{
		id: 250,
		title: "Artères cervicales et/ou cérébrales intra-crâniennes (TSA)",
	},
	{
		id: 251,
		title: "Artères cervicales et des membres inférieurs (TSA+MI)",
	},
	{
		id: 252,
		title: "Artères et/ou veines des membres supérieurs",
	},
	{
		id: 253,
		title: "Artères rénales et/ou digestives",
	},
	{
		id: 254,
		title: "Aorte abdominale (dépistage et/ou suivi AAA)",
	},
	{
		id: 255,
		title: "Fistule artério-veineuse (FAV)",
	},
	{
		id: 256,
		title: "Cartographie des membres supérieurs pré-FAV",
	},
	{
		id: 257,
		title: "Greffon rénal et/ou hépatique",
	},
	{
		id: 258,
		title: "Marquage pré-opératoire (chirurgie varices)",
	},
	{
		id: 259,
		title: "Consultation de médecine vasculaire",
	},
	{
		id: 262,
		title: "Consultation de suivi en psychiatrie (courte)",
	},
	{
		id: 263,
		title: "Téléconsultation",
		code: "TCG",
	},
	{
		id: 264,
		title: "Consultation Clinique Sainte Marie",
	},
	{
		id: 265,
		title: "Consultation Maison de santé de Ducos",
	},
	{
		id: 267,
		title: "Contrôle post opératoire",
	},
	{
		id: 268,
		title: "Infiltration sous contrôle Échographique",
	},
	{
		id: 269,
		title: "Consultation Chirurgie Viscérale",
	},
	{
		id: 270,
		title: "1ère Consultation d'obésité",
	},
	{
		id: 271,
		title: "Consultation Bariatrique",
	},
	{
		id: 272,
		title: "Consultation Commune du François",
	},
	{
		id: 273,
		title: "Consultation post opératoire",
	},
	{
		id: 274,
		title: "Suivi Anneau",
	},
	{
		id: 275,
		title: "Suivi bypass",
	},
	{
		id: 276,
		title: "Suivi minibypass",
	},
	{
		id: 277,
		title: "Suivi Sleeve",
	},
	{
		id: 278,
		title: "Suivi Viscéral",
	},
	{
		id: 279,
		title: "Rendez vous Professionnel",
	},
	{
		id: 280,
		title: "Test Allergologique",
	},
	{
		id: 281,
		title: "Consultation de Pneumologie",
	},
	{
		id: 282,
		title: "Consultation d'Allergologie",
	},
	{
		id: 283,
		title: "Exploration Fonctionnelle Respiratoire",
	},
	{
		id: 285,
		title: "Contrôle",
	},
	{
		id: 286,
		title: "Prescription d'ordonnance / rendu d'examen",
	},
	{
		id: 287,
		title: "GARDE",
	},
	{
		id: 288,
		title: "Consultation Apnée",
	},
	{
		id: 289,
		title: "Appareillage",
	},
	{
		id: 290,
		title: "Contrôle post-op 1 semaine",
	},
	{
		id: 291,
		title: "Contrôle post-op 1 mois",
	},
	{
		id: 292,
		title: "Echo-Sclérose",
	},
	{
		id: 293,
		title: "Sclérose esthétique",
	},
	{
		id: 294,
		title: "Contrôle sclérose 6 semaines",
	},
	{
		id: 295,
		title: "Echomarquage",
	},
	{
		id: 296,
		title: "Injection vaginale d'acide hyaluronique",
	},
	{
		id: 297,
		title: "LED Vaginale",
	},
	{
		id: 298,
		title: "Rééducation Périnéale",
	},
	{
		id: 300,
		title: "bilan orthoptique fonctionnel",
	},
	{
		id: 301,
		title: "Rééducation Neurovisuelle",
	},
	{
		id: 302,
		title: "Test Recherche Apnée du Sommeil",
	},
	{
		id: 303,
		title: "Consultation Renouvellement Appareil de Sommeil",
	},
	{
		id: 304,
		title: "Cartographie",
	},
	{
		id: 305,
		title: "Première Consultation d'Ostéopathie",
	},
	{
		id: 306,
		title: "Consultation de Suivi d'Ostéopathie",
	},
	{
		id: 311,
		title: "Bilan Orthoptique + Lancaster",
	},
	{
		id: 312,
		title: "Consultation d'Amblyopie",
	},
	{
		id: 313,
		title: "Consultation homéopathie ou acupuncture ou ostéopathie",
	},
	{
		id: 314,
		title: "Consultation Urgences Psy",
	},
	{
		id: 315,
		title: "Thérapie Cognitive et Comportementale",
	},
	{
		id: 316,
		title: "Consultation psychiatrique CMU",
	},
	{
		id: 322,
		title: "Renouvellement lunettes",
	},
	{
		id: 323,
		title: "Consultation hors internet",
	},
	{
		id: 325,
		title: "Radiofréquence",
	},
	{
		id: 328,
		title: "Premier RDV de nutrition",
	},
	{
		id: 329,
		title: "Premier RDV de micro-nutrition",
	},
	{
		id: 330,
		title: "Premier RDV d'homéopathie",
	},
	{
		id: 331,
		title: "Soins de micro-nutrition",
	},
	{
		id: 332,
		title: "Consultation d'Homéopathie",
	},
	{
		id: 333,
		title: "Sevrage Tabagique",
	},
	{
		id: 334,
		title: "Acupuncture",
	},
	{
		id: 335,
		title: "Micronutrition",
	},
	{
		id: 337,
		title: "Homéopathie",
	},
	{
		id: 339,
		title: "Cours de Préparation la Naissance",
	},
	{
		id: 340,
		title: "Consultation de Pneumologie ou Allergologie",
	},
	{
		id: 341,
		title: "Consultation pour Renouvellement Appareil du sommeil",
	},
	{
		id: 342,
		title: "Laboratoire Pharmaceutique",
	},
	{
		id: 343,
		title: "Radiofréquence forma visage",
	},
	{
		id: 344,
		title: "Radiofréquence forma cou",
	},
	{
		id: 345,
		title: "Radiofréquence forma corps",
	},
	{
		id: 346,
		title: "Radiofréquence fractora visage",
	},
	{
		id: 347,
		title: "Radiofréquence fractora cou",
	},
	{
		id: 348,
		title: "Radiofréquence fractora corps",
	},
	{
		id: 349,
		title: "Intervention Chirurgicale",
	},
	{
		id: 350,
		title: "Champ Visuel Automatique",
	},
	{
		id: 363,
		title: "Consultation nourrisson",
	},
	{
		id: 364,
		title: "Consultation hypercholestérolémie",
	},
	{
		id: 365,
		title: "Reins et Artères Rénales",
	},
	{
		id: 366,
		title: "Artères et/ou Veines digestives",
	},
	{
		id: 367,
		title: "Thyroïde et Vascularisation",
	},
	{
		id: 368,
		title: "1ère Consultation",
	},
	{
		id: 369,
		title: "Urgence (douleur)",
	},
	{
		id: 371,
		title: "Dépistage Rétinopathie Diabétique",
	},
	{
		id: 372,
		title: "Mesure IPS",
	},
	{
		id: 373,
		title: "TCPO2 (Pression des orteils)",
	},
	{
		id: 376,
		title: "Expertise",
	},
	{
		id: 378,
		title: "Yoga Prénatal",
	},
	{
		id: 379,
		title: "Plaies Variqueuses",
	},
	{
		id: 416,
		title: "Consultation COVID19",
	},
	{
		id: 418,
		title: "Entretien Pré natal",
	},
	{
		id: 419,
		title: "Contraception",
	},
	{
		id: 420,
		title: "Consultation d'Allergologie Suivi",
	},
	{
		id: 421,
		title: "Consultation d'Allergologie Nouveau patient",
	},
	{
		id: 422,
		title: "Consultation Médecine Générale",
	},
	{
		id: 423,
		title: "Consultation Dermatologie",
	},
	{
		id: 424,
		title: "Dermatologie Chirurgicale",
	},
	{
		id: 425,
		title: "Consultation Médecine Générale Nouveau P",
	},
	{
		id: 426,
		title: "Dépôt de Dossier Bariatrique",
	},
	{
		id: 432,
		title: "Chirurgie Anesthésie Locale",
	},
	{
		id: 433,
		title: "Chirurgie Anesthésie Générale",
	},
	{
		id: 434,
		title: "Consultation Grossesse",
	},
	{
		id: 435,
		title: "Entretien Prénatal Précoce (EPP)",
	},
	{
		id: 436,
		title: "Préparation à la Naissance et à la Parentalité (PNP)",
	},
	{
		id: 437,
		title: "Monitoring au cabinet à partir de 35 SA",
	},
	{
		id: 438,
		title: "Consultation post- natale",
	},
	{
		id: 439,
		title: "Pose implant contraceptif",
	},
	{
		id: 440,
		title: "Retrait implant contraceptif",
	},
	{
		id: 441,
		title: "Pose D.I.U (stérilet)",
	},
	{
		id: 442,
		title: "Retrait D.I.U (stérilet)",
	},
	{
		id: 443,
		title: "Renouvellement pilule",
	},
	{
		id: 444,
		title: "Consultation rééducation",
	},
	{
		id: 445,
		title: "Séance de rééducation",
	},
	{
		id: 449,
		title: "IPS",
	},
	{
		id: 455,
		title: "Consultation contrôle",
	},
	{
		id: 462,
		title: "Esthétique",
	},
	{
		id: 467,
		title: "RDV Admin",
	},
	{
		id: 471,
		title: "Consultation de médecine vasculaire",
	},
	{
		id: 472,
		title: "Marquage pré-opératoire (pré FAV)",
	},
	{
		id: 473,
		title: "Esthétique",
	},
	{
		id: 475,
		title: "Epreuve d'effort",
	},
	{
		id: 476,
		title: "Audiogramme",
	},
	{
		id: 477,
		title: "Vertige + Consultation",
	},
	{
		id: 478,
		title: "Ronflement",
	},
	{
		id: 479,
		title: "Consultation pré-esthétique",
	},
	{
		id: 480,
		title: "Cryolipolyse",
	},
	{
		id: 481,
		title: "Fils Tenseurs",
	},
	{
		id: 482,
		title:
			"PLEXR (Blepharoplastie) traitement et élimination paupière supérieure",
	},
	{
		id: 483,
		title: "PLEXR (élimination lésions cutanées molluscum Verrues...)",
	},
	{
		id: 484,
		title: "Photoréjuvénation",
	},
	{
		id: 485,
		title: "Echo-doppler des veines testiculaires (recherche variocèle)",
	},
	{
		id: 487,
		title: "RDV expo",
	},
	{
		id: 489,
		title: "Consultation Cardiologie avec ECG",
	},
	{
		id: 491,
		title: "Contrôle Pacemaker",
	},
	{
		id: 492,
		title: "Renouvellement Poly",
	},
	{
		id: 493,
		title: "Thérapie conjugale et familiale",
	},
	{
		id: 495,
		title: "Réflexothérapie",
	},
	{
		id: 499,
		title: "Suivi de Grossesse",
	},
	{
		id: 500,
		title: "Première Consultation de Contraception et de Prévention (15/18ans)",
	},
	{
		id: 502,
		title: "Consultation et accompagnement à l'allaitement",
	},
	{
		id: 505,
		title: "Entretient Post Natal Précoce",
	},
	{
		id: 507,
		title: "Echographie Cardiaque",
	},
	{
		id: 508,
		title: "Consultation d'Anesthésie autres chirurgies",
	},
	{
		id: 509,
		title: "Post interventionnel",
	},
	{
		id: 510,
		title: "Rééducation Basse Vision",
	},
	{
		id: 511,
		title: "Appareillage Auditif à Porter",
	},
	{
		id: 512,
		title: "Réglage Appareil Auditif",
	},
	{
		id: 513,
		title: "Contrôle Appareil Auditif",
	},
	{
		id: 514,
		title: "SAV, Panne, Réparation",
	},
	{
		id: 515,
		title: "Test D'Audition",
	},
	{
		id: 516,
		title: "Holter ECG ou Rhytmique Galléria",
	},
	{
		id: 517,
		title: "Polygraphie Ventilatoire Galléria",
	},
	{
		id: 518,
		title: "Consultation d'ostéopathie adultes",
	},
	{
		id: 519,
		title: "Consultation d'ostéopathie enfants et nourrissons",
	},
	{
		id: 520,
		title: "Consultation d'ostéopathie post- accouchement + nouveau née",
	},
	{
		id: 522,
		title: "Suivi",
	},
	{
		id: 523,
		title: "Bilan-conseils",
	},
	{
		id: 524,
		title: "Consultation plongée +1",
	},
	{
		id: 525,
		title: "Consultation ostéopathie Maman avec Bébé",
	},
	{
		id: 526,
		title: "Bilan de la déglutition (orienté par un dentiste)",
	},
	{
		id: 527,
		title: "Suivi des troubles de déglutition",
	},
	{
		id: 530,
		title: "Remise Semelles",
	},
	{
		id: 531,
		title: "Consultation d'Anesthésie Maternité",
	},
	{
		id: 533,
		title: "Vérification de Semelles",
	},
	{
		id: 536,
		title: "Bilan de la communication et du langage oral",
	},
	{
		id: 537,
		title: "Bilan du langage écrit",
	},
	{
		id: 538,
		title: "Bilan langage / troubles cognitifs Adulte",
	},
	{
		id: 541,
		title: "Enfant/nourisson-première consultation",
	},
	{
		id: 542,
		title: "Enfant/nourrisson consultation de suivi",
		code: "COE",
	},
	{
		id: 543,
		title: "Femme enceinte- première consultation",
	},
	{
		id: 544,
		title: "Femme enceinte- consultation de suivi",
	},
	{
		id: 545,
		title: "Consulation somato- émotionnelle",
	},
	{
		id: 546,
		title: "Consultation Suivi Traitement",
	},
	{
		id: 547,
		title: "Consultation Suivi Trimestriel",
	},
	{
		id: 548,
		title: "Consultation Suivi Semestriel",
	},
	{
		id: 549,
		title: "Consultation Suivi Annuel",
	},
	{
		id: 550,
		title: "Consultation Nourrisson 9e et 24e mois",
	},
	{
		id: 553,
		title: "Pose de bandes",
	},
	{
		id: 554,
		title: "Pansement IDE",
	},
	{
		id: 555,
		title: "Pressions Digitales",
	},
	{
		id: 556,
		title: "Phlébectomie",
	},
	{
		id: 558,
		title: "1ère Consultation de Médecine Esthétique Rides, Laser",
	},
	{
		id: 559,
		title: "Bilan avant 1ère injection",
	},
	{
		id: 560,
		title: "Epilations Laser",
	},
	{
		id: 561,
		title: "Epilation laser 1/2 Jambes",
	},
	{
		id: 562,
		title: "Injections",
	},
	{
		id: 563,
		title: "Séance PRP (plasma riche en plaquettes)",
	},
	{
		id: 564,
		title: "PLEXR (signes de chaire, blépharoplastie, chéloïdes)",
	},
	{
		id: 565,
		title: "Personnel",
	},
	{
		id: 567,
		title: "Psychothérapie enfant",
	},
	{
		id: 568,
		title: "Psychothérapie Adolescent",
	},
	{
		id: 569,
		title: "Psychothérapie Adulte",
	},
	{
		id: 571,
		title: "Psychothérapie Couple",
	},
	{
		id: 572,
		title: "Consultation avec ECG",
	},
	{
		id: 573,
		title: "Frottis cervico-vaginal",
	},
	{
		id: 575,
		title:
			"Consultation pédiatrie générale / Consultation pour troubles de croissance",
	},
	{
		id: 576,
		title: "Consultation d'anesthésie Cataracte",
	},
	{
		id: 577,
		title: "MMS",
	},
	{
		id: 578,
		title: "Hamilton",
		code: "ALQP003",
	},
	{
		id: 579,
		title: "Consultation infertilité",
	},
	{
		id: 580,
		title: "Echographie Suivi PMA",
	},
	{
		id: 581,
		title: "Hypnose",
	},
	{
		id: 582,
		title: "Cohérence cardiaque",
	},
	{
		id: 583,
		title: "Traitement verrue",
	},
	{
		id: 584,
		title: "Soin Patient Diabétique",
	},
	{
		id: 585,
		title: "Suivi Soin Patient Diabétique",
	},
	{
		id: 586,
		title: "Examen clinique de l'adulte",
	},
	{
		id: 587,
		title: "Examen clinique de l'enfant (-16ans)",
	},
	{
		id: 588,
		title: "Examen clinique sport",
	},
	{
		id: 589,
		title: "Onychoplasties",
	},
	{
		id: 592,
		title: "1ère consultation infertilité",
	},
	{
		id: 593,
		title: "Colposcopie pour frottis anormal",
	},
	{
		id: 594,
		title: "Soin pédicurie",
	},
	{
		id: 595,
		title: "Examen clinique",
	},
	{
		id: 596,
		title: "Suivi de grossesse à risque",
	},
	{
		id: 597,
		title: "Radiofréquence + phlebectomie",
	},
	{
		id: 598,
		title: "Echo grossesse datation entre 8SA et 10SA",
	},
	{
		id: 599,
		title: "Echo grossesse 1er trimestre entre 11SA et 13SA et 6j",
	},
	{
		id: 600,
		title: "Echo grossesse 2eme trimestre entre 20SA et 24SA",
	},
	{
		id: 601,
		title: "Echo grossesse 3eme trimestre entre 30SA et 34SA",
	},
	{
		id: 602,
		title: "Colposcopie et biopsie du col de l'utérus",
	},
	{
		id: 603,
		title: "Première visite prénatale",
	},
	{
		id: 604,
		title: "Echo croissance et/ou dopplers",
	},
	{
		id: 605,
		title: "Visite post natale",
	},
	{
		id: 606,
		title: "Consultation de gynécologie",
	},
	{
		id: 608,
		title: "Injection d'Acide Hyaluronique",
	},
	{
		id: 609,
		title: "Injection de Toxine Botulique",
	},
	{
		id: 610,
		title: "Injection d'Acide Hyaluronique & Injection de Toxine Botulique",
	},
	{
		id: 611,
		title: "Injection Radiesse",
	},
	{
		id: 612,
		title: "Laser épilatoire visage",
	},
	{
		id: 613,
		title: "Laser épilatoire aisselles",
	},
	{
		id: 614,
		title: "Laser épilatoire maillot",
	},
	{
		id: 615,
		title: "Laser épilatoire aisselles & maillot",
	},
	{
		id: 616,
		title: "Laser épilatoire 1/2 jambes",
	},
	{
		id: 617,
		title: "Laser épilatoire Forfait 1 heure",
	},
	{
		id: 619,
		title: "Lumière Pulsée",
	},
	{
		id: 620,
		title: "Laser Vasculaire Visage",
	},
	{
		id: 622,
		title: "Laser Vasculaire Jambes",
	},
	{
		id: 623,
		title: "Laser Fractionné",
	},
	{
		id: 624,
		title: "Peelings",
	},
	{
		id: 631,
		title: "Bilan diététique",
	},
	{
		id: 632,
		title: "Consultation de suivi",
	},
	{
		id: 633,
		title: "Consultation nutrition et mot",
	},
	{
		id: 634,
		title: "Forfait nutrition et mot",
	},
	{
		id: 635,
		title: "Forfait nutrition",
	},
	{
		id: 636,
		title: "Niromathé thérapie manuelle",
	},
	{
		id: 637,
		title: "Echo gémellaire",
	},
	{
		id: 638,
		title: "Consultation Adolescent",
	},
	{
		id: 639,
		title: "Consultation Santé Sexuelle",
	},
	{
		id: 640,
		title: "Monitoring au Cabinet",
	},
	{
		id: 641,
		title: "Suivi à Domicile",
	},
	{
		id: 642,
		title: "Première consultation de grossesse pour suivi",
	},
	{
		id: 643,
		title: "Dépistage maladies sexuellement transmissibles",
		code: "CCE",
	},
	{
		id: 644,
		title: "Retrait implant, changement implant",
	},
	{
		id: 645,
		title: "Pose DIU, retrait DIU, changement DIU (DIU=Stérilet)",
	},
	{
		id: 647,
		title: "Consultation générale, suivi gynécologique",
	},
	{
		id: 648,
		title: "Contrôle / Résultats",
	},
	{
		id: 649,
		title: "Projet de grossesse + - difficultés",
	},
	{
		id: 650,
		title: "Délégué médical",
	},
	{
		id: 651,
		title: "Consultation périnée",
	},
	{
		id: 652,
		title: "Visite post natale",
	},
	{
		id: 653,
		title: "Suivi diversion duodénale",
	},
	{
		id: 654,
		title: "Suivi Hernie Hiatale",
	},
	{
		id: 655,
		title: "Frottis",
	},
	{
		id: 656,
		title: "Certificat MDPH",
	},
	{
		id: 657,
		title: "Epilation Electrique",
	},
	{
		id: 660,
		title: "Laser pour taches Visage",
	},
	{
		id: 661,
		title: "Aptitude avant emploi",
	},
	{
		id: 662,
		title: "Laser pour taches visage + autre zone",
	},
	{
		id: 664,
		title: "Fasciathérapie",
	},
	{
		id: 665,
		title: "Consultation pour un enfant -12 ans",
	},
	{
		id: 666,
		title: "Drainage",
	},
	{
		id: 667,
		title: "Neuro",
	},
	{
		id: 668,
		title: "Rééducation post opératoire",
	},
	{
		id: 669,
		title: "Pathologies courantes (entorse, douleurs au dos...)",
	},
	{
		id: 670,
		title: "Kiné respi",
	},
	{
		id: 672,
		title: "Test PCR COVID-19",
	},
	{
		id: 675,
		title: "Asthme/BPCO",
	},
	{
		id: 676,
		title: "Consultation pré opératoire",
	},
	{
		id: 684,
		title: "Consultation Ostéopathie Morne Des Esses",
	},
	{
		id: 686,
		title: "Rééducation classique",
	},
	{
		id: 687,
		title: "Réfraction",
	},
	{
		id: 688,
		title: "Consultation individuelle",
	},
	{
		id: 690,
		title: "Suivi Diététique",
	},
	{
		id: 691,
		title: "1er rdv de suivi diététique (plan alimentaire)",
	},
	{
		id: 692,
		title: "Bilan Diététique + mise en place du suivi",
	},
	{
		id: 693,
		title: "Consultation 2eme avis",
	},
	{
		id: 694,
		title: "Consultation nouvelle patiente",
	},
	{
		id: 695,
		title: "Examen semelles orthopédiques",
	},
	{
		id: 696,
		title: "Bilan éducatif partagé",
	},
	{
		id: 697,
		title: "Consultation psychologique dans le cadre de l'ETP pour le DG",
	},
	{
		id: 698,
		title: "Atelier d'initiation à la méditation",
	},
	{
		id: 699,
		title: "Vaccination COVID-19 pour les 55-75 ans INJECTION 1",
	},
	{
		id: 700,
		title: "Consultation pour personne diabétique",
	},
	{
		id: 701,
		title: "Première consultation pour personne diabétique",
	},
	{
		id: 702,
		title: "Bilan podologique et semelles orthopédiques",
	},
	{
		id: 703,
		title: "Enfant Bilan podologique et semelles orthopédiques",
	},
	{
		id: 704,
		title: "Semelles orthopédiques - Remise",
	},
	{
		id: 705,
		title: "Semelles orthopédiques - Verification",
	},
	{
		id: 706,
		title: "Traitement de verrue plantaire",
	},
	{
		id: 707,
		title: "Traitement de verrue plantaire - Suivi",
	},
	{
		id: 708,
		title: "Orthoplatie/orthonyxie",
	},
	{
		id: 712,
		title: "Consultation du post partum",
	},
	{
		id: 714,
		title: "Examen clinique (semelles)",
	},
	{
		id: 715,
		title: "Vaccination COVID-19 pour les 55-75 ans INJECTION 2",
	},
	{
		id: 716,
		title: "Bilan test laser",
	},
	{
		id: 717,
		title: "Laser menton strict et/ou lèvres",
	},
	{
		id: 718,
		title: "Laser menton élargi vers le cou et/ou vers les joues",
	},
	{
		id: 719,
		title: "Laser 1/2 jambes",
	},
	{
		id: 720,
		title: "RDV 45 min",
	},
	{
		id: 721,
		title: "RDV 60 min",
	},
	{
		id: 722,
		title: "Laboratoire",
	},
	{
		id: 723,
		title: "1ère consultation médecine morphologique anti-âge",
	},
	{
		id: 724,
		title: "Consultation hydrafacial",
	},
	{
		id: 725,
		title: "Consultation LED",
	},
	{
		id: 726,
		title: "Consultation de suivi de médecine générale",
	},
	{
		id: 727,
		title: "1ère consultation de médecine générale",
	},
	{
		id: 728,
		title: "Consultation pour symptômes de tout type d'infection",
	},
	{
		id: 729,
		title: "Consultation prise en charge par REUCARE",
	},
	{
		id: 730,
		title: "RDV Extra Matin",
	},
	{
		id: 731,
		title: "RDV Extra Après-Midi",
	},
	{
		id: 737,
		title: "Vaccination COVID-19",
	},
	{
		id: 739,
		title: "Suspicion phlébite",
	},
	{
		id: 740,
		title: "Contrôle phlébite",
	},
	{
		id: 741,
		title: "Douleur oreille (otalgie)",
	},
	{
		id: 742,
		title: "Douleur gorge (dysphagie)",
	},
	{
		id: 743,
		title: "Maux de tête (céphalée)",
	},
	{
		id: 744,
		title: "Surdité",
	},
	{
		id: 745,
		title: "Vertige",
	},
	{
		id: 746,
		title: "Perte de la voix normale",
	},
	{
		id: 747,
		title: "Pose / retrait implants",
	},
	{
		id: 748,
		title: "Dossier MDPH",
	},
	{
		id: 749,
		title: "Dossier assurance",
	},
	{
		id: 750,
		title:
			"Consultation de suivi gynécologique de prévention et de contraception",
	},
	{
		id: 751,
		title: "Entretien individuel préparation à la naissance",
	},
	{
		id: 753,
		title: "Visite post natale (domicile)",
	},
	{
		id: 754,
		title: "Suivi post natal",
	},
	{
		id: 755,
		title: "Rééducation périnéale (1er rendez-vous)",
	},
	{
		id: 756,
		title: "Renouvellement contraception",
	},
	{
		id: 758,
		title: "Pose/ Changement ou Retrait de STERILET ou IMPLANT CONTRACEPTIF",
	},
	{
		id: 759,
		title: "1ère Consultation INFERTILITE / DESIR DE GROSSESSE",
	},
	{
		id: 760,
		title: "Consultation / Echo de grossesse avant 11SA",
	},
	{
		id: 761,
		title: "Echographie morpho 2eme trimestre entre 20 - 24SA",
	},
	{
		id: 762,
		title: "Echographie morpho 3eme trimestre entre 30 - 34SA",
	},
	{
		id: 763,
		title: "Echographie de contrôle post IVG",
	},
	{
		id: 764,
		title: "Demande d'IVG",
	},
	{
		id: 765,
		title: "Consultation prise en charge par le Réseau Pédiatrique.",
	},
	{
		id: 766,
		title: "ETP",
	},
	{
		id: 767,
		title: "Monitoring à domicile",
	},
	{
		id: 768,
		title: "Consultation sans rendez-vous",
	},
	{
		id: 769,
		title: "Médecine générale et Acupuncture",
	},
	{
		id: 770,
		title: "Consultation interne",
	},
	{
		id: 771,
		title: "Rééducation Périnéale (45min)",
	},
	{
		id: 772,
		title: "Consultation d'ostéopathie - suivi de sportif",
	},
	{
		id: 773,
		title: "Consultation d'ostéopathie à domicile",
	},
	{
		id: 774,
		title: "Consultation Grand Prématuré",
		code: "CCE",
	},
	{
		id: 775,
		title: "Participation forfaitaire volontaire",
		code: "PAV",
	},
	{
		id: 776,
		title: "Ablation de bouchon de cérumen",
		code: "CAGD001",
	},
	{
		id: 777,
		title: "Consultation Obligatoire de l'Enfant de plus de 6 ans",
		code: "COB",
	},
	{
		id: 778,
		title: "Changement d'un dispositif intra-utérin",
		code: "JKKD001",
	},
	{
		id: 779,
		title: "Ablation ou changement d'implant contraceptif",
		code: "QZGA002",
	},
	{
		id: 780,
		title: "Suture plaie superficielle de la peau de moins de 3 cm",
		code: "QZJA002",
	},
];

export const getActesIds = (codes: string[]) => {
	const ids: number[] = [];
	codes.forEach((code) => {
		const findedId: any = type_rdv.find(
			(tr) => tr.code?.toLocaleLowerCase() === code.toLocaleLowerCase()
		);
		if (findedId) ids.push(findedId.id);
	});
	return ids;
};

export const type_reglement: { id: number; title: string; label: string }[] = [
	{ id: 0, title: "ESP", label: "Espèces" },
	{ id: 1, title: "TP", label: "Télé paiement" },
	{ id: 2, title: "AT", label: "AT" },
	{ id: 3, title: "CHQ", label: "Chèques" },
	{ id: 4, title: "CMU", label: "CMU" },
	{ id: 5, title: "MAT", label: "Maternité" },
	{ id: 6, title: "CB", label: "Carte bancaire" },
	{ id: 7, title: "ALD", label: "ALD" },
	{ id: 8, title: "AME", label: "AME" },
	{ id: 9, title: "VIR", label: "Virement bancaire" },
	{ id: 10, title: "TRP", label: "Tiers payant" },
	{ id: 11, title: "NO", label: "Aucun" },
];

export const type_civilite: { id: number; title: string; label: string }[] = [
	{ id: 0, title: "Mr", label: "Monsieur" },
	{ id: 1, title: "Mme", label: "Madame " },
	{ id: 2, title: "Mlle", label: "Mademoiselle " },
	{ id: 3, title: "Dr", label: "Docteur " },
	{ id: 4, title: "Pr", label: "Professeur " },
];

export const type_anamnese: types.IAnamneseBase[] = [
	{
		id: 1,
		label: "Irritabilité",
		src: "irritabilite",
		categ: "trouble",
	},
	{ id: 2, label: "Otalgie", src: "otalgie", categ: "douleur" },
	{
		id: 3,
		label: "Herpès labial",
		src: "herpes_labial",
		categ: "plaie",
	},
	{
		id: 4,
		label: "Perte d’appétit",
		src: "perte_appetit",
		categ: "trouble",
	},
	{
		id: 5,
		label: "Polydipsie ",
		src: "polydipsie",
		categ: "trouble",
	},
	{
		id: 6,
		label: "Maux de gorge",
		src: "mauxgorge",
		categ: "douleur",
	},
	{ id: 7, label: "Asthénie", src: "asthenie", categ: "trouble" },
	{ id: 8, label: "Fièvre", src: "fievre", categ: "trouble" },
	{
		id: 9,
		label: "Hémoptysie",
		src: "hemoptysie",
		categ: "saignement",
	},
	{ id: 10, label: "Confusion", src: "confusion", categ: "trouble" },
	{ id: 11, label: "Vertiges", src: "vertiges", categ: "trouble" },
	{
		id: 12,
		label: "Migraines",
		src: "migraines",
		categ: "douleur,trouble",
	},
	{ id: 13, label: "Toux", src: "toux", categ: "trouble" },
	{
		id: 14,
		label: "Vomissements",
		src: "vomissements",
		categ: "trouble",
	},
	{ id: 15, label: "Nausées", src: "nausees", categ: "trouble" },
	{
		id: 16,
		label: "Conjonctivite",
		src: "conjonctivite",
		categ: "douleur,saignement",
	},
	{
		id: 17,
		label: "Trouble de la vision",
		src: "trouble_vision",
		categ: "trouble",
	},
	{ id: 18, label: "Jaunisse", src: "jaunisse", categ: "" },
	{
		id: 19,
		label: "Œdème de visage",
		src: "oedeme_visage",
		categ: "",
	},
	{ id: 20, label: "Œdème labial", src: "oedeme_labial", categ: "" },
	{
		id: 21,
		label: "Epistaxis",
		src: "epistaxis",
		categ: "saignement",
	},
	{ id: 22, label: "Dysosmie", src: "dysosmie", categ: "trouble" },
	{
		id: 23,
		label: "Perte de mémoire",
		src: "perte_memoire",
		categ: "trouble",
	},
	{
		id: 24,
		label: "Perte de facultés",
		src: "perte_facultes",
		categ: "trouble",
	},
	{
		id: 25,
		label: "Température basse",
		src: "temperature_basse",
		categ: "",
	},
	{ id: 26, label: "Eksema", src: "eksema", categ: "plaie" },
	{
		id: 27,
		label: "Courbatures",
		src: "courbatures",
		categ: "douleur",
	},
	{
		id: 28,
		label: "Douleur musculaire",
		src: "douleur_musculaire",
		categ: "douleur",
	},
	{
		id: 29,
		label: "Douleur articulaire",
		src: "douleur_articulaire",
		categ: "douleur",
	},
	{
		id: 30,
		label: "Lombalgies",
		src: "lombalgies",
		categ: "douleur",
	},
	{
		id: 31,
		label: "Trouble cardiaque",
		src: "trouble_cardiaque",
		categ: "trouble",
	},
	{
		id: 32,
		label: "Insuffisance respiratoire",
		src: "insuffisance_respiratoire",
		categ: "trouble",
	},
	{ id: 33, label: "Mains froides", src: "mains_froides", categ: "" },
	{
		id: 34,
		label: "Mastodynie",
		src: "mastodynie",
		categ: "douleur",
	},
	{
		id: 35,
		label: "Hématurie",
		src: "hematurie",
		categ: "saignement",
	},
	{ id: 36, label: "Brûlures", src: "brulures", categ: "plaie" },
	{
		id: 37,
		label: "Effractions cutanée",
		src: "effractions_cutanees",
		categ: "plaie,saignement",
	},
	{ id: 38, label: "Tendinite", src: "tendinite", categ: "" },
	{ id: 39, label: "Fracture", src: "fracture", categ: "" },
	{
		id: 40,
		label: "Grande faim",
		src: "grande_faim",
		categ: "douleur,trouble",
	},
	{
		id: 41,
		label: "Maux de ventre",
		src: "maux_ventre",
		categ: "douleur,trouble",
	},
	{
		id: 42,
		label: "Eruption cutanée",
		src: "eruption_cutanee",
		categ: "",
	},
	{
		id: 43,
		label: "Hémorragie interne",
		src: "hemorragie_interne",
		categ: "saignement",
	},
	{
		id: 44,
		label: "Douleurs génitales",
		src: "douleurs_genitales_m",
		categ: "douleur",
	},
	{
		id: 45,
		label: "Douleurs urinaires",
		src: "douleurs_urinaires_m",
		categ: "douleur",
	},
	{
		id: 46,
		label: "Douleurs génitales",
		src: "douleurs_genitales_f",
		categ: "douleur",
	},
	{
		id: 47,
		label: "Douleurs urinaires",
		src: "douleurs_urinaires_f",
		categ: "douleur",
	},
	{
		id: 48,
		label: "Herpès vaginal",
		src: "herpes_vaginal",
		categ: "plaie",
	},
	{
		id: 49,
		label: "Herpès génital",
		src: "herpes_genital",
		categ: "plaie",
	},
	{
		id: 50,
		label: "Saignement vaginal",
		src: "saignement_vaginal",
		categ: "saignement",
	},
	{
		id: 51,
		label: "Troubles érectiles",
		src: "troubles_erectiles",
		categ: "trouble",
	},
	{
		id: 52,
		label: "Douleurs anales",
		src: "douleurs_anales",
		categ: "douleur",
	},
	{
		id: 53,
		label: "Démangeaison anale",
		src: "demangeaison_anale",
		categ: "plaie,trouble",
	},
	{
		id: 54,
		label: "Rectorragie",
		src: "rectorragie",
		categ: "saignement",
	},
	{
		id: 55,
		label: "Selles claires",
		src: "selles_claires",
		categ: "",
	},
	{
		id: 56,
		label: "Constipation",
		src: "constipation",
		categ: "trouble",
	},
	{ id: 57, label: "Diarrhée", src: "diarrhee", categ: "" },
	{ id: 58, label: "Flatulences", src: "flatulences", categ: "" },
	{
		id: 59,
		label: "Forte perte de poids",
		src: "forte_perte_poids",
		categ: "",
	},
	{
		id: 60,
		label: "Forte prise de poids",
		src: "forte_prise_poids",
		categ: "",
	},

	{
		id: 61,
		label: "Méningite",
		src: "meningite",
		categ: "child,trouble",
	},

	{
		id: 61,
		label: "Varicelle",
		src: "eksema",
		categ: "child,trouble",
	},

	{
		id: 62,
		label: "Rubéole",
		src: "eksema",
		categ: "child,trouble",
	},

	{
		id: 63,
		label: "Rougeole",
		src: "eksema",
		categ: "child,trouble",
	},

	{
		id: 64,
		label: "Oreillons",
		src: "oedeme_visage",
		categ: "child,trouble",
	},

	{
		id: 65,
		label: "Coqueluche",
		src: "toux",
		categ: "child,trouble",
	},

	{
		id: 66,
		label: "Laryngite ",
		src: "toux",
		categ: "child,trouble",
	},

	{
		id: 67,
		label: "Otite",
		src: "otite",
		categ: "child,douleur",
	},

	{
		id: 68,
		label: "Asthme",
		src: "asthme",
		categ: "child,trouble",
	},

	{
		id: 69,
		label: "Bronchite",
		src: "insuffisance_respiratoire",
		categ: "child,trouble",
	},
];

export enum TodoStatus {
	todo = "todo",
	inprogress = "inprogress",
	done = "done",
}

export const momentFR = {
	months:
		"janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre".split(
			"_"
		),
	monthsShort: "jan_fév_mar_avr_mai_jun_jul_aoû_sep_oct_nov_déc".split("_"),
	monthsParseExact: true,
	weekdays: "dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi".split("_"),
	weekdaysShort: "dim_lun_mar_mer_jeu_ven_sam".split("_"),
	weekdaysMin: "Di_Lu_Ma_Me_Je_Ve_Sa".split("_"),
	weekdaysParseExact: true,
	longDateFormat: {
		LT: "HH:mm",
		LTS: "HH:mm:ss",
		L: "DD/MM/YYYY",
		LL: "D MMMM YYYY",
		LLL: "D MMMM YYYY HH:mm",
		LLLL: "dddd D MMMM YYYY HH:mm",
	},
	calendar: {
		sameDay: "[Aujourd’hui à] LT",
		nextDay: "[Demain à] LT",
		nextWeek: "dddd [à] LT",
		lastDay: "[Hier à] LT",
		lastWeek: "dddd [dernier à] LT",
		sameElse: "L",
	},
	relativeTime: {
		future: "dans %s",
		past: "il y a %s",
		s: "quelques secondes",
		m: "une minute",
		mm: "%d minutes",
		h: "une heure",
		hh: "%d heures",
		d: "un jour",
		dd: "%d jours",
		M: "un mois",
		MM: "%d mois",
		y: "un an",
		yy: "%d ans",
	},
	dayOfMonthOrdinalParse: /\d{1,2}(er|e)/,
	ordinal: function (number: number) {
		return number + (number === 1 ? "er" : "e");
	},
	meridiemParse: /PD|MD/,
	isPM: function (input: any) {
		return input.charAt(0) === "M";
	},
	// In case the meridiem units are not separated around 12, then implement
	// this function (look at locale/id.js for an example).
	// meridiemHour : function (hour, meridiem) {
	//     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
	// },
	meridiem: function (hours: number, minutes: number, isLower: boolean) {
		return hours < 12 ? "PD" : "MD";
	},
	week: {
		dow: 1, // Monday is the first day of the week.
		doy: 4, // Used to determine first week of the year.
	},
};

export const colors = {
	green: {
		card: "card-lighter-green",
		label: "card-green",
		bolder: "card-bolder-green",
	},
	yellow: {
		card: "card-lighter-yellow",
		label: "card-yellow",
		bolder: "Gt6Z2TYLNWg2dg",
	},
	orange: {
		card: "card-lighter-orange",
		label: "card-orange",
		bolder: "QbGsu1h2KImNHi",
	},
	red: {
		card: "card-lighter-red",
		label: "card-red",
		bolder: "YlTu3CRWFBNXnj",
	},
	purple: {
		card: "card-lighter-purple",
		label: "card-purple",
		bolder: "DsifJCopCBEumx",
	},
	blue: {
		card: "card-lighter-blue",
		label: "card-blue",
		bolder: "dC3eyYZsZ_yK4E",
	},
	teal: {
		card: "card-lighter-teal",
		label: "card-teal",
		bolder: "PyqAcz_HsOnUtf",
	},
	lime: {
		card: "card-lighter-lime",
		label: "card-lime",
		bolder: "jlPUQzkfm8oYZu",
	},
	magenta: {
		card: "card-lighter-magenta",
		label: "card-magenta",
		bolder: "HgiR1IHw0SvNkK",
	},
	gray: {
		card: "card-lighter-gray",
		label: "card-gray",
		bolder: "Q_TO1T_p7lzNJs",
	},
};

export type ColorTypes =
	| "green"
	| "yellow"
	| "orange"
	| "red"
	| "purple"
	| "blue"
	| "teal"
	| "lime"
	| "magenta"
	| "gray";

export type ButtonTypes = "link" | "button" | "text";

export class ListFilters {
	"clients": string[] = [];
	"status": string[] = [];
	"remplacants": string[] = [];
	"specialites": string[] = [];
	"notes": string[] = [];
}

export enum InvoiceStatus {
	INVOICE_STATUS_DEVIS = "DEVIS", //Brouillon
	INVOICE_STATUS_PENDING = "PENDING", //En cours
	INVOICE_STATUS_ESTIMATE_SENDED = "ESTIMATE_SENDED", //devis envoyé
	INVOICE_STATUS_INVOICE_SENDED = "INVOICE_SENDED", // facture envoyée
	INVOICE_STATUS_PROJECT_DELIVERED = "DELIVERED", // projet livré
	INVOICE_STATUS_PAYMENT_OVERDUE = "PAYMENT_OVERDUE", //paiement en retard
	INVOICE_STATUS_PAID = "PAID", //payé
	INVOICE_STATUS_INVALID = "INVALID", //Invalide
	INVOICE_STATUS_CANCEL = "CANCELED", //Annulé
}

export const INVOICE_STATUS_DEVIS = -1; //Brouillon
export const INVOICE_STATUS_PENDING = 0; //En cours
export const INVOICE_STATUS_ESTIMATE_SENDED = 1; //devis envoyé
export const INVOICE_STATUS_INVOICE_SENDED = 2; // facture envoyée
export const INVOICE_STATUS_PROJECT_DELIVERED = 3; // projet livré
export const INVOICE_STATUS_PAYMENT_OVERDUE = 4; //paiement en retard
export const INVOICE_STATUS_PAID = 5; //payé
export const INVOICE_STATUS_INVALID = 6; //Invalide
export const INVOICE_STATUS_CANCEL = 7; //Annulé

export const INVOICE_TYPE_QUANTITY = 0;
export const INVOICE_TYPE_FORFAIT = 1;

export const TRANSACTION_TYPE_REVENUS = "revenus";
export const TRANSACTION_TYPE_REMUNERATIONS = "remunerations";
export const TRANSACTION_TYPE_FONCTIONNEMENT = "fonctionnement";
export const TRANSACTION_TYPE_DEPLACEMENTS = "deplacements";
export const TRANSACTION_TYPE_FRAIS_FIXES = "frais-fixes";
export const TRANSACTION_TYPE_TAXES = "taxes";

export const FILTER_TYPE_INVOICE_CUSTOMER = "invoiceClients";
export const FILTER_TYPE_INVOICE_STATUS = "invoiceStatus";
export const FILTER_TYPE_SEXE = "sexe";
export const FILTER_TYPE_REMPLACANT = "remplacant";
export const FILTER_TYPE_EXPERIENCE = "experience";
export const FILTER_TYPE_SPECIALITE = "specialite";

export const letterCorrespondance: { [key: string]: string } = {
	À: "A",
	Á: "A",
	Â: "A",
	Ã: "A",
	Ä: "A",
	Å: "A",
	à: "a",
	á: "a",
	â: "a",
	ã: "a",
	ä: "a",
	å: "a",
	Ç: "C",
	ç: "c",
	Ð: "D",
	ð: "d",
	È: "E",
	É: "E",
	Ê: "E",
	Ë: "E",
	è: "e",
	é: "e",
	ê: "e",
	ë: "e",
	Ì: "I",
	Í: "I",
	Î: "I",
	Ï: "I",
	ì: "i",
	í: "i",
	î: "i",
	ï: "i",
	Ñ: "N",
	ñ: "n",
	Ò: "O",
	Ó: "O",
	Ô: "O",
	Õ: "O",
	Ö: "O",
	Ø: "O",
	ò: "o",
	ó: "o",
	ô: "o",
	õ: "o",
	ö: "o",
	ø: "o",
	Ù: "U",
	Ú: "U",
	Û: "U",
	Ü: "U",
	ù: "u",
	ú: "u",
	û: "u",
	ü: "u",
	Ý: "Y",
	ý: "y",
	ÿ: "y",
	Æ: "Ae",
	æ: "ae",
	Þ: "Th",
	þ: "th",
	ß: "ss",
	Ā: "A",
	Ă: "A",
	Ą: "A",
	ā: "a",
	ă: "a",
	ą: "a",
	Ć: "C",
	Ĉ: "C",
	Ċ: "C",
	Č: "C",
	ć: "c",
	ĉ: "c",
	ċ: "c",
	č: "c",
	Ď: "D",
	Đ: "D",
	ď: "d",
	đ: "d",
	Ē: "E",
	Ĕ: "E",
	Ė: "E",
	Ę: "E",
	Ě: "E",
	ē: "e",
	ĕ: "e",
	ė: "e",
	ę: "e",
	ě: "e",
	Ĝ: "G",
	Ğ: "G",
	Ġ: "G",
	Ģ: "G",
	ĝ: "g",
	ğ: "g",
	ġ: "g",
	ģ: "g",
	Ĥ: "H",
	Ħ: "H",
	ĥ: "h",
	ħ: "h",
	Ĩ: "I",
	Ī: "I",
	Ĭ: "I",
	Į: "I",
	İ: "I",
	ĩ: "i",
	ī: "i",
	ĭ: "i",
	į: "i",
	ı: "i",
	Ĵ: "J",
	ĵ: "j",
	Ķ: "K",
	ķ: "k",
	ĸ: "k",
	Ĺ: "L",
	Ļ: "L",
	Ľ: "L",
	Ŀ: "L",
	Ł: "L",
	ĺ: "l",
	ļ: "l",
	ľ: "l",
	ŀ: "l",
	ł: "l",
	Ń: "N",
	Ņ: "N",
	Ň: "N",
	Ŋ: "N",
	ń: "n",
	ņ: "n",
	ň: "n",
	ŋ: "n",
	Ō: "O",
	Ŏ: "O",
	Ő: "O",
	ō: "o",
	ŏ: "o",
	ő: "o",
	Ŕ: "R",
	Ŗ: "R",
	Ř: "R",
	ŕ: "r",
	ŗ: "r",
	ř: "r",
	Ś: "S",
	Ŝ: "S",
	Ş: "S",
	Š: "S",
	ś: "s",
	ŝ: "s",
	ş: "s",
	š: "s",
	Ţ: "T",
	Ť: "T",
	Ŧ: "T",
	ţ: "t",
	ť: "t",
	ŧ: "t",
	Ũ: "U",
	Ū: "U",
	Ŭ: "U",
	Ů: "U",
	Ű: "U",
	Ų: "U",
	ũ: "u",
	ū: "u",
	ŭ: "u",
	ů: "u",
	ű: "u",
	ų: "u",
	Ŵ: "W",
	ŵ: "w",
	Ŷ: "Y",
	ŷ: "y",
	Ÿ: "Y",
	Ź: "Z",
	Ż: "Z",
	Ž: "Z",
	ź: "z",
	ż: "z",
	ž: "z",
	Ĳ: "IJ",
	ĳ: "ij",
	Œ: "Oe",
	œ: "oe",
	ŉ: "'n",
	ſ: "s",
};
// Statuts des Tables
export enum StatutTable {
	Free = "Free", // Free
	Occupied = "Occupied", // Occupied
	Reserved = "Reserved", // Reserved
	OutOfService = "OutOfService", // Out of Service
	Cleaning = "Cleaning", // Cleaning
	AwaitingOrder = "AwaitingOrder", // Awaiting Order
	Preparing = "Preparing", // Preparing
	ReadyToServe = "ReadyToServe", // Ready to Serve
	BeingServed = "BeingServed", // Being Served
	ToClear = "ToClear", // To Clear
	Other = "Other", // Other
}

type StatutTableKeys = keyof typeof StatutTable;

export interface StatutTablePropertiesValues {
	label?: string;
	subText?: string;
	color: string;
	percent: number;
}

export const StatutTableProperties: {
	[key in StatutTableKeys]: StatutTablePropertiesValues;
} = {
	Free: { label: "Libre", color: "blue-400", percent: 0 },
	Occupied: { label: "Occupée", color: "green-400", percent: 20 },
	Reserved: { label: "Réservée", color: "gray-200", percent: -1 },
	OutOfService: { label: "Hors service", color: "gray-200", percent: -2 },
	Cleaning: { label: "Nettoyage", color: "green-200", percent: 10 },
	AwaitingOrder: {
		label: "Attente de commande",
		color: "orange-400",
		percent: 30,
	},
	Preparing: { label: "En préparation", color: "orange-400", percent: 40 },
	ReadyToServe: { label: "Prête à servir", color: "orange-500", percent: 50 },
	BeingServed: { label: "En cours de service", color: "rose-500", percent: 60 },
	ToClear: { label: "À débarrasser", color: "red-500", percent: 90 },
	Other: { label: "Autre", color: "gray-200", percent: -1 },
};

export type CustomerSex = "male" | "female" | "child";

export const tw_colors: { [key: string]: any } = {
	slate: {
		50: "#f5f7fa",
		100: "#ebeef5",
		200: "#d6dbe5",
		300: "#a9b3c9",
		400: "#727e9d",
		500: "#4e5e7d",
		600: "#364958",
		700: "#273e44",
		800: "#1b2f3a",
		900: "#152930",
	},
	gray: {
		50: "#f9fafb",
		100: "#f3f4f6",
		200: "#e5e7eb",
		300: "#d1d5db",
		400: "#9ca3af",
		500: "#6b7280",
		600: "#4b5563",
		700: "#374151",
		800: "#252f3f",
		900: "#161e2e",
	},
	zinc: {
		50: "#f8f9fa",
		100: "#f1f3f5",
		200: "#e2e6e9",
		300: "#c7ced4",
		400: "#96a2aa",
		500: "#667179",
		600: "#4d5867",
		700: "#35434f",
		800: "#27303a",
		900: "#192228",
	},
	neutral: {
		50: "#f9fafb",
		100: "#f3f4f6",
		200: "#e5e7eb",
		300: "#d1d5db",
		400: "#9ca3af",
		500: "#6b7280",
		600: "#4b5563",
		700: "#374151",
		800: "#252f3f",
		900: "#161e2e",
	},
	stone: {
		50: "#f4f5f7",
		100: "#e5e7eb",
		200: "#c7ced4",
		300: "#a0aec0",
		400: "#718096",
		500: "#4a5568",
		600: "#383e4b",
		700: "#28303d",
		800: "#20232a",
		900: "#1a1e28",
	},
	red: {
		50: "#fef2f2",
		100: "#fee2e2",
		200: "#fecaca",
		300: "#fca5a5",
		400: "#f87171",
		500: "#ef4444",
		600: "#dc2626",
		700: "#b91c1c",
		800: "#991b1b",
		900: "#7f1d1d",
	},
	orange: {
		50: "#fff7ed",
		100: "#ffedd5",
		200: "#fed7aa",
		300: "#fdba74",
		400: "#fb923c",
		500: "#f97316",
		600: "#ea580c",
		700: "#c2410c",
		800: "#9a3412",
		900: "#7c2d12",
	},
	amber: {
		50: "#fffbeb",
		100: "#fef3c7",
		200: "#fde68a",
		300: "#fcd34d",
		400: "#fbbf24",
		500: "#f59e0b",
		600: "#d97706",
		700: "#b45309",
		800: "#92400e",
		900: "#78350f",
	},
	yellow: {
		50: "#fefce8",
		100: "#fef9c3",
		200: "#fef08a",
		300: "#fde047",
		400: "#facc15",
		500: "#eab308",
		600: "#ca8a04",
		700: "#a16207",
		800: "#854d0e",
		900: "#713f12",
	},
	lime: {
		50: "#f7fee7",
		100: "#ecfccb",
		200: "#d9f99d",
		300: "#bef264",
		400: "#a3e635",
		500: "#84cc16",
		600: "#65a30d",
		700: "#4d7c0f",
		800: "#3f6212",
		900: "#365314",
	},
	green: {
		50: "#f0fdf4",
		100: "#dcfce7",
		200: "#bbf7d0",
		300: "#86efac",
		400: "#4ade80",
		500: "#22c55e",
		600: "#16a34a",
		700: "#15803d",
		800: "#166534",
		900: "#14532d",
	},
	emerald: {
		50: "#ecfdf5",
		100: "#d1fae5",
		200: "#a7f3d0",
		300: "#6ee7b7",
		400: "#34d399",
		500: "#10b981",
		600: "#059669",
		700: "#047857",
		800: "#065f46",
		900: "#064e3b",
	},
	teal: {
		50: "#f0fdfa",
		100: "#ccfbf1",
		200: "#99f6e4",
		300: "#66f0d8",
		400: "#3ae7d1",
		500: "#20c997",
		600: "#0ca678",
		700: "#099268",
		800: "#087f5b",
		900: "#0b6c4d",
	},
	cyan: {
		50: "#ecfeff",
		100: "#cffafe",
		200: "#a5f3fc",
		300: "#67e8f9",
		400: "#22d3ee",
		500: "#06b6d4",
		600: "#0891b2",
		700: "#0e7490",
		800: "#155e75",
		900: "#164e63",
	},
	sky: {
		50: "#f0f9ff",
		100: "#e0f2fe",
		200: "#bae6fd",
		300: "#7dd3fc",
		400: "#38bdf8",
		500: "#0ea5e9",
		600: "#0284c7",
		700: "#0369a1",
		800: "#075985",
		900: "#0c4a6e",
	},
	blue: {
		50: "#eff6ff",
		100: "#dbeafe",
		200: "#bfdbfe",
		300: "#93c5fd",
		400: "#60a5fa",
		500: "#3d7dca",
		600: "#2563eb",
		700: "#1d4ed8",
		800: "#1e40af",
		900: "#1e3a8a",
	},
	indigo: {
		50: "#f0f5ff",
		100: "#e5eaf5",
		200: "#c7b9f7",
		300: "#a295df",
		400: "#7a6bca",
		500: "#4f3cc9",
		600: "#2d1ac7",
		700: "#1e0ea3",
		800: "#150e78",
		900: "#130e62",
	},
	violet: {
		50: "#f5f3ff",
		100: "#ede9fe",
		200: "#ddd6fe",
		300: "#c4b5fd",
		400: "#a78bfa",
		500: "#8b5cf6",
		600: "#7c3aed",
		700: "#6d28d9",
		800: "#5925b5",
		900: "#501e93",
	},
	purple: {
		50: "#faf5ff",
		100: "#f3e8ff",
		200: "#e9d5ff",
		300: "#d8b4fe",
		400: "#c084fc",
		500: "#a855f7",
		600: "#9333ea",
		700: "#7e22ce",
		800: "#6b21a8",
		900: "#581c87",
	},
	fuchsia: {
		50: "#fdf4ff",
		100: "#fae8ff",
		200: "#f5d0fe",
		300: "#f0abfc",
		400: "#e879f9",
		500: "#d946ef",
		600: "#c026d3",
		700: "#a21caf",
		800: "#86198b",
		900: "#701a75",
	},
	pink: {
		50: "#fce7f3",
		100: "#fbcfe8",
		200: "#f9a8d4",
		300: "#f472b6",
		400: "#ec4899",
		500: "#db2777",
		600: "#be185d",
		700: "#9d174d",
		800: "#831843",
		900: "#6b143a",
	},
	rose: {
		50: "#fff1f2",
		100: "#ffe4e6",
		200: "#fecdd3",
		300: "#fda4af",
		400: "#fb7185",
		500: "#f43f5e",
		600: "#e11d48",
		700: "#be123c",
		800: "#9f1239",
		900: "#881337",
	},
	midnight: {
		50: "#f0f2f7",
		100: "#d3d5e6",
		200: "#b8bbd0",
		300: "#989ecb",
		400: "#7277c8",
		500: "#5659c7",
		600: "#3d36d0",
		700: "#2c2c6f",
		800: "#2c2c6f",
		900: "#2c2c6f",
	},
	metal: {
		50: "#fbfdfe",
		100: "#f1f5f9",
		200: "#e2e8f0",
		300: "#c6ccd6",
		400: "#8994a6",
		500: "#67768e",
		600: "#4d5765",
		700: "#374151",
		800: "#293b46",
		900: "#1f2933",
	},
	tahiti: {
		50: "#fffdf9",
		100: "#fefcf1",
		200: "#fdf8e0",
		300: "#faf4c0",
		400: "#f5e88b",
		500: "#f0e05a",
		600: "#e6d137",
		700: "#d9b412",
		800: "#bb8e05",
		900: "#9e7000",
	},
	silver: {
		50: "#f7f7f7",
		100: "#eeeeee",
		200: "#e0e0e0",
		300: "#cfcfcf",
		400: "#a8a8a8",
		500: "#868686",
		600: "#686868",
		700: "#525252",
		800: "#414141",
		900: "#323232",
	},
	"bubble-gum": {
		50: "#f9f5fc",
		100: "#f0ebfa",
		200: "#e1d4f4",
		300: "#d1bbec",
		400: "#c19ce4",
		500: "#b277db",
		600: "#9851d1",
		700: "#7839c9",
		800: "#692cc1",
		900: "#5823b0",
	},
	bermuda: {
		50: "#f4f9f9",
		100: "#e5f1f6",
		200: "#cde2ec",
		300: "#a5d7e0",
		400: "#7bcbda",
		500: "#3fb5d3",
		600: "#2aa1c0",
		700: "#1c81a0",
		800: "#12687a",
		900: "#0f5660",
	},
};
