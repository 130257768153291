import colors from "tailwindcss/colors";

/* eslint-disable no-mixed-spaces-and-tabs */
function CalCaseDark({
	selected,
	selectedColor,
	defaultColor,
}: {
	selected?: boolean;
	selectedColor?: keyof typeof colors;
	defaultColor?: keyof typeof colors;
}) {
	const selectedBg = `bg-${selectedColor}-700`;
	const selectedBorder = `border-${selectedColor}-700`;
	const defaultBg = `bg-${defaultColor}-700`;
	const defaultBorder = `border-${defaultColor}-700`;
	return (
		<div
			className={`flex w-1 h-1 border-[1px] border-blue-800/50 ${
				selected
					? `${
							selectedColor
								? `${selectedBorder} ${selectedBg}`
								: "border-blue-800/50 bg-blue-800/50"
					  }`
					: `${
							defaultColor
								? `${defaultBorder} ${defaultBg}`
								: "border-blue-500/50 bg-blue-500/50"
					  }`
			}`}
		></div>
	);
}

export default CalCaseDark;
