/* eslint-disable @typescript-eslint/no-unused-vars */
import { create } from "zustand";
import * as enums from "../lib/enums";

interface appStore {
	isCollapsed: boolean;
	dashboardFilterCalSelection: number;
	selectedClient: string;
	selectedDataFilter: number;
	selectedCountryName?: string;
	selectedPageName?: string;
	setSelectedPageName: (selectedPageName?: string) => void;
	setSelectedCountryName: (selectedCountryName?: string) => void;
	setIsCollapsed: (isCollapsed: boolean) => void;
	setDashboardFilterCalSelection: (dashboardFilterCalSelection: number) => void;
	setSelectedClient: (selectedClient: string) => void;
	setSelectedDataFilter: (selectedDataFilter: number) => void;
}

export const useAppSettingStore = create<appStore>()((set, _get) => ({
	isCollapsed: true,
	selectedClient: "",
	selectedDataFilter: enums.CAMPAGNE_DATA_FILTER_TOTALSCORE,
	dashboardFilterCalSelection: enums.CALENDAR_MODE_DAY,
	setSelectedPageName: (selectedPageName?: string) => {
		set({ selectedPageName });
	},
	setSelectedCountryName: (selectedCountryName?: string) => {
		set({ selectedCountryName });
	},
	setIsCollapsed: (isCollapsed: boolean) => {
		set({ isCollapsed });
	},
	setDashboardFilterCalSelection: (dashboardFilterCalSelection: number) => {
		set({ dashboardFilterCalSelection });
	},
	setSelectedClient: (selectedClient: string) => {
		set({ selectedClient });
	},
	setSelectedDataFilter: (selectedDataFilter: number) => {
		set({ selectedDataFilter });
	},
}));
