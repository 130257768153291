import React from "react";
import PageContent from "../../PageContent";

function Kitchen() {
	return (
		<PageContent>
			<div>
				<div className="">Kitchen</div>
				<div className=""></div>
				<div className=""></div>
				<div className=""></div>
				<div className=""></div>
				<div className=""></div>
				<div className=""></div>
				<div className=""></div>
				<div className=""></div>
				<div className=""></div>
			</div>
		</PageContent>
	);
}

export default Kitchen;
