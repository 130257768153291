import { reportNoteItem } from "@/lib/invoice-types";
import { Card, Rating } from "@material-tailwind/react";
import React from "react";

const NotesDeRapport = ({ reportNotes }: { reportNotes: reportNoteItem[] }) => {
	return (
		<>
			<Card className="rounded-md p-0 text-xs">
				<table className="min-w-full leading-normal">
					<tbody>
						{reportNotes.map((note, note_index) => {
							return (
								<React.Fragment key={note_index}>
									<tr>
										<td className="p-2 border-b border-gray-200">
											{note.date}
										</td>
										<td className="p-2 border-b border-gray-200">
											{note.role}
										</td>
										<td className="p-2 border-b border-gray-200">
											<Rating
												readonly
												value={note.note ?? 0}
											/>
										</td>
										<td className="p-2 border-b border-gray-200">
											{note.description ?? (
												<div className="italic">Aucune note de rapport</div>
											)}
										</td>
									</tr>
								</React.Fragment>
							);
						})}
					</tbody>
				</table>
			</Card>
		</>
	);
};

export default NotesDeRapport;
