import { useCurrUserStore } from "_services/useCurrUserStore";
import React from "react";

import { useAuth } from "../auth/AuthContext";
import * as functions from "@/lib/functions";
import { IconButton, Avatar } from "@mui/material";
function UserMenu() {
	const { logout, currentUser } = useAuth();
	const avatar =
		currentUser.displayName ??
		currentUser.email.split("@")[0].replace(".", " ");

	return (
		<div className="flex flex-col gap-3  text-sm text-gray-600">
			<div className="">
				<div className="flex flex-row items-center gap-3 m-4">
					<div className="flex items-center">
						<IconButton sx={{ p: 0 }}>
							<Avatar
								{...functions.stringAvatar(avatar)}
								sx={{ width: 42, height: 42 }}
							/>
						</IconButton>
					</div>
					<div className="flex flex-col">
						<div className="">{avatar}</div>
						<div className="text-xs">{currentUser?.email}</div>
					</div>
				</div>
				<div className="px-5 py-2 hover:bg-gray-100">Changer de compte</div>
				<div className="px-5 py-2 hover:bg-gray-100">Gérer le compte</div>
			</div>

			<hr />
			<div className="">
				<div className="px-5 py-2 font-bold">TRELLO</div>
				<div className="px-5 py-2 hover:bg-gray-100">Profil et visibilité</div>
				<div className="px-5 py-2 hover:bg-gray-100">Activité</div>
				<div className="px-5 py-2 hover:bg-gray-100">Cartes</div>
				<div className="px-5 py-2 hover:bg-gray-100">Paramètres</div>
				<div className="px-5 py-2 hover:bg-gray-100">Thème</div>
			</div>

			<hr />
			<div className="">
				<div className="px-5 py-2 hover:bg-gray-100">Aide</div>
				<div className="px-5 py-2 hover:bg-gray-100">Raccourcis</div>
			</div>
			<hr />
			<div
				className=" mb-4"
				onClick={() => {
					logout();
				}}
			>
				<div className="px-5 py-2 hover:bg-gray-100">Se déconnecter</div>
			</div>
		</div>
	);
}

export default UserMenu;
