import { CanceledReceiptItem } from "@/lib/invoice-types";
import { Card, CardBody } from "@material-tailwind/react";
import React from "react";

const CanceledReceipts = ({
	canceledReceipts,
}: {
	canceledReceipts: CanceledReceiptItem[];
}) => {
	let totalAnnulationsArticles = 0;
	let totalAnnulationsMontantNet = 0;
	let totalAnnulationsMontantBrut = 0;
	canceledReceipts.map((receipt) => {
		totalAnnulationsArticles += receipt.articles;
		totalAnnulationsMontantNet += receipt.montantNet;
		totalAnnulationsMontantBrut += receipt.montantBrut;
	});
	return (
		<Card className="rounded-md p-0 text-xs">
			<CardBody className="p-0">
				<table className="min-w-full leading-normal">
					<tbody>
						<tr>
							<td className="p-2 border-b border-gray-200">Type de Produits</td>
							<td className="p-2 border-b border-gray-200">Nb</td>
							<td className="p-2 border-b border-gray-200">Brut</td>
							<td className="p-2 border-b border-gray-200">Net</td>
						</tr>
						{/* Section Drinks */}
						{canceledReceipts.map((receipt, receipt_index) => {
							return (
								<React.Fragment key={receipt_index}>
									<tr>
										<td className="p-2 border-b border-gray-200">
											{receipt.departement}
										</td>
										<td className="p-2 border-b border-gray-200 text-right">
											{receipt.articles} articles
										</td>
										<td className="p-2 border-b border-gray-200 text-right">
											{receipt.montantBrut.toFixed(2)}€
										</td>
										<td className="p-2 border-b border-gray-200 text-right">
											{receipt.montantNet.toFixed(2)}€
										</td>
									</tr>
								</React.Fragment>
							);
						})}
						<tr>
							<td className="p-2 border-t border-gray-300">
								Nb Total d'annulations
							</td>
							<td className="p-2 border-t border-gray-300 text-right">
								{totalAnnulationsArticles} articles
							</td>
							<td className="p-2 border-t border-gray-300 text-right">
								{totalAnnulationsMontantBrut.toFixed(2)}€
							</td>
							<td className="p-2 border-t border-gray-300 text-right">
								{totalAnnulationsMontantNet.toFixed(2)}€
							</td>
						</tr>
					</tbody>
				</table>
			</CardBody>
		</Card>
	);
};

export default CanceledReceipts;
