import React from "react";
import PageContent from "../../PageContent";

function Employees() {
	return (
		<PageContent>
			<div className="flex flex-col">
				<div className="">job scheduling</div>
				<div className="">
					{"time clock (horaire des employés)  "}
					<div className="">name</div>
					<div className="">job</div>
					<div className="">clock in</div>
					<div className="">clock out</div>
					<div className="">total hours</div>
					<div className="">regular hours</div>
					<div className="">overtime</div>
					<div className="">absence</div>
					<div className="">
						bulk actions : Addd shift, add absence, send chat message, create a
						team chat from selection
					</div>
					<div className="">Tous les employés qui ont pointés aujourd hui</div>
				</div>
				<div className=""></div>
				<div className=""></div>
				<div className=""></div>
				<div className=""></div>
				<div className=""></div>
				<div className=""></div>
				<div className=""></div>
				<div className=""></div>
			</div>
		</PageContent>
	);
}

export default Employees;
