import { Button } from "@material-tailwind/react";
import { useReportStore } from "_services/useReportStore";
import React from "react";
import * as enums from "@/lib/enums";

function ReportGroupByFilters() {
	const [groupBy, setGroupBy] = useReportStore((state) => [
		state.groupBy,
		state.setGroupBy,
	]); // Fonctions pour changer les filtres
	const changerGroupe = (nouveauGroupe: string) => setGroupBy(nouveauGroupe);
	const buttons = [
		{
			label: "Références comptables",
			type: enums.REPORT_FILTER_GROUPBY_ACCOUNTINGGROUPS,
		},
		{
			label: "Catégories",
			type: enums.REPORT_FILTER_GROUPBY_CATEGORICAL,
		},
	];

	return (
		<div className="flex flex-col">
			<div className="">Groupé par :</div>
			<div className="flex flex-row gap-1">
				{buttons.map((button) => {
					return (
						<Button
							key={button.label}
							onClick={() => changerGroupe(button.type)}
							className={`text-xs capitalize font-normal px-4 py-2 rounded-md text-gray-800 ${
								groupBy === button.type
									? "bg-blue-600/50 font-bold"
									: "bg-gray-300/50 font-normal"
							}`}
						>
							{button.label}
						</Button>
					);
				})}
			</div>
		</div>
	);
}

export default ReportGroupByFilters;
