/* eslint-disable no-mixed-spaces-and-tabs */
import React from "react";
import CalCaseWhite from "./CalCaseWhite";
import CalCaseDark from "./CalCaseDark";

import colors from "tailwindcss/colors";
const colorKeys = Object.keys(colors);

function DayIcon({
	selected,
	selectedColor,
	defaultColor,
}: {
	selected?: boolean;
	selectedColor?: keyof typeof colors;
	defaultColor?: keyof typeof colors;
}) {
	const selectedBg = `bg-${selectedColor}-700`;
	const defaultBg = `bg-${defaultColor}-700`;
	return (
		<div
			className={`flex ${
				selected
					? `${
							selectedColor ? selectedBg : "bg-blue-800/50 "
					  } shadow-[0_2px_5px_0_rgba(0,0,0,0.3)]`
					: `${defaultColor ? defaultBg : "bg-blue-500/50 "}`
			} p-[3px] w-[29px] pt-2 rounded-sm overflow-hidden`}
		>
			<div className="grid grid-cols-4 grid-rows-3 w-[23px] h-[18px] rounded-sm gap-[1px] bg-white p-[2px]">
				<CalCaseWhite
					selected={selected}
					selectedColor={selectedColor}
					defaultColor={defaultColor}
				/>
				<CalCaseWhite
					selected={selected}
					selectedColor={selectedColor}
					defaultColor={defaultColor}
				/>
				<CalCaseWhite
					selected={selected}
					selectedColor={selectedColor}
					defaultColor={defaultColor}
				/>
				<CalCaseWhite
					selected={selected}
					selectedColor={selectedColor}
					defaultColor={defaultColor}
				/>
				<CalCaseWhite
					selected={selected}
					selectedColor={selectedColor}
					defaultColor={defaultColor}
				/>
				<CalCaseDark
					selected={selected}
					selectedColor={selectedColor}
					defaultColor={defaultColor}
				/>
				<CalCaseWhite
					selected={selected}
					selectedColor={selectedColor}
					defaultColor={defaultColor}
				/>
				<CalCaseWhite
					selected={selected}
					selectedColor={selectedColor}
					defaultColor={defaultColor}
				/>
				<CalCaseWhite
					selected={selected}
					selectedColor={selectedColor}
					defaultColor={defaultColor}
				/>
				<CalCaseWhite
					selected={selected}
					selectedColor={selectedColor}
					defaultColor={defaultColor}
				/>
				<CalCaseWhite
					selected={selected}
					selectedColor={selectedColor}
					defaultColor={defaultColor}
				/>
				<CalCaseWhite
					selected={selected}
					selectedColor={selectedColor}
					defaultColor={defaultColor}
				/>
			</div>
		</div>
	);
}

export default DayIcon;
