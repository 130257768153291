import { generateUniqueID } from "@/lib/functions";
import { TodoItem } from "@/lib/todo-types";
import { Moment } from "moment";
import { create } from "zustand";

import {
	doc,
	onSnapshot,
	getDocs,
	where,
	addDoc,
	collection,
	query,
	updateDoc,
	deleteDoc,
	db,
} from "@/firebase/BaseConfig";

interface TodoListState {
	todos: TodoItem[];
	getTodos: (userId: string) => void;
	addTodo: (userId: string, text: string, date: Moment) => void;
	toggleTodo: (todoId: string) => void;
	deleteTodo: (todoId: string) => void;
}

export const useTodoListStore = create<TodoListState>()((set, get) => ({
	todos: [],
	getTodos: async (userId: string) => {
		//currentUser.uid

		const q = query(collection(db, "todos"), where("uid", "==", userId));
		try {
			const querySnapshot = await getDocs(q);
			const todos: TodoItem[] = [];
			querySnapshot.forEach((doc) => {
				// doc.data() is never undefined for query doc snapshots
				todos.push(doc.data() as TodoItem);
			});
			set({ todos });
		} catch (error) {
			//todo catch error

			debugger;
		}
	},
	addTodo: async (userId: string, text: string, date: Moment) => {
		const oldTodos = get().todos;
		const newItem: TodoItem = {
			id: generateUniqueID({}),
			text,
			dateDebut: date.format("YYYYMMDDHHmm"),
			completed: false,
			uid: userId,
		};
		const fireDoc = await addDoc(collection(db, "todos"), newItem);
		const todos = [...oldTodos, newItem];
		set({ todos });
	},
	toggleTodo: async (todoId: string) => {
		const oldTodos = get().todos;
		const q = query(collection(db, "todos"), where("id", "==", todoId));
		const querySnapshot = await getDocs(q);
		querySnapshot.forEach(async (fireDoc) => {
			const docRef = doc(db, "todos", fireDoc.id);
			await updateDoc(docRef, {
				completed: !(fireDoc.data() as TodoItem).completed,
			});
		});
		const todos = oldTodos.map((todo) =>
			todo.id === todoId ? { ...todo, completed: !todo.completed } : todo
		);

		set({ todos });
	},
	deleteTodo: async (todoId: string) => {
		const q = query(collection(db, "todos"), where("id", "==", todoId));
		const querySnapshot = await getDocs(q);
		querySnapshot.forEach(async (fireDoc) => {
			await deleteDoc(doc(db, "todos", fireDoc.id));
		});
		const oldTodos = get().todos;
		const todos = oldTodos.filter((todo) => todo.id !== todoId);
		set({ todos });
	},
}));
