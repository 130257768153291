import { Button } from "@material-tailwind/react";
import { useReportStore } from "_services/useReportStore";
import React from "react";
import * as enums from "@/lib/enums";

function ReportTakingsViewFilters() {
	const [takingView, setTakingView] = useReportStore((state) => [
		state.takingView,
		state.setTakingView,
	]); // Fonctions pour changer les filtres
	const changerMouvement = (nouveauMouvement: string) =>
		setTakingView(nouveauMouvement);
	const buttons = [
		{
			label: "Détails",
			type: enums.REPORT_FILTER_TAKINGSVIEW_DETAILS,
		},
		{
			label: "Total",
			type: enums.REPORT_FILTER_TAKINGSVIEW_TOTAL,
		},
	];

	return (
		<div className="flex flex-col">
			<div className="">Mouvements d'encaissement:</div>
			<div className="flex flex-row gap-1">
				{buttons.map((button) => {
					return (
						<Button
							key={button.label}
							onClick={() => changerMouvement(button.type)}
							className={`text-xs capitalize font-normal px-4 py-2 rounded-md text-gray-800 ${
								takingView === button.type
									? "bg-blue-600/50 font-bold"
									: "bg-gray-300/50 font-normal"
							}`}
						>
							{button.label}
						</Button>
					);
				})}
			</div>
		</div>
	);
}

export default ReportTakingsViewFilters;
