import { Button } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import ResultatBrut from "./rapport-operationnel/ResultatBrut";
import { JSX } from "react/jsx-runtime";
import ResultatNet, {
	ResultatNetProps,
} from "./rapport-operationnel/ResultatNet";
import RapportTVA from "./rapport-operationnel/RapportTVA";
import NotesDeRapport from "./rapport-operationnel/NotesDeRapport";
import CanceledReceipts, {
	CanceledReceiptsProps,
} from "./rapport-operationnel/CanceledReceipts";
import RapportInfo from "./rapport-operationnel/RapportInfo";
import * as invoiceTypes from "@/lib/invoice-types";
import ClassicReportByDepartement from "./ClassicReportByDepartement";
import * as enums from "@/lib/enums";
import { useReportStore } from "_services/useReportStore";
import ReportViewFilters from "./filters/ReportDisplayViewFilters";
import ReportTakingsViewFilters from "./filters/ReportTakingsViewFilters";
import ReportGroupByFilters from "./filters/ReportGroupByFilters";
import ReportTimeIntervalFilters from "./filters/ReportTimeIntervalFilters";
import StatisticalReport from "./StatisticalReport";
import SectionTitle from "../sections/SectionTitle";

const RapportOperationnel: React.FC = () => {
	const [displayView, takingView, groupBy, timeInterval, setDisplayView] =
		useReportStore((state) => [
			state.displayView,
			state.takingView,
			state.groupBy,
			state.timeInterval,
			state.setDisplayView,
		]);

	const rapportData: invoiceTypes.ReportData = {
		ventes: [
			{
				departement: "ALCOOL",
				code: "",
				quantite: 19,
				tva: 20,
				montantTva: 26.33,
				// tva6:5.5,
				// montantTaxe6: 0,
				// tva8: 8.5,
				// montantTaxe8: 0,
				// tva12: 10,
				// montantTaxe12: 0,
				// tva20: 20,
				// montantTaxe20: 158,
				//tauxTVA: 20,
				discounts: 0,
				//totalHT: ,
				totalTTC: 158,
			},
			{
				departement: "BOISSONS",
				code: "",
				quantite: 13,
				tva: 10,
				montantTva: 2.68,
				// tva6: 0,
				// montantTaxe6: 0,
				// tva8: 0,
				// montantTaxe8: 0,
				// tva12: 29.5,
				// montantTaxe12: 29.5,
				// tva20: 0,
				// montantTaxe20: 0,
				//tauxTVA: 10,
				discounts: 0,
				//totalHT: 6.36,
				totalTTC: 29.5,
			},
			{
				departement: "CUISINE",
				code: "",
				quantite: 85.02,
				tva: 10,
				montantTva: 74.09,
				// tva6: 0,
				// montantTaxe6: 0,
				// tva8: 0,
				// montantTaxe8: 0,
				// tva12: 815,
				// montantTaxe12: 815,
				// tva20: 0,
				// montantTaxe20: 0,
				//tauxTVA: 20,
				discounts: 0,
				//totalHT: 0,
				totalTTC: 815,
			},
			// {
			// 	departement: "ENTREES",
			// 	code: "888888",
			// 	quantite: 11,
			// 	tauxTVA: 10,
			// 	tva6: 0,
			// 	montantTaxe6: 0,
			// 	tva8: 0,
			// 	montantTaxe8: 0,
			// 	tva12: 0,
			// 	montantTaxe12: 0,
			// 	tva20: 0,
			// 	montantTaxe20: 83.82,
			// 	discounts: 0,
			// 	//totalHT: 83.82,
			// 	totalTTC: 92.2,
			// },
		] as invoiceTypes.StatisticalReportItem[],
		recettes: [
			{ type: "Carte bleue", montantIn: 125, montantOut: 0 },
			{ type: "Espèces", pourboires: 0, montantIn: 370, montantOut: 0 },
			{ type: "Facture", montantIn: 235.5, montantOut: 239.5 },
			{ type: "Virement", montantIn: 145.5, montantOut: 0 },
		] as invoiceTypes.ResultatNetItem[],
		informations: {
			nombreTickets: 48,
			premierTicket: "09:34:39",
			dernierTicket: "14:10:28",
		},
		canceledReceipts: [
			{ departement: "ALCOOL", articles: 2, montantBrut: 5.5, montantNet: 5.5 },
		] as invoiceTypes.CanceledReceiptItem[],
		reportNotes: [{ date: "06.10.2023", role: "Manager" }],
	};

	useEffect(() => {
		if (window.location.hash && window.location.hash.substring(1)) {
			setDisplayView(window.location.hash.substring(1));
			console.log("setDisplayView!");
		}
	}, [window.location.hash]);
	// États pour les filtres sélectionnés

	return (
		<div className="grid grid-cols-[2fr_2fr_1fr] h-full w-full p-4 overflow-y-auto gap-5">
			<SectionTitle className=" col-span-3">
				Rapport opérationnel pour mardi 26/04/22 (#327)
			</SectionTitle>

			<div className="flex flex-wrap col-span-3 gap-3">
				<ReportViewFilters />
				<ReportTakingsViewFilters />
				<ReportGroupByFilters />
				<ReportTimeIntervalFilters />
			</div>

			{/* Section des ventes */}

			<div className="grid grid-cols-1 lg:grid-cols-2 gap-5 w-full h-full col-span-2">
				{/* {displayView === enums.REPORT_FILTER_VIEW_CLASSIC &&
					groupBy === enums.REPORT_FILTER_GROUPBY_ACCOUNTINGGROUPS && ( */}
				<ClassicReportByDepartement
					rapportData={rapportData}
					isResume={
						displayView === enums.REPORT_FILTER_VIEW_ACCOUNTING ||
						displayView === enums.REPORT_FILTER_VIEW_STATISTICAL
					}
				/>
				{/* )} 
				{displayView === enums.REPORT_FILTER_VIEW_STATISTICAL && (
					<StatisticalReport statisticalReport={rapportData.ventes} />
				)}*/}
			</div>

			<div className="grid w-full h-full">
				<RapportInfo
					nbReceipts={0}
					dateOfFirstReceipt=""
					dateOfLastReceipt=""
				/>
			</div>
		</div>
	);
};

export default RapportOperationnel;
